import React, { useState, useCallback } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Button, Row, Col, Spinner } from 'react-bootstrap';

// Import plugins
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const PDFViewer = ({ fileUrl }) => {
  // State
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize plugins
  const printPluginInstance = printPlugin();
  const zoomPluginInstance = zoomPlugin();
  const { PrintButton } = printPluginInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  // Handlers
  const handleDocumentLoad = useCallback((doc) => {
    setNumPages(doc.doc.numPages);
    setCurrentPage(1);
    setLoading(false);
  }, []);

  const handlePageChange = useCallback((e) => {
    setCurrentPage(e.pageIndex + 1);
  }, []);

  const handleError = useCallback((error) => {
    setError('Failed to load PDF document.');
    setLoading(false);
  }, []);

  const changePage = useCallback((offset) => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + offset;
      return newPage >= 1 && newPage <= numPages ? newPage : prevPage;
    });
  }, [numPages]);

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  return (
    <div className='pdf-viewer-container' style={{
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      height: '800px', // Adjustable height
      backgroundColor: '#f8f9fa'
    }}>
      {/* Toolbar */}
      <div style={{
        padding: '8px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff'
      }}>
        {/* Navigation Controls */}
        <div className="d-flex align-items-center">
          <Button
            variant="outline-primary"
            size="sm"
            disabled={currentPage === 1}
            onClick={() => changePage(-1)}
            className="me-2"
          >
            ←
          </Button>
          <span className="mx-2">
            Sida {currentPage} av {numPages || '?'}
          </span>
          <Button
            variant="outline-primary"
            size="sm"
            disabled={currentPage === numPages}
            onClick={() => changePage(1)}
            className="ms-2"
          >
            →
          </Button>
        </div>

        {/* Zoom Controls */}
        <div className="d-flex align-items-center">
          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
          <div className="ms-3">
            <PrintButton />
          </div>
        </div>
      </div>

      {/* PDF Viewer */}
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
          {loading && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Laddar PDF...</span>
              </Spinner>
            </div>
          )}
          <Viewer
            fileUrl={fileUrl}
            defaultScale={SpecialZoomLevel.PageWidth}
            onDocumentLoad={handleDocumentLoad}
            onPageChange={handlePageChange}
            onError={handleError}
            plugins={[
              printPluginInstance,
              zoomPluginInstance
            ]}
            initialPage={currentPage - 1}
          />
        </Worker>
      </div>
    </div>
  );
};

export default PDFViewer;