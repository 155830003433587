import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const QuoteTableRow = ({
  index,
  item,
  articles,
  updateRow,
  deleteRow,
  openCreateNewArticleModal,
}) => {
  const [editedItem, setEditedItem] = useState(item);

  useEffect(() => {
    setEditedItem(item);
  }, [item]);

  const calculateTotalAmount = (item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitprice = parseFloat(item.unitprice) || 0;
    const total = quantity * unitprice;
    return parseFloat(total.toFixed(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedItem = {
      ...editedItem,
      [name]: value
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const taxOptions = [
    { value: '0%', label: '0%' },
    { value: '6%', label: '6%' },
    { value: '12%', label: '12%' },
    { value: '25%', label: '25%' },
  ];

  const unitOptions = [
    { value: 'Piece', label: 'St' },
    { value: 'Hour', label: 'Tim' },
    { value: 'Day', label: 'Dag' },
    { value: 'Month', label: 'Månad' },
    { value: 'Kilogram', label: 'kg' },
    { value: 'Liter', label: 'l' },
    { value: 'Meter', label: 'm' },
    { value: 'Package', label: 'Förp' },
    { value: 'Set', label: 'Set' },
    { value: 'Square Meter', label: 'm²' },
    { value: 'Cubic Meter', label: 'm³' }
  ];

  const typeOptions = [
    { value: 'Goods', label: 'Varor' },
    { value: 'Service', label: 'Tjänst' },
  ];

  const articleOptions = articles.map((article) => ({
    value: article.id,
    label: `${article.productcode} - ${article.description}`,
    articleData: article,
  }));

  const handleArticleChange = (selectedOption) => {
    let updatedItem;
    if (selectedOption) {
      if (selectedOption.articleData) {
        // Existing article selected
        const selectedArticle = selectedOption.articleData;
        updatedItem = {
          ...editedItem,
          article_id: selectedArticle.id,
          description: selectedArticle.description,
          productcode: selectedArticle.productcode,
          unitprice: selectedArticle.unitprice,
          tax_rate: selectedArticle.tax_rate,
          unit: selectedArticle.unit,
          type: selectedArticle.type
        };
      } else {
        // New article description entered
        updatedItem = {
          ...editedItem,
          article_id: null,
          description: selectedOption.label,
          // Keep existing values for other fields
        };
      }
    } else {
      // Selection cleared
      updatedItem = {
        ...editedItem,
        article_id: null,
        description: '',
        productcode: ''
      };
    }

    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const handleTaxRateChange = (selectedOption) => {
    const updatedItem = {
      ...editedItem,
      tax_rate: selectedOption ? selectedOption.value : ''
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const handleUnitChange = (selectedOption) => {
    const updatedItem = {
      ...editedItem,
      unit: selectedOption ? selectedOption.value : ''
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const handleTypeChange = (selectedOption) => {
    const updatedItem = {
      ...editedItem,
      type: selectedOption ? selectedOption.value : ''
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const getSelectStyles = () => ({
    control: (base) => ({
      ...base,
      minHeight: '36px',
      height: '36px'
    }),
    valueContainer: (base) => ({
      ...base,
      height: '36px',
      padding: '0 8px'
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    })
  });

  return (
    <tr>
      <td></td>
      <td style={{ minWidth: '250px' }}>
        <CreatableSelect
          isClearable
          onChange={handleArticleChange}
          onCreateOption={handleArticleChange}
          options={articleOptions}
          value={
            editedItem.article_id
              ? {
                value: editedItem.article_id,
                label: `${editedItem.productcode} - ${editedItem.description}`,
                articleData: editedItem
              }
              : editedItem.description
                ? { value: editedItem.description, label: editedItem.description }
                : null
          }
          placeholder="Välj artikel eller skapa ny..."
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            ...getSelectStyles()
          }}
        />
      </td>
      <td>
        <Select
          name="type"
          value={typeOptions.find(option => option.value === editedItem.type)}
          onChange={handleTypeChange}
          options={typeOptions}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            ...getSelectStyles()
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        <input
          type="number"
          name="quantity"
          value={editedItem.quantity}
          onChange={handleChange}
          className="form-control"
          min="0"
          step="1"
        />
      </td>
      <td>
        <Select
          name="unit"
          value={unitOptions.find(option => option.value === editedItem.unit)}
          onChange={handleUnitChange}
          options={unitOptions}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            ...getSelectStyles()
          }}
        />
      </td>
      <td style={{ minWidth: '120px' }}>
        <input
          type="number"
          name="unitprice"
          value={editedItem.unitprice}
          onChange={handleChange}
          className="form-control"
          min="0"
          step="0.01"
        />
      </td>
      <td>
        <Select
          name="tax_rate"
          value={taxOptions.find(option => option.value === editedItem.tax_rate)}
          onChange={handleTaxRateChange}
          options={taxOptions}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            ...getSelectStyles()
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        {new Intl.NumberFormat('sv-SE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(editedItem.total_amount)}
      </td>
      <td>
        <div className="d-flex">
          <FontAwesomeIcon
            icon={faTrash}
            className="text-danger me-2"
            style={{ cursor: 'pointer' }}
            onClick={() => deleteRow(index)}
          />
          {!editedItem.article_id && editedItem.description && (
            <FontAwesomeIcon
              icon={faPlus}
              className="text-primary ms-2"
              style={{ cursor: 'pointer' }}
              onClick={() => openCreateNewArticleModal(editedItem)}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default QuoteTableRow;