import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import PageTitle from "../../../layouts/PageTitle";
import { useParams, Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import axios from 'axios';
import moment from 'moment';
import VerificationDetailsModal from './VerificationDetailsModal';
import Select from 'react-select';
import { getApiUrl } from "../../../../utils/ApiUtils";
import { NoData } from '../../SharedComponents/NoData';
import axiosInstance from '../../../../services/AxiosInstance';
import ImageComponent from '../../SharedComponents/ImageComponent';
import PdfThumbnail from './PdfThumbnail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from 'react-bootstrap';

export const Verifications = () => {
  const { companyId } = useParams();
  const history = useHistory();

  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showVerificationDetails, setShowVerificationDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const COLUMNS = [
    {
      Header: 'Bilaga', // Attachment
      accessor: 'attachment_id',
      Cell: ({ row }) => <VerificationAttachment row={row} />
    },
    {
      Header: 'Verifikat',
      Footer: 'Verifikat',
      accessor: row => `${row.series}${row.number}`,
      id: 'verification',
      Cell: ({ value }) => <a href="#">{value}</a>,
    },
    {
      Header: 'Datum',
      Footer: 'Datum',
      accessor: row => moment(row.date).format('YYYY-MM-DD'),
      id: 'date',
    },
    {
      Header: 'Titel',
      Footer: 'Titel',
      accessor: 'description',
      id: 'description',
    },
    {
      Header: 'Belopp',
      Footer: 'Belopp',
      accessor: 'debitSum',
      id: 'debitSum',
      Cell: ({ value }) => new Intl.NumberFormat('sv-SE').format(value) + " kr",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    // Fetch fiscal years
    const getFiscalYears = async () => {
      try {
        const result = await axiosInstance.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
        setFiscalYears(result.data.fiscal_years);
        if (result.data.fiscal_years.length > 0) {
          const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
          setSelectedFiscalYear(latestFiscalYear);
        }
      } catch (err) {
        console.error('Error fetching fiscal years:', err);
        setFiscalYears([]);
      } finally {
        setLoading(false);
      }
    };
    getFiscalYears();
  }, [companyId]);

  useEffect(() => {
    if (!selectedFiscalYear) return;

    // Fetch verifications for the selected fiscal year
    const getVerifications = async () => {
      setLoading(true);
      try {
        const result = await axiosInstance.get(getApiUrl(`${companyId}/verifications/${selectedFiscalYear.year}`), { withCredentials: true });
        const updatedVerifications = result.data.verifications.map(verification => {
          let debitSum = 0;
          let creditSum = 0;

          verification.transactions.forEach(transaction => {
            if (transaction.transaction_type === 'debit') {
              debitSum += parseFloat(transaction.amount);
            } else if (transaction.transaction_type === 'credit') {
              creditSum += parseFloat(transaction.amount);
            }
          });

          return {
            ...verification,
            debitSum,
            creditSum,
            attachment: verification.attachments || null,
          };
        });

        setData(updatedVerifications);
      } catch (err) {
        setError(err);
        setData([]);
      } finally {
        setLoading(false);
      }
    };
    getVerifications();

  }, [companyId, selectedFiscalYear]);

  const handleUploadClick = (event, verificationId) => {
    event.stopPropagation(); // Prevent row click
    event.preventDefault();

    console.log('Upload clicked for verification:', verificationId);

    // Trigger file upload (you may use a hidden input element or a modal)
    // For demonstration, let's say you have a file input:
    const fileInput = document.getElementById(`upload-input-${verificationId}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`File input element not found for verificationId: ${verificationId}`);
    }
  };

  const handleFileUpload = async (event, verificationId) => {

    console.log('File uploaded for verification:', verificationId);

    const file = event.target.files[0];
    if (file) {
      console.log(`File selected for verificationId ${verificationId}:`, file);

      const formData = new FormData();
      formData.append('file', file);
      try {
        const uploadFileApiUrl = getApiUrl(`${companyId}/accounting/verifications/${verificationId}/addattachment`);
        const response = await axiosInstance.post(uploadFileApiUrl, formData, { withCredentials: true });

        if (response.data.success) {
          console.log('File uploaded successfully:', response.data);
        } else {
          console.error('Error uploading file:', response.data.message);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleFiscalYearChange = (selectedOption) => {
    setSelectedFiscalYear(selectedOption.value);
  };

  const tableInstance = useTable({
    columns,
    data,
  }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const handleViewDetails = (event, row) => {
    event.preventDefault();
    setSelectedRow(row);
    setShowVerificationDetails(true);
  };

  const VerificationAttachment = ({ row }) => {
    // If this is an invoice payment verification with an invoice ID
    if (row.original.invoice?.invoice_id) {
      return (
        <PdfThumbnail
          companyId={companyId}
          invoiceId={row.original.invoice.invoice_id}
          style={{
            width: '40px',
            height: '40px',
            border: '1px solid #dee2e6',
            borderRadius: '4px',
            objectFit: 'cover'
          }}
        />
      );
    }

    // Regular attachment handling
    return (
      <>
        {row.original.attachments.length > 0 ? (
          <ImageComponent
            imageUrl={getApiUrl(`${companyId}/accounting/verifications/thumbnail/${row.original.attachments[0].attachment_id}`)}
            alt=""
            style={{
              width: '40px',
              height: '40px',
              border: '1px solid #dee2e6',
              borderRadius: '4px'
            }}
          />
        ) : (
          <div
            style={{
              width: '40px',
              height: '40px',
              border: '1px dashed #dee2e6',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              transition: 'all 0.2s',
              backgroundColor: '#f8f9fa'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.borderColor = '#44814e';
              e.currentTarget.style.backgroundColor = '#f0f4f1';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.borderColor = '#dee2e6';
              e.currentTarget.style.backgroundColor = '#f8f9fa';
            }}
            onClick={(e) => handleUploadClick(e, row.original.verification_id)}
          >
            <FontAwesomeIcon
              icon={faAdd}
              style={{
                color: '#6c757d',
                fontSize: '16px'
              }}
            />
          </div>
        )}
        <input
          type="file"
          id={`upload-input-${row.original.verification_id}`}
          style={{ display: 'none' }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => { handleFileUpload(e, row.original.verification_id) }}
          accept="application/pdf,image/*"
        />
      </>
    );
  };

  return (
    <>
      <div className="col-12">

        <VerificationDetailsModal
          showVerificationDetails={showVerificationDetails}
          setShowVerificationDetails={setShowVerificationDetails}
          selectedRow={selectedRow}
          onUploadAttachment={(verificationId) => {
            // Use the existing file input click handler
            const fileInput = document.getElementById(`upload-input-${verificationId}`);
            if (fileInput) {
              fileInput.click();
            }
          }}
        />

        <PageTitle motherMenu="Bokföring" activeMenu={"Verifikationer"} />

        {loading ? (
          <p>Loading...</p>
        ) : fiscalYears.length === 0 ? (
          <>
            <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              padding: '10px'
            }}>
              <Link
                style={{ marginTop: "20px", alignItem: "center" }}
                to={`#`}
                onClick={() => history.push(`/${companyId}/settings`)}
                className="btn btn-primary"
              >
                Gå till Inställningar
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between gap-4 mb-5">
              <div className="d-flex align-items-center gap-4">
                <span>Räkenskapsår:</span>
                <Select
                  value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                  onChange={handleFiscalYearChange}
                  options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                />
              </div>
              <Button variant="primary" onClick={() => history.push(`/${companyId}/newverification`)}>
                Skapa nytt verifikat
              </Button>
            </div>

            {data.length === 0 ? (
              <NoData message={`Det finns inga verifikationer för ${selectedFiscalYear?.year}.`} />
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="dataTables_wrapper">
                      <table {...getTableProps()} className="table dataTable display">
                        <thead>
                          {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ display: column.hidden ? 'none' : 'table-cell' }}>
                                  {column.render('Header')}
                                  <span className="ml-1">
                                    {column.isSorted ? (
                                      column.isSortedDesc ?
                                        <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                        :
                                        <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                    ) : (
                                      <i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />
                                    )}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {rows.map(row => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} onClick={(event) => handleViewDetails(event, row.original)} style={{ cursor: "pointer", borderBottom: "1px solid #ccc" }}>
                                {row.cells.map(cell => (
                                  <td {...cell.getCellProps()} style={{ display: cell.column.hidden ? 'none' : 'table-cell' }}>{cell.render('Cell')}</td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Verifications;
