import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import axios from '../../../../services/AxiosInstance';
import PDFViewer from './PdfViewer';
import dayjs from 'dayjs';
import {
  Spinner,
  Card,
  Button,
  Collapse,
  Badge,
  Alert,
  Modal,
  Form,
  Table,
  Row,
} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import PaymentReceivedModal from './PaymentReceivedModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faPaperPlane,
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faBell,
  faMoneyBill,
  faUndo,
  faUndoAlt,
  faCreditCard,
  faLink,
  faMoneyBillTransfer
} from '@fortawesome/free-solid-svg-icons';

import { ButtonGroup, Dropdown, SplitButton } from 'react-bootstrap';

// Helper function to format numbers as Swedish currency (SEK)
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amount);
};

const InvoiceViewer = () => {
  const history = useHistory();
  const { companyId, invoiceId } = useParams();

  console.log('InvoiceViewer: Company ID:', companyId);
  console.log('InvoiceViewer: Invoice ID:', invoiceId);

  // State declarations
  const [pdfData, setPdfData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openCustomerInfo, setOpenCustomerInfo] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [nextAction, setNextAction] = useState(null);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [combinedTimeline, setCombinedTimeline] = useState([]);
  const [showReversalConfirmModal, setShowReversalConfirmModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [relatedInvoiceData, setRelatedInvoiceData] = useState(null);


  // Constants for document types
  const DocumentType = {
    INVOICE: 'invoice',
    CREDIT_NOTE: 'credit_note'
  };

  // Constants for status types
  const StatusType = {
    DRAFT: 'Draft',
    PUBLISHED: 'Published',
    PAID: 'Paid',
    APPLIED: 'Applied',    // New status for credit notes
    REFUNDED: 'Refunded',  // New status for credit notes
    OVERDUE: 'Overdue'
  };

  // Main useEffect
  useEffect(() => {
    fetchInvoice();
    fetchPdf();
  }, [companyId, invoiceId]);

  // Add new useEffect for determining next action
  useEffect(() => {
    if (invoiceData) {
      console.log('Determining next action with invoice data:', invoiceData);
      determineNextAction(invoiceData.status);
    }
  }, [invoiceData]);

  // Effect for combining timeline data
  useEffect(() => {
    if (statusHistory && paymentHistory && invoiceData) {
      const documentType = invoiceData.invoice_type || DocumentType.INVOICE;
      const originalTotal = invoiceData.totals.grand_total;
      const isCredit = documentType === DocumentType.CREDIT_NOTE;
      const linkedInvoiceId = invoiceData.related_invoice_id; // Assuming this exists in your data

      console.log('=== Starting Timeline Calculation ===');
      console.log('Document Type:', documentType);
      console.log('Original Total:', originalTotal);
      console.log('Is Credit Note:', isCredit);
      console.log('Linked Invoice:', linkedInvoiceId);

      // Add credit note events for regular invoices
      let creditNoteEvents = [];
      if (!isCredit && invoiceData.credit_notes?.length > 0) {
        creditNoteEvents = invoiceData.credit_notes.map(creditNote => ({
          date: creditNote.date, // Using created_at for proper timeline ordering
          type: 'credit_note',
          creditNoteId: creditNote.credit_note_id,
          creditNoteNumber: creditNote.credit_note_number,
          amount: creditNote.amount,
          status: creditNote.status,
          timestamp: new Date(creditNote.created_at).getTime(),
          created_by: creditNote.created_by,
          refundStatus: creditNote.refund_status // if you have this information
        }));
      }

      // Process status events with document type context
      const statusEvents = statusHistory
        .filter(status => status.status !== StatusType.PAID)
        .map(status => ({
          date: status.changed_at,
          type: 'status',
          status: status.status,
          displayName: getStatusDisplayName(status.status, documentType),
          timestamp: new Date(status.changed_at).getTime()
        }));

      console.log('Payment History:', paymentHistory);

      // Sort payments chronologically
      const sortedPayments = [...paymentHistory]
        //.filter(payment => parseFloat(payment.amount_paid) > 0)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      // Track running totals
      let runningPaymentTotal = 0;

      // Process payments with context awareness
      const paymentEvents = sortedPayments.map(payment => {

        console.log('Raw payment data:', payment);

        runningPaymentTotal += parseFloat(payment.amount_paid);

        // For credit notes, we track outgoing payments (refunds)
        // For invoices, we track incoming payments
        const remainingAmount = isCredit
          ? Math.abs(originalTotal) - runningPaymentTotal
          : originalTotal - runningPaymentTotal;

        return {
          date: payment.created_at,
          type: 'payment',
          status: StatusType.PAID,
          paymentId: payment.payment_id,
          amount: payment.amount_paid,
          verificationId: payment.verification_id,
          verificationSeries: payment.verification_series,
          verificationNumber: payment.verification_number,
          runningPaymentTotal,
          remainingAmount,
          isRefund: isCredit,
          timestamp: new Date(payment.created_at).getTime(),
          created_by: payment.created_by
        };
      });

      // Add link events if this is a credit note
      let linkEvents = [];
      if (isCredit && linkedInvoiceId) {
        linkEvents = [{
          date: invoiceData.created_at, // or when it was linked
          type: 'link',
          linkedInvoiceId,
          timestamp: new Date(invoiceData.created_at).getTime(),
          amount: Math.abs(originalTotal)
        }];
      }

      // Combine all events and sort chronologically
      const combinedEvents = [...statusEvents, ...paymentEvents, ...creditNoteEvents, ...linkEvents]
        .sort((a, b) => a.timestamp - b.timestamp);

      console.log('Final Timeline:', combinedEvents);
      setCombinedTimeline(combinedEvents);
    }
  }, [statusHistory, paymentHistory, invoiceData]);


  const {
    effectiveAmount,
    remainingAmount,
    totalCreditNoteAmount
  } = useMemo(() => {
    if (!invoiceData) {
      return {
        effectiveAmount: 0,
        remainingAmount: 0,
        totalCreditNoteAmount: 0
      };
    }

    const isCredit = invoiceData.invoice_type === DocumentType.CREDIT_NOTE;

    if (isCredit) {
      const creditAmount = Math.abs(parseFloat(invoiceData.totals.grand_total));

      // If this credit note is linked to an invoice
      if (relatedInvoiceData) {
        const relatedInvoicePayments = relatedInvoiceData.payments || [];
        const totalPaidOnInvoice = relatedInvoicePayments.reduce(
          (sum, p) => sum + parseFloat(p.amount_paid),
          0
        );

        // If the related invoice is already paid, this credit note needs to be refunded
        if (totalPaidOnInvoice >= relatedInvoiceData.totals.grand_total) {
          // For credit notes, we need to keep the negative sign in the calculation
          // but display the absolute value
          return {
            effectiveAmount: creditAmount,
            remainingAmount: creditAmount + totalPaid, // totalPaid is negative for credit notes
            totalCreditNoteAmount: 0
          };
        } else {
          return {
            effectiveAmount: creditAmount,
            remainingAmount: 0, // Nothing to pay back
            totalCreditNoteAmount: 0
          };
        }
      }

      // If no related invoice, treat as regular refund
      return {
        effectiveAmount: creditAmount,
        remainingAmount: creditAmount + totalPaid, // totalPaid is negative for credit notes
        totalCreditNoteAmount: 0
      };
    }

    // Regular invoice calculations (unchanged)
    const totalCreditNoteAmount = invoiceData.credit_notes?.reduce((sum, cn) =>
      sum + Math.abs(cn.amount),
      0) || 0;

    const effectiveAmount = invoiceData.totals.grand_total - totalCreditNoteAmount;
    const remainingAmount = effectiveAmount - totalPaid;

    return {
      effectiveAmount,
      remainingAmount,
      totalCreditNoteAmount
    };
  }, [invoiceData, totalPaid, relatedInvoiceData]);

  // Main fetch function
  // Remove determineNextAction call from fetchInvoice
  const fetchInvoice = async () => {
    try {
      const [invoiceResponse, statusHistoryResponse, paymentsResponse] = await Promise.all([
        axios.get(getApiUrl(`${companyId}/invoices/${invoiceId}/details`), { withCredentials: true }),
        axios.get(getApiUrl(`${companyId}/invoices/${invoiceId}/status-history`), { withCredentials: true }),
        axios.get(getApiUrl(`${companyId}/invoices/${invoiceId}/payments`), { withCredentials: true })
      ]);

      console.log('Fetched invoice data:', invoiceResponse.data);
      console.log('Fetched status history:', statusHistoryResponse.data);
      console.log('Fetched payment history:', paymentsResponse.data);

      const data = invoiceResponse.data;
      const statusHistory = statusHistoryResponse.data;
      const payments = paymentsResponse.data;

      // If this is a credit note and has a related invoice, fetch its data
      if (data.invoice_type === DocumentType.CREDIT_NOTE && data.related_invoice_id) {
        const relatedInvoiceResponse = await axios.get(
          getApiUrl(`${companyId}/invoices/${data.related_invoice_id}/details`),
          { withCredentials: true }
        );
        const relatedPaymentsResponse = await axios.get(
          getApiUrl(`${companyId}/invoices/${data.related_invoice_id}/payments`),
          { withCredentials: true }
        );
        setRelatedInvoiceData({
          ...relatedInvoiceResponse.data,
          payments: relatedPaymentsResponse.data
        });
      }

      // Get latest status from history
      const sortedHistory = [...statusHistory].sort((a, b) =>
        new Date(b.changed_at) - new Date(a.changed_at)
      );
      const currentStatus = sortedHistory[0]?.status || data.status;

      // Set all states at once
      setStatusHistory(statusHistory);
      setPaymentHistory(payments);
      setTotalPaid(payments.reduce((sum, p) => sum + parseFloat(p.amount_paid), 0));

      setInvoiceData({
        ...data,
        status: currentStatus
      });

      // Calculate dates
      const inputDate = data.deadline;
      const today = dayjs();
      const futureDate = dayjs(inputDate);
      setRemainingDays(futureDate.diff(today, 'day'));
      setFormattedDate(futureDate.format('D MMMM YYYY'));

      setLoading(false);
    } catch (error) {
      console.error('Error fetching invoice data:', error);
      setError('Kunde inte hämta fakturadetaljer. Vänligen försök igen senare.');
      setLoading(false);
    }
  };

  const fetchPdf = async () => {
    try {
      const getInvoicePdfApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/pdf`);
      const response = await axios.get(getInvoicePdfApiUrl, {
        responseType: 'blob',
        withCredentials: true,
      });

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setPdfData(fileURL);
    } catch (error) {
      console.error('Error fetching the PDF:', error);
      setError('Kunde inte hämta PDF-filen. Vänligen försök igen senare.');
    }
  };

  // const determineNextAction = (currentStatus) => {
  //   const totalPaid = paymentHistory.reduce((sum, p) => sum + parseFloat(p.amount_paid), 0);
  //   const isFullyPaid = totalPaid >= invoiceData?.totals?.grand_total;

  //   switch (currentStatus) {
  //     case 'Draft':
  //       setNextAction({ text: 'Publicera faktura', nextStatus: 'Published' });
  //       break;
  //     case 'Published':
  //       if (!isFullyPaid) {
  //         setNextAction({ text: 'Registrera betalning', nextStatus: 'Paid' });
  //       } else {
  //         setNextAction(null);
  //       }
  //       break;
  //     case 'Paid':
  //       if (!isFullyPaid) {
  //         setNextAction({ text: 'Registrera delbetalning', nextStatus: 'Paid' });
  //       } else {
  //         setNextAction(null);
  //       }
  //       break;
  //     case 'Overdue':
  //       if (!isFullyPaid) {
  //         setNextAction({ text: 'Registrera betalning', nextStatus: 'Paid' });
  //       } else {
  //         setNextAction(null);
  //       }
  //       break;
  //     default:
  //       setNextAction(null);
  //   }
  // };

  // Display names for statuses
  const getStatusDisplayName = (status, documentType) => {
    const statusNames = {
      [DocumentType.INVOICE]: {
        [StatusType.DRAFT]: 'Utkast',
        [StatusType.PUBLISHED]: 'Publicerad',
        [StatusType.PAID]: 'Betald',
        [StatusType.OVERDUE]: 'Förfallen',
        'Cancelled': 'Makulerad',
        'Reminder Sent': 'Påminnelse skickad'
      },
      [DocumentType.CREDIT_NOTE]: {
        [StatusType.DRAFT]: 'Utkast',
        [StatusType.PUBLISHED]: 'Publicerad',
        [StatusType.APPLIED]: 'Tillämpad på faktura',
        [StatusType.REFUNDED]: 'Återbetald till kund',
        'Cancelled': 'Makulerad'
      }
    };

    return statusNames[documentType]?.[status] || status;
  };

  const getStatusIcon = (status) => {
    const statusIcons = {
      Draft: { icon: faFileAlt },
      Published: { icon: faPaperPlane },
      Paid: { icon: faCheckCircle },
      Overdue: { icon: faExclamationCircle },
      Cancelled: { icon: faTimesCircle },
      'Reminder Sent': { icon: faBell },
    };
    return statusIcons[status] || { icon: faFileAlt };
  };

  const handlePaymentReversal = (payment) => {
    setSelectedPayment(payment);
    setShowReversalConfirmModal(true);
  };

  const handleActionClick = () => {
    if (nextAction.nextStatus === StatusType.PAID || nextAction.nextStatus === StatusType.REFUNDED) {
      // Both regular payments and credit note refunds should show payment modal
      setShowPaymentModal(true);
    } else {
      setShowConfirmModal(true);
    }
  };

  /*const handleActionClick = () => {
    if (nextAction.nextStatus === 'Paid') {
      setShowPaymentModal(true);
    } else {
      setShowConfirmModal(true);
    }
  };*/

  const handleStatusUpdate = async (nextStatus) => {
    setMarkAsPaidLoading(true);
    setError(null);

    try {
      await axios.put(
        getApiUrl(`${companyId}/invoices/${invoiceId}/status`),
        { status: nextStatus },
        { withCredentials: true }
      );

      await fetchInvoice();
      setSuccessMessage(`Fakturan har uppdaterats till ${getStatusDisplayName(nextStatus).toLowerCase()}.`);
    } catch (error) {
      console.error('Error updating invoice status:', error);
      setError('Kunde inte uppdatera fakturastatus. Vänligen försök igen senare.');
    } finally {
      setMarkAsPaidLoading(false);
      setShowConfirmModal(false);
    }
  };

  const handlePaymentSubmit = async (paymentDetails) => {
    try {
      await axios.post(
        getApiUrl(`${companyId}/invoices/${invoiceId}/register-payment`),
        paymentDetails,
        { withCredentials: true }
      );

      await fetchInvoice();
      setShowPaymentModal(false);
      setSuccessMessage('Betalning har registrerats.');
    } catch (error) {
      console.error('Error registering payment:', error);
      setError('Kunde inte registrera betalningen. Vänligen försök igen senare.');
    }
  };

  const handleCreateCreditNote = () => {
    history.push(`/${companyId}/create-credit-note/${invoiceId}`);
  };

  const handleSendReminder = async () => {
    try {
      await axios.post(
        getApiUrl(`${companyId}/invoices/${invoiceId}/send-reminder`),
        {},
        { withCredentials: true }
      );

      await fetchInvoice();
      setSuccessMessage('Påminnelse skickad till kunden.');
    } catch (error) {
      console.error('Error sending reminder:', error);
      setError('Kunde inte skicka påminnelse. Vänligen försök igen senare.');
    }
  };

  const handleSendEmail = async () => {
    try {
      await axios.post(
        getApiUrl(`${companyId}/invoices/${invoiceId}/send-email`),
        {},
        { withCredentials: true }
      );
      setSuccessMessage('E-post skickad till kunden.');
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Kunde inte skicka e-post. Vänligen försök igen senare.');
    }
  };

  const handleDownloadPdf = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/invoices/${invoiceId}/download-pdf`),
        {
          responseType: 'blob',
          withCredentials: true,
        }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank').focus();
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError('Kunde inte ladda ner PDF-filen. Vänligen försök igen senare.');
    }
  };

  const handlePaymentReceived = () => {
    setShowPaymentModal(true);
  };

  const handleRefundPayment = () => {
    // Simply show the payment modal - the amount will already be negative
    // from the invoiceData.totals.grand_total for credit notes
    setShowPaymentModal(true);
  };

  const confirmPaymentReversal = async () => {
    try {
      const response = await axios.post(
        getApiUrl(`${companyId}/invoices/${invoiceId}/payments/${selectedPayment.paymentId}/reverse`),
        {},
        { withCredentials: true }
      );

      if (response.data.success) {
        await fetchInvoice();
        setSuccessMessage('Betalningen har återkallats.');
      }
    } catch (error) {
      console.error('Error reversing payment:', error);
      setError('Kunde inte återkalla betalningen. Vänligen försök igen senare.');
    } finally {
      setShowReversalConfirmModal(false);
      setSelectedPayment(null);
    }
  };

  // Update the renderTimelineEvent function to use the new calculations
  const renderTimelineEvent = (event) => {
    const isCredit = invoiceData.invoice_type === DocumentType.CREDIT_NOTE;

    if (event.type === 'payment') {
      return (
        <>
          <div className={`timeline-badge ${isCredit ? 'warning' : 'primary'}`}></div>
          <div className="timeline-panel text-muted">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={isCredit ? faMoneyBillTransfer : faMoneyBill}
                  className={isCredit ? "text-warning me-2" : "text-success me-2"}
                />
                <span>
                  {dayjs(event.date).format('YYYY-MM-DD HH:mm')}
                  {event.created_by && ` • ${event.created_by}`}
                </span>
              </div>
              <span className={isCredit ? "text-warning fw-bold" : "text-success fw-bold"}>
                {isCredit ? "-" : ""}{formatCurrency(event.amount)}
              </span>
            </div>
            <div className="">
              <div className="mb-1">
                <div>{isCredit ? "Återbetalning till kund" : "Betalning registrerad"}</div>
                <div className="small text-muted">
                  {isCredit ? (
                    <>
                      Totalt återbetalt: {formatCurrency(event.runningPaymentTotal)}
                      <br />
                      Kvar att återbetala: {formatCurrency(event.remainingAmount)}
                    </>
                  ) : (
                    <>
                      Totalt betalat: {formatCurrency(event.runningPaymentTotal)}
                      <br />
                      Kvar att betala: {formatCurrency(event.remainingAmount)}
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={`/${companyId}/verifications/${event.verificationId}`}
                  className="text-primary small"
                  onClick={(e) => e.stopPropagation()}
                >
                  Verifikat {event.verificationSeries}{event.verificationNumber}
                </Link>
                {!event.reversalVerificationId && (
                  <Button
                    variant="link"
                    className="text-danger p-0"
                    onClick={() => handlePaymentReversal(event)}
                  >
                    Ångra
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else if (event.type === 'credit_note') {
      console.log('Credit note event:', event);
      const displayAmount = Math.abs(event.amount);
      const isInvoiceView = !isCredit;  // true when viewing the original invoice

      return (
        <>
          <div className="timeline-badge warning"></div>
          <div className="timeline-panel text-muted">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faCreditCard} className="text-warning me-2" />
                <span>{dayjs(event.date).format('YYYY-MM-DD HH:mm')}
                  {event.created_by && ` • ${event.created_by}`}</span>
              </div>
              <span className="text-warning fw-bold">-{formatCurrency(displayAmount)}</span>
            </div>
            <div>
              <div className="mb-1">
                {isInvoiceView ? (
                  <>
                    <div>Kreditfaktura {event.creditNoteNumber} skapad</div>
                    <div className="small text-muted">
                      {event.refundStatus === 'refunded' ? (
                        <span className="text-success">Återbetalad till kund</span>
                      ) : (
                        <span className="text-warning">Väntar på återbetalning</span>
                      )}
                    </div>
                    <Link
                      to={`/${companyId}/view-invoice/${event.creditNoteId}`}
                      className="text-primary small"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Visa kreditfaktura
                    </Link>
                  </>
                ) : (
                  // Existing credit note view rendering
                  <>
                    <div>Kreditfaktura {event.creditNoteNumber}</div>
                    <div className="small text-muted">
                      Kvar att betala: {formatCurrency(event.remainingAmount)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else if (event.type === 'status') {
      const { icon } = getStatusIcon(event.status);
      return (
        <>
          <div className="timeline-badge primary"></div>
          <div className="timeline-panel text-muted">
            <div className="d-flex align-items-center mb-1">
              <FontAwesomeIcon icon={icon} className="text-success me-2" />
              <span>{dayjs(event.date).format('YYYY-MM-DD HH:mm')}</span>
            </div>
            <div className="">
              <span>{event.displayName}</span>
            </div>
          </div>
        </>
      );
    } else if (event.type === 'link') {
      return (
        <>
          <div className="timeline-badge info"></div>
          <div className="timeline-panel text-muted">
            <div className="d-flex align-items-center mb-1">
              <FontAwesomeIcon icon={faLink} className="text-info me-2" />
              <span>{dayjs(event.date).format('YYYY-MM-DD HH:mm')}</span>
            </div>
            <div className="">
              <div>Kopplad till faktura</div>
              <Link
                to={`/${companyId}/view-invoice/${event.linkedInvoiceId}`}
                className="text-primary small"
                onClick={(e) => e.stopPropagation()}
              >
                Visa faktura
              </Link>
            </div>
          </div>
        </>
      );
    }
  };

  const determineNextAction = (currentStatus) => {
    console.log('determineNextAction: Current status:', currentStatus);
    console.log('determineNextAction: Invoice data:', invoiceData);

    const documentType = invoiceData.invoice_type || DocumentType.INVOICE;
    const isCredit = documentType === DocumentType.CREDIT_NOTE;

    // For credit notes
    if (isCredit) {
      switch (currentStatus) {
        case StatusType.DRAFT:
          setNextAction({ text: 'Publicera kreditfaktura', nextStatus: StatusType.PUBLISHED });
          break;
        case StatusType.PUBLISHED:
          // Only show refund option if the related invoice is fully paid
          if (relatedInvoiceData &&
            relatedInvoiceData.payments?.reduce((sum, p) => sum + parseFloat(p.amount_paid), 0) >= relatedInvoiceData.totals.grand_total) {
            const creditRemainingAmount = Math.abs(invoiceData.totals.grand_total) - totalPaid;
            if (creditRemainingAmount > 0) {
              // Change this to trigger payment modal instead of status update
              setNextAction({
                text: 'Registrera återbetalning',
                nextStatus: StatusType.REFUNDED,
                isPayment: true  // Flag to indicate this should open payment modal
              });
            } else {
              setNextAction(null);
            }
          } else {
            setNextAction(null);
          }
          break;
        default:
          setNextAction(null);
      }
    }
    // For regular invoices
    else {
      const totalPaid = paymentHistory.reduce((sum, p) => sum + parseFloat(p.amount_paid), 0);
      const isFullyPaid = totalPaid >= invoiceData?.totals?.grand_total;

      switch (currentStatus) {
        case StatusType.DRAFT:
          setNextAction({ text: 'Publicera faktura', nextStatus: StatusType.PUBLISHED });
          break;
        case StatusType.PUBLISHED:
        case StatusType.OVERDUE:
          if (!isFullyPaid) {
            setNextAction({ text: 'Registrera betalning', nextStatus: StatusType.PAID });
          } else {
            setNextAction(null);
          }
          break;
        default:
          setNextAction(null);
      }
    }
  };

  const renderActionButton = () => {
    const documentType = invoiceData.invoice_type || DocumentType.INVOICE;
    const isCredit = documentType === DocumentType.CREDIT_NOTE;
    const mainButtonText = nextAction ? nextAction.text : 'Visa meny';

    return (
      <SplitButton
        as={ButtonGroup}
        id="invoice-actions-dropdown"
        drop="down"
        variant="outline-primary"
        title={markAsPaidLoading ? 'Uppdaterar...' : mainButtonText}
        onClick={nextAction ? handleActionClick : null}
        disabled={markAsPaidLoading}
        className="me-2"
      >
        {!isCredit && invoiceData?.status !== StatusType.DRAFT && (
          <Dropdown.Item onClick={handleCreateCreditNote}>
            Skapa kreditfaktura
          </Dropdown.Item>
        )}

        {!isCredit && [StatusType.PUBLISHED, StatusType.OVERDUE].includes(invoiceData?.status) && (
          <>
            <Dropdown.Item onClick={handlePaymentReceived}>
              Registrera betalning
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSendReminder()}>
              Skicka påminnelse
            </Dropdown.Item>
          </>
        )}

        <Dropdown.Item onClick={() => handleSendEmail()}>
          Skicka e-post
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleDownloadPdf()}>
          Ladda ner PDF
        </Dropdown.Item>
      </SplitButton>
    );
  };

  return (
    <>
      <PageTitle activeMenu="Kundfakturor" motherMenu="Fakturor" />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Laddar...</span>
          </Spinner>
        </div>
      ) : (
        <div className="col-xl-12 mt-4">
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
              {successMessage}
            </Alert>
          )}

          <div className='col-xl-12 mb-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center-horizontal' }}>
            <h2>Fakturadetaljer</h2>
            <div className='d-flex'>
              {renderActionButton()}
            </div>
          </div>

          <div className="col-xl-12 col-xxl-12 col-lg-12 mb-4 widget-timeline">
            <ul className="timeline">
              {combinedTimeline.map((event, index) => (
                <li key={index}>
                  {renderTimelineEvent(event)}
                </li>
              ))}
            </ul>
          </div>

          <Card className="mt-4">
            <Card.Body>
              <Row>
                <div className="accordion_body">
                  <div style={{ display: "flex", flexFlow: "wrap", justifyContent: "space-between" }}>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Kund</p>
                      <span className="font-w500">
                        <strong>{invoiceData.customername}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Fakturanr.</p>
                      <span className="font-w500">
                        <strong>{invoiceData.invoicenumber}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Fakturadatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(invoiceData.invoicedate).format('DD MMM YYYY')}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Förfallodatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(invoiceData.deadline).format('DD MMM YYYY')}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Status</p>
                      <span className="font-w500">
                        <strong>
                          <Badge bg="primary">{getStatusDisplayName(invoiceData.status)}</Badge>
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Valuta</p>
                      <span className="font-w500">
                        <strong>{invoiceData.currency}</strong>
                      </span>
                    </div>
                    {/* In the Card component, update the amount display */}
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Summa</p>
                      <span className="font-w500">
                        <strong>
                          {formatCurrency(invoiceData.totals.grand_total)}
                        </strong>
                        {totalCreditNoteAmount > 0 && (
                          <div className="small mt-1 text-warning">
                            Krediterat: -{formatCurrency(totalCreditNoteAmount)}
                          </div>
                        )}
                        <div className="small mt-1">
                          {totalPaid > 0 && (
                            <div>Totalt betalat: {formatCurrency(totalPaid)}</div>
                          )}
                          {remainingAmount < 0 ? (
                            // Show overpaid status
                            <div className="text-warning">
                              Överbetalad: {formatCurrency(Math.abs(remainingAmount))}
                              {invoiceData.credit_notes?.some(cn => !cn.is_refunded) && (
                                <div className="small">Väntar på kreditfaktura återbetalning</div>
                              )}
                            </div>
                          ) : remainingAmount > 0 ? (
                            <div className="text-warning">
                              Kvar att betala: {formatCurrency(remainingAmount)}
                            </div>
                          ) : (
                            <div className="text-success">Fullständigt betald</div>
                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>

          {pdfData ? <PDFViewer fileUrl={pdfData} /> : <Spinner animation="border" />}

          {/* Modals */}
          {nextAction && (
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Bekräfta åtgärd</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Är du säker på att du vill {nextAction.text.toLowerCase()}?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                  Avbryt
                </Button>
                <Button
                  variant="success"
                  onClick={() => handleStatusUpdate(nextAction.nextStatus)}
                  disabled={markAsPaidLoading}
                >
                  {markAsPaidLoading ? 'Uppdaterar...' : 'Ja, ' + nextAction.text.toLowerCase()}
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {invoiceData && (
            <PaymentReceivedModal
              show={showPaymentModal}
              handleClose={() => setShowPaymentModal(false)}
              invoiceData={{
                ...invoiceData,
                totals: {
                  ...invoiceData.totals,
                  grand_total: invoiceData.totals.grand_total
                }
              }}
              handlePaymentSubmit={handlePaymentSubmit}
            />
          )}

          <Modal
            show={showReversalConfirmModal}
            onHide={() => setShowReversalConfirmModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bekräfta återkallelse av betalning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Är du säker på att du vill återkalla denna betalning?</p>
              <p>Detta kommer att:</p>
              <ul>
                <li>Skapa en ny verifikation som återkallar betalningen</li>
                <li>Uppdatera fakturans betalningsstatus</li>
                <li>Markera betalningen som återkallad</li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowReversalConfirmModal(false)}
              >
                Avbryt
              </Button>
              <Button
                variant="danger"
                onClick={confirmPaymentReversal}
              >
                Återkalla betalning
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default InvoiceViewer;