/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import CurrentUser from "./CurrentUser";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = ({ companyId }) => {

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener('click', heartBlast);

  }, []);

  //let scrollPosition = useScrollPosition();
  const [scrollPosition, setScrollPosition] = useState(0);

  useScrollPosition(({ currPos }) => {
    setScrollPosition(currPos.y); // Assuming you are interested in vertical scroll
  });

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  /// Active menu
  let dashBoard = [
    "",
    "dashboard",
    "home"
  ],
    reports = [
      "vatreport",
      "balansreport",
      "resultatreport",
      "huvudbok",
    ],
    bookkeeping = [
      "verifications",
      "newverification",
    ],
    factoring = [
      "invoices",
      "create-invoices",
      "customers",
      "articles",
    ],
    purchaseandorders = [
      "supplier-invoices",
      "create-supplier-invoices",
      "suppliers",
    ],
    personell = [
      "employees",
      "payroll/periods",
      "schedule",
    ],
    settings = [
      "settings",
      "personal-settings",
    ],
    tools = [
      "moms",
      "timdebitering",
      "konto",
      "bokslut",
      "rapporter",
      "dokument",
    ];

  return (
    <div className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
      sidebarLayout.value === "horizontal" &&
      headerposition.value === "static"
      ? scrollPosition > 120
        ? "fixed"
        : ""
      : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">

        {/*<CurrentUser className="mb-5" />*/}

        <MM className="metismenu" id="menu">
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul >
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to={`/${companyId}/dashboard`}> Home</Link></li>
            </ul>
          </li>

          <li className={`${reports.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-chart-bar"></i>
              <span className="nav-text">Raporter</span>
            </Link>
            <ul >
              <li><Link className={`${path === "vatreport" ? "mm-active" : ""}`} to={`/${companyId}/vat-periods`}>Momsraport</Link></li>
              <li><Link className={`${path === "balansreport" ? "mm-active" : ""}`} to={`/${companyId}/reports/balance`}>Balansrapport</Link></li>
              <li><Link className={`${path === "balansreport" ? "mm-active" : ""}`} to={`/${companyId}/reports/result`}>Resultatrapport</Link></li>
              <li><Link className={`${path === "huvudbok" ? "mm-active" : ""}`} to={`/${companyId}/reports/mainledger`}>Huvudbok</Link></li>
            </ul>
          </li>

          <li className={`${bookkeeping.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-book"></i>
              <span className="nav-text">Bokföring</span>
            </Link>
            <ul >
              <li><Link className={`${path === "verifications" ? "mm-active" : ""}`} to={`/${companyId}/verifications`}>Bokfört</Link></li>
              <li><Link className={`${path === "verifications" ? "mm-active" : ""}`} to={`/${companyId}/newverification`}>Nytt</Link></li>
            </ul>
          </li>

          <li className={`${factoring.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-money-bill"></i>
              <span className="nav-text">Fakturering och offerter</span>
            </Link>
            <ul >
              <li><Link className={`${path === "invoices" ? "mm-active" : ""}`} to={`/${companyId}/invoices`}>Kundfakturor</Link></li>
              <li><Link className={`${path === "invoices" ? "mm-active" : ""}`} to={`/${companyId}/quotes`}>Offerter</Link></li>
              <li><Link className={`${path === "customers" ? "mm-active" : ""}`} to={`/${companyId}/customers`}>Kunder</Link></li>
              <li><Link className={`${path === "articles" ? "mm-active" : ""}`} to={`/${companyId}/articles`}>Artiklar</Link></li>
            </ul>
          </li>

          <li className={`${purchaseandorders.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-shopping-cart"></i>
              <span className="nav-text">Leverantörer och inköp</span>
            </Link>
            <ul >
              <li><Link className={`${path === "supplier-invoices" ? "mm-active" : ""}`} to={`/${companyId}/supplier-invoices`}>Leverantörsfakturor</Link></li>
              {/*<li><Link className={`${path === "create-supplier-invoices" ? "mm-active" : ""}`} to={`/${companyId}/create-supplier-invoices`}>Skapa ny leverantörsfaktura</Link></li>*/}
              <li><Link className={`${path === "suppliers" ? "mm-active" : ""}`} to={`/${companyId}/suppliers`}>Leverantörer</Link></li>
            </ul>
          </li>

          <li className={`${personell.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-person-booth"></i>
              <span className="nav-text">Personal</span>
            </Link>
            <ul >
              <li><Link className={`${path === "employees" ? "mm-active" : ""}`} to={`/${companyId}/employees`}>Anställda</Link></li>
              <li><Link className={`${path === "periods" ? "mm-active" : ""}`} to={`/${companyId}/payroll/periods`}>Löner</Link></li>
              <li><Link className={`${path === "schedule" ? "mm-active" : ""}`} to={`/${companyId}/schedule`}>Schema</Link></li>
            </ul>
          </li>

          <li className={`${tools.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-tools"></i>
              <span className="nav-text">Verktyg</span>
            </Link>
            <ul >
              <li><Link className={`${path === "employees" ? "mm-active" : ""}`} to={`/${companyId}/salarycalculator`}>Räkna ut lön</Link></li>
            </ul>
          </li>

          <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-cogs"></i>
              <span className="nav-text">Inställningar</span>
            </Link>
            <ul >
              <li><Link className={`${path === "settings" ? "mm-active" : ""}`} to={`/${companyId}/settings`}>Företaget</Link></li>
              {/*<li><Link className={`${path === "personal-settings" ? "mm-active" : ""}`} to={`/${companyId}/personal-settings`}>Personliga</Link></li>*/}
            </ul>
          </li>

          {/*<li className={`${forms.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-file-alt"></i>
              <span className="nav-text forms">Forms</span>
            </Link>
            <ul >
              <li><Link className={`${path === "form-element" ? "mm-active" : ""}`} to="/form-element">Form Elements</Link></li>
              <li><Link className={`${path === "form-wizard" ? "mm-active" : ""}`} to="/form-wizard"> Wizard</Link></li>
              <li>
                <Link className={`${path === "form-editor-summernote" ? "mm-active" : ""}`} to="/form-editor-summernote">
                  Summernote
                </Link>
              </li>
              <li><Link className={`${path === "form-pickers" ? "mm-active" : ""}`} to="/form-pickers">Pickers</Link></li>
              <li>
                <Link className={`${path === "form-validation-jquery" ? "mm-active" : ""}`} to="/form-validation-jquery">
                  Form Validate
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${table.includes(path) ? "mm-active" : ""}`}>
             <Link className="has-arrow ai-icon" to="#" ><i className="fas fa-table"></i><span className="nav-text">Table</span></Link>
            <ul>
                <li>
                <Link className={`${ path === "table-filtering" ? "mm-active" : "" }`} to="/table-filtering">
                  Table Filtering
                </Link>
              </li>
              <li>
                <Link className={`${ path === "table-sorting" ? "mm-active" : "" }`} to="/table-sorting">
                  Table Sorting
                </Link>
              </li>
              <li>
                <Link className={`${ path === "table-bootstrap-basic" ? "mm-active" : "" }`} to="/table-bootstrap-basic">
                  Bootstrap
                </Link>
              </li>
                <li>
                  <Link className={`${ path === "table-datatable-basic" ? "mm-active" : ""}`} to="/table-datatable-basic">
                    Datatable
                  </Link>
                </li>
            </ul>
          </li>
          <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-clone"></i>
              <span className="nav-text">Pages</span>
            </Link>
              <ul >
                <li className={`${error.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#" >Error</Link>
                  <ul>
                    <li><Link className={`${ path === "page-error-400" ? "mm-active" : "" }`} to="/page-error-400">Error 400</Link></li>
                    <li><Link className={`${ path === "page-error-403" ? "mm-active" : "" }`} to="/page-error-403">Error 403</Link></li>
                    <li><Link className={`${ path === "page-error-404" ? "mm-active" : "" }`} to="/page-error-404">Error 404</Link></li>
                    <li><Link className={`${ path === "page-error-500" ? "mm-active" : "" }`} to="/page-error-500">Error 500</Link></li>
                    <li><Link className={`${ path === "page-error-503" ? "mm-active" : "" }`} to="/page-error-503">Error 503</Link></li>
                  </ul>
                </li>
                <li><Link className={`${path === "page-lock-screen" ? "mm-active" : ""}`} to="/page-lock-screen">Lock Screen</Link></li>
              </ul>
          </li>
        */}
        </MM>
        <div className="copyright">
          <p><strong>LedgerLoop Group AB</strong> © 2024 All Rights Reserved</p>
          <p className="fs-12">Made with <span className="heart"></span> by LedgerLoop Group AB</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
