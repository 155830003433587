import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Button, Alert, Spinner } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import CreditNoteItemsCard from './CreditNoteItemsCard';
import DeliveryOptions from './components/InvoiceDeliveryOptions';
import PageTitle from '../../../layouts/PageTitle';
import swal from 'sweetalert';
import { isEqual } from 'lodash';

const CreditNoteForm = () => {
  const { companyId, invoiceId } = useParams();
  const history = useHistory();

  // State management
  const [loading, setLoading] = useState(true);
  const [originalInvoice, setOriginalInvoice] = useState(null);
  const [creditNote, setCreditNote] = useState(null);
  const [items, setItems] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [deliveryOptionValid, setDeliveryOptionValid] = useState(false);
  const [disableRounding, setDisableRounding] = useState(false);
  const [error, setError] = useState(null);
  const [creditNoteId, setCreditNoteId] = useState(null);

  // Change tracking state
  const [initialItems, setInitialItems] = useState([]);
  const [initialCreditNote, setInitialCreditNote] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    // Only run this effect when we have initial items to compare against
    if (!initialItems.length) return;

    // Compare quantities of regular items and existence/content of text items
    const hasChanges = items.some((currentItem, index) => {
      const initialItem = initialItems[index];

      // New item added
      if (!initialItem) return true;

      // Check changes based on item type
      if (currentItem.type === 'Text') {
        return !initialItem || currentItem.description !== initialItem.description;
      } else {
        return currentItem.quantity !== initialItem.quantity;
      }
    });

    // Update unsaved changes flag if there are actual changes
    if (hasChanges !== hasUnsavedChanges) {
      setHasUnsavedChanges(hasChanges);
    }
  }, [items, initialItems]);

  const initialize = async () => {
    try {
      const invoiceResponse = await axios.get(
        getApiUrl(`${companyId}/invoices/${invoiceId}/details`),
        { withCredentials: true }
      );

      console.log('Fetch initial invoice details: ', invoiceResponse.data);

      if (invoiceResponse.data.invoice_type === 'credit_note') {

        console.log('We are in edit mode for credit note');

        // We're editing an existing credit note
        setCreditNote(invoiceResponse.data);
        setInitialCreditNote(invoiceResponse.data);
        setCreditNoteId(invoiceId);

        // Fetch the original invoice this credit note is based on
        if (invoiceResponse.data.related_invoice_id) {
          const originalInvoiceResponse = await axios.get(
            getApiUrl(`${companyId}/invoices/${invoiceResponse.data.related_invoice_id}/details`),
            { withCredentials: true }
          );
          setOriginalInvoice(originalInvoiceResponse.data);

          const creditNoteItems = invoiceResponse.data.items;
          const originalItems = originalInvoiceResponse.data.items;

          const mappedItems = creditNoteItems.map(creditItem => {
            if (creditItem.type === 'text') {
              return creditItem;
            }

            const originalItem = originalItems.find(origItem =>
              origItem.productcode === creditItem.productcode
            );

            return {
              ...creditItem,
              quantity: Math.abs(creditItem.quantity),
              original_quantity: originalItem ? originalItem.quantity : 0
            };
          });

          setItems(mappedItems);
          setInitialItems(JSON.parse(JSON.stringify(mappedItems)));
          setDisableRounding(invoiceResponse.data.disable_rounding);
        }
      } else {
        console.log('We are in create mode for credit note');
        // We're creating a new credit note based on an existing invoice
        setOriginalInvoice(invoiceResponse.data);

        const mappedItems = invoiceResponse.data.items.map(item => ({
          ...item,
          quantity: 0,
          original_quantity: item.quantity
        }));

        setItems(mappedItems);
        setInitialItems(JSON.parse(JSON.stringify(mappedItems)));
        setDisableRounding(invoiceResponse.data.disable_rounding);
      }
    } catch (error) {
      console.error('Error initializing credit note form:', error);
      setError('Kunde inte läsa in fakturainformation');
    } finally {
      setLoading(false);
    }
  };

  const handleItemUpdate = (index, updatedItem) => {
    console.log('handleItemUpdate', index, updatedItem);
    setItems(prevItems => {
      const newItems = prevItems.map((item, idx) =>
        idx === index ? { ...item, ...updatedItem } : item
      );
      const hasChanges = newItems[index].quantity !== initialItems[index].quantity;
      setHasUnsavedChanges(hasChanges);
      return newItems;
    });
  };

  const handleAddTextItem = (textItem) => {
    console.log('handleAddTextItem', textItem);
    setItems(prevItems => {
      const newItems = [...prevItems, textItem];
      setHasUnsavedChanges(true);
      return newItems;
    });
  };

  const handleDeleteItem = (index) => {
    console.log('handleDeleteItem', index);
    setItems(prevItems => {
      const newItems = prevItems.filter((_, idx) => idx !== index);
      setHasUnsavedChanges(true);
      return newItems;
    });
  };

  const validateForm = () => {
    /*if (!deliveryOptionValid) {
      swal('Varning', 'Vänligen välj ett giltigt leveransalternativ', 'warning');
      return false;
    }*/

    const hasItemsToCredit = items.some(item =>
      item.type !== 'text' && item.quantity > 0
    );

    if (!hasItemsToCredit) {
      swal('Varning', 'Vänligen välj minst en artikel att kreditera', 'warning');
      return false;
    }

    const invalidQuantities = items.filter(item =>
      item.type !== 'text' &&
      item.quantity > item.original_quantity
    );

    if (invalidQuantities.length > 0) {
      swal('Varning', 'Krediterat antal kan inte överstiga ursprungligt antal', 'warning');
      return false;
    }

    return true;
  };

  const handleSaveDraft = async () => {

    console.log('handleSaveDraft');

    if (!validateForm()) return null;

    try {
      const data = {
        items: items,
        customerid: creditNote ? creditNote.customerid : originalInvoice.customerid,
        disable_rounding: disableRounding,
        status: 'Draft',
        original_invoice_id: originalInvoice.invoiceid
      };

      let response;
      if (creditNoteId) {
        console.log('Updating existing credit note');

        response = await axios.put(
          getApiUrl(`${companyId}/credit-notes/${creditNoteId}`),
          data,
          { withCredentials: true }
        );
      } else {
        console.log('Creating new credit note');

        response = await axios.post(
          getApiUrl(`${companyId}/invoices/${invoiceId}/credit-note`),
          data,
          { withCredentials: true }
        );
      }

      console.log('Save draft response:', response.data);

      if (response.data.success) {
        const newCreditNoteId = response.data.creditNoteId || creditNoteId;
        setCreditNoteId(newCreditNoteId);
        setInitialItems(JSON.parse(JSON.stringify(items)));
        setInitialCreditNote(creditNote);
        setHasUnsavedChanges(false);
        swal('Klart', 'Utkast sparat', 'success');
        return newCreditNoteId;
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      swal('Error', 'Kunde inte spara utkast', 'error');
      return null;
    }
  };

  const handlePublish = async () => {
    console.log('handlePublish');

    if (!validateForm()) return;

    if (!deliveryOption.method) {
      swal('Varning', 'Vänligen välj ett leveransalternativ', 'warning');
      return;
    }

    // Always save before publishing
    let creditNoteIdToPublish;

    if (!creditNoteId || hasUnsavedChanges) {
      const confirmSave = await swal({
        title: hasUnsavedChanges ? 'Du har osparade ändringar!' : 'Spara först',
        text: hasUnsavedChanges
          ? 'Vill du spara ändringarna innan du publicerar kreditfakturan?'
          : 'Kreditfakturan måste sparas innan publicering.',
        icon: 'warning',
        buttons: {
          cancel: 'Avbryt',
          save: {
            text: 'Spara och publicera',
            value: 'save',
          },
        },
      });

      if (confirmSave === 'save') {
        console.log('User chose save, Saving draft before publishing');

        creditNoteIdToPublish = await handleSaveDraft();
        if (!creditNoteIdToPublish) {
          swal('Error', 'Kunde inte spara kreditfakturan före publicering', 'error');
          return;
        }
      } else {
        return; // User cancelled
      }
    } else {
      creditNoteIdToPublish = creditNoteId;
    }

    console.log('Publishing credit note:', creditNoteIdToPublish);

    await publishCreditNote(creditNoteIdToPublish);
  };

  const publishCreditNote = async (creditNoteIdToPublish) => {
    try {
      console.log('Publishing credit note:', creditNoteIdToPublish);
      const response = await axios.post(
        getApiUrl(`${companyId}/invoices/credit-notes/${creditNoteIdToPublish}/publish`),
        {
          action: deliveryOption.method,
          email: deliveryOption.method === 'email' ? deliveryOption.email : undefined
        },
        {
          withCredentials: true,
          responseType: deliveryOption.method === 'download' ? 'blob' : 'json'
        }
      );

      if (deliveryOption.method === 'download' && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `credit-note-${creditNoteIdToPublish}.pdf`;
        link.click();
        window.URL.revokeObjectURL(url);
      }

      swal('Klart', 'Kreditfaktura publicerad', 'success').then(() => {
        history.push(`/${companyId}/invoices`);
      });
    } catch (error) {
      console.error('Error publishing credit note:', error);
      swal('Error', 'Kunde inte publicera kreditfaktura', 'error');
    }
  };

  // Handle navigation prompts for unsaved changes
  useEffect(() => {
    const unblock = history.block((promptLocation) => {
      if (hasUnsavedChanges) {
        swal({
          title: 'Du har osparade ändringar!',
          text: 'Vill du spara utkastet innan du lämnar sidan?',
          icon: 'warning',
          buttons: {
            cancel: 'Avbryt',
            discard: {
              text: 'Fortsätt utan att spara',
              value: 'discard',
            },
            save: {
              text: 'Spara utkast',
              value: 'save',
            },
          },
        }).then((value) => {
          switch (value) {
            case 'save':
              handleSaveDraft()
                .then(() => {
                  unblock();
                  history.push(promptLocation.pathname);
                })
                .catch(() => {
                  swal('Error', 'Kunde inte spara utkast. Navigation avbruten.', 'error');
                });
              break;
            case 'discard':
              setHasUnsavedChanges(false);
              unblock();
              history.push(promptLocation.pathname);
              break;
            default:
              break;
          }
        });
        return false;
      }
      return true;
    });

    return () => {
      if (unblock) {
        unblock();
      }
    };
  }, [hasUnsavedChanges, history]);

  // Handle browser refresh/close
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Laddar...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="col-xl-12">
        <Alert variant="danger">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu={creditNoteId ? "Redigera Kreditfaktura" : "Skapa Kreditfaktura"}
        motherMenu="Fakturor"
      />

      <div className="row">
        <div className="col-xl-12">
          <Alert variant="info" className="d-flex align-items-center">
            <span>
              {creditNoteId
                ? `Redigerar kreditfaktura #${creditNote?.invoicenumber} för faktura #${originalInvoice?.invoicenumber}`
                : `Skapar kreditfaktura för faktura #${originalInvoice?.invoicenumber}`
              }
            </span>
          </Alert>
        </div>

        <div className="col-xl-12">
          <CreditNoteItemsCard
            items={items}
            onUpdateItem={handleItemUpdate}
            onAddTextItem={handleAddTextItem}
            onDeleteItem={handleDeleteItem}
            disableRounding={disableRounding}
            currency={creditNote?.currency || originalInvoice?.currency}
          />
        </div>

        <div className="col-xl-12">
          <Card>
            <Card.Body>
              <h4 className="fs-24 font-w800">Leveransmetod</h4>
              <DeliveryOptions
                onDeliveryOptionChange={setDeliveryOption}
                onValidationChange={setDeliveryOptionValid}
              />
            </Card.Body>
          </Card>
        </div>

        <div className="col-xl-6">
          <div className="text-begin mt-4">
            <Button
              variant="primary"
              onClick={handlePublish}
              disabled={!deliveryOptionValid}
              className="me-1 me-sm-3"
            >
              Publicera
            </Button>
            <Button
              variant="light"
              onClick={handleSaveDraft}
              className="me-sm-3"
            >
              Spara Utkast
            </Button>
            <Button
              variant="light"
              onClick={() => history.goBack()}
              className="me-sm-3"
            >
              Avbryt
            </Button>
          </div>
          {hasUnsavedChanges && <p className="text-warning mt-2">Du har osparade ändringar</p>}
        </div>
      </div>
    </>
  );
};

export default CreditNoteForm;