import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload } from '@fortawesome/free-solid-svg-icons';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';
import { getApiUrl } from '../../../utils/ApiUtils';
import axios from '../../../services/AxiosInstance';
import { Table, Form } from 'react-bootstrap';

// Style block component for CSS that needs hover states and other dynamic features
const StyleBlock = () => (
  <style>
    {`
      .invalid-tooltip {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 5;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: 0.875rem;
        color: #fff;
        background-color: rgba(220, 53, 69, 0.9);
        border-radius: 0.25rem;
      }

      td:hover .invalid-tooltip {
        display: block;
      }

      .table td {
        position: relative;
      }

      .has-error {
        border-color: #dc3545;
        background-color: #fff8f8;
      }

      .table-hover tbody tr:hover {
        background-color: rgba(0,0,0,.075);
      }

      .row-error {
        background-color: #fff3f3;
      }

      .selected-row {
        background-color: #f8f9fa;
      }
    `}
  </style>
);

const ImportTypes = {
  ARTICLES: {
    type: 'articles',
    title: 'Import Articles',
    apiEndpoint: '/articles/import',
    requiredFields: {
      productcode: {
        label: "Product Code",
        required: true,
        validate: (value) => {
          if (!value) return "Product code is required";
          if (value.length > 150) return "Product code too long (max 45 characters)";
          return null;
        }
      },
      description: {
        label: "Description",
        validate: (value) => value?.length > 150 ? "Description too long (max 45 characters)" : null
      },
      unitprice: {
        label: "Unit Price",
        required: true,
        validate: (value) => {
          if (!value) return "Unit price is required";
          if (isNaN(value) || parseFloat(value) < 0) return "Invalid unit price";
          return null;
        }
      },
      type: {
        label: "Type",
        options: ['Goods', 'Service'],
        validate: (value) => {
          if (value && !['Goods', 'Service'].includes(value)) {
            return "Invalid type";
          }
          return null;
        }
      },
      unit: {
        label: "Unit",
        options: ['Piece', 'Hour', 'Liter', 'Kilogram', 'Square Meter',
          'Cubic Meter', 'Meter', 'Package', 'Set', 'Day', 'Month'],
        validate: (value) => {
          if (value && !['Piece', 'Hour', 'Liter', 'Kilogram', 'Square Meter',
            'Cubic Meter', 'Meter', 'Package', 'Set', 'Day', 'Month'].includes(value)) {
            return "Invalid unit";
          }
          return null;
        }
      },
      tax_rate: {
        label: "Tax Rate",
        options: ['0%', '6%', '12%', '25%'],
        validate: (value) => {
          if (value && !['0%', '6%', '12%', '25%'].includes(value)) {
            return "Invalid tax rate";
          }
          return null;
        }
      }
    }
  },

  CUSTOMERS: {
    type: 'customers',
    title: 'Import Customers',
    apiEndpoint: '/sales/customers/import',
    requiredFields: {
      name: {
        label: "Company Name",
        validate: (value) => {
          if (value && value.length > 45) return "Company name too long (max 45 characters)";
          return null;
        }
      },
      orgnumber: {
        label: "Organization Number",
        validate: (value) => value?.length > 45 ? "Organization number too long (max 45 characters)" : null
      },
      vatnumber: {
        label: "VAT Number",
        validate: (value) => value?.length > 45 ? "VAT number too long (max 45 characters)" : null
      },
      type: {
        label: "Type",
        options: ['company', 'individual'],
        validate: (value) => {
          if (value && !['company', 'individual'].includes(value)) {
            return "Invalid type";
          }
          return null;
        }
      },
      phone: {
        label: "Phone",
        validate: (value) => value?.length > 45 ? "Phone number too long (max 45 characters)" : null
      },
      street1: {
        label: "Street Address",
        validate: (value) => value?.length > 45 ? "Street address too long (max 45 characters)" : null
      },
      city: {
        label: "City",
        validate: (value) => value?.length > 45 ? "City name too long (max 45 characters)" : null
      },
      postalcode: {
        label: "Postal Code",
        validate: (value) => value?.length > 45 ? "Postal code too long (max 45 characters)" : null
      },
      country: {
        label: "Country",
        validate: (value) => value?.length > 45 ? "Country name too long (max 45 characters)" : null
      },
      email: {
        label: "Email",
        validate: (value) => {
          if (!value) return null;
          if (value.length > 45) return "Email too long (max 45 characters)";
          if (!value.includes('@')) return "Invalid email format";
          return null;
        }
      },
      payment_terms: {
        label: "Payment Terms (days)",
        validate: (value) => {
          if (!value) return null;
          if (isNaN(value) || parseInt(value) < 0) return "Invalid payment terms";
          return null;
        }
      },
      first_name: {
        label: "First Name",
        validate: (value) => value?.length > 100 ? "First name too long (max 100 characters)" : null
      },
      last_name: {
        label: "Last Name",
        validate: (value) => value?.length > 100 ? "Last name too long (max 100 characters)" : null
      }
    }
  },
  SUPPLIERS: {
    type: 'suppliers',
    title: 'Import Suppliers',
    apiEndpoint: '/suppliers/import',
    requiredFields: {
      name: {
        label: "Name",
        required: true,
        validate: (value) => {
          if (!value) return "Name is required";
          if (value.length > 45) return "Name too long (max 45 characters)";
          return null;
        }
      },
      orgnumber: {
        label: "Organization Number",
        required: true,
        validate: (value) => {
          if (!value) return "Organization number is required";
          if (value.length > 45) return "Organization number too long (max 45 characters)";
          return null;
        }
      },
      vatnumber: {
        label: "VAT Number",
        validate: (value) => value?.length > 45 ? "VAT number too long (max 45 characters)" : null
      },
      postadress: {
        label: "Address",
        validate: (value) => value?.length > 45 ? "Address too long (max 45 characters)" : null
      },
      postadress2: {
        label: "Address 2",
        validate: (value) => value?.length > 45 ? "Address 2 too long (max 45 characters)" : null
      },
      postnumber: {
        label: "Postal Number",
        validate: (value) => value?.length > 45 ? "Postal number too long (max 45 characters)" : null
      },
      city: {
        label: "City",
        validate: (value) => value?.length > 45 ? "City too long (max 45 characters)" : null
      },
      country: {
        label: "Country",
        validate: (value) => value?.length > 45 ? "Country too long (max 45 characters)" : null
      },
      phone: {
        label: "Phone",
        validate: (value) => value?.length > 45 ? "Phone number too long (max 45 characters)" : null
      }
    }
  }
};

const DataPreviewEditor = ({
  previewData,
  columnMappings,
  config,
  onBack,
  onImport
}) => {
  const [editableData, setEditableData] = useState([]);
  const [importResults, setImportResults] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (previewData) {
      const initialData = previewData.map((row, index) => ({
        id: index,
        included: true,
        ...Object.entries(columnMappings).reduce((acc, [field, sourceField]) => ({
          ...acc,
          [field]: row[sourceField]
        }), {})
      }));
      setEditableData(initialData);
    }
  }, [previewData, columnMappings]);

  const handleDataChange = (rowId, field, value) => {
    setEditableData(prev => prev.map(row =>
      row.id === rowId ? { ...row, [field]: value } : row
    ));

    // Run validation on the changed field
    const fieldConfig = config.requiredFields[field];
    if (fieldConfig?.validate) {
      const error = fieldConfig.validate(value);
      if (error) {
        setValidationErrors(prev => ({
          ...prev,
          [rowId]: {
            ...(prev[rowId] || {}),
            [field]: error
          }
        }));
      } else {
        // Clear error if validation passes
        setValidationErrors(prev => {
          if (!prev[rowId]) return prev;
          const newRowErrors = { ...prev[rowId] };
          delete newRowErrors[field];
          return {
            ...prev,
            [rowId]: Object.keys(newRowErrors).length ? newRowErrors : undefined
          };
        });
      }
    }
  };

  const handleRowToggle = (rowId) => {
    setEditableData(prev => prev.map(row =>
      row.id === rowId ? { ...row, included: !row.included } : row
    ));
  };

  const validateRow = (row) => {
    const errors = {};
    Object.entries(config.requiredFields).forEach(([field, fieldConfig]) => {
      if (fieldConfig.validate) {
        const error = fieldConfig.validate(row[field]);
        if (error) {
          errors[field] = error;
        }
      }
    });
    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleImportClick = async () => {
    const selectedData = editableData.filter(row => row.included);
    if (selectedData.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'No Rows Selected',
        text: 'Please select at least one row to import'
      });
      return;
    }

    // Validate all selected rows
    const errors = {};
    let hasErrors = false;
    selectedData.forEach(row => {
      const rowErrors = validateRow(row);
      if (rowErrors) {
        errors[row.id] = rowErrors;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setValidationErrors(errors);
      Swal.fire({
        icon: 'error',
        title: 'Validation Errors',
        text: 'Please fix the highlighted errors before importing'
      });
      return;
    }

    try {
      const result = await onImport(selectedData);

      console.log('Import result:', result);

      setImportResults(result);

      if (result.errors) {
        const newErrors = {};
        result.errors.forEach(error => {
          newErrors[error.row - 2] = { _general: error.error };
        });
        setValidationErrors(newErrors);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Import Failed',
        text: error.message || 'An error occurred during import'
      });
    }
  };

  const renderFieldEditor = (field, row) => {
    const fieldConfig = config.requiredFields[field];
    const hasError = validationErrors[row.id]?.[field];
    const generalError = validationErrors[row.id]?._general;
    const errorMessage = hasError || generalError;

    if (!fieldConfig) {
      console.warn(`No configuration found for field: ${field}`);
      return null;
    }

    // Handle fields with predefined options
    if (fieldConfig.options) {
      return (
        <div className="position-relative">
          <Form.Select
            size="sm"
            value={row[field] || ''}
            onChange={(e) => handleDataChange(row.id, field, e.target.value)}
            className={hasError ? 'has-error' : ''}
          >
            <option value="">Select {fieldConfig.label}</option>
            {fieldConfig.options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Form.Select>
          {errorMessage && <div className="invalid-tooltip">{errorMessage}</div>}
        </div>
      );
    }

    // Handle numeric fields
    if (field === 'unitprice') {
      return (
        <div className="position-relative">
          <Form.Control
            size="sm"
            type="number"
            step="0.01"
            value={row[field] || ''}
            onChange={(e) => handleDataChange(row.id, field, e.target.value)}
            className={hasError ? 'has-error' : ''}
          />
          {errorMessage && <div className="invalid-tooltip">{errorMessage}</div>}
        </div>
      );
    }

    // Default text input
    return (
      <div className="position-relative">
        <Form.Control
          size="sm"
          type="text"
          value={row[field] || ''}
          onChange={(e) => handleDataChange(row.id, field, e.target.value)}
          className={hasError ? 'has-error' : ''}
        />
        {errorMessage && <div className="invalid-tooltip">{errorMessage}</div>}
      </div>
    );
  };

  return (
    <>
      <StyleBlock />
      <div className="d-flex flex-column gap-3">
        <div className="table-responsive">
          <Table hover bordered>
            <thead>
              <tr>
                <th className="text-center" style={{ width: '50px' }}>
                  <Form.Check
                    type="checkbox"
                    checked={editableData.every(row => row.included)}
                    onChange={() => {
                      const allSelected = editableData.every(row => row.included);
                      setEditableData(prev => prev.map(row => ({
                        ...row,
                        included: !allSelected
                      })));
                    }}
                  />
                </th>
                {Object.entries(config.requiredFields).map(([field, fieldConfig]) => (
                  <th key={field}>
                    {typeof fieldConfig === 'string' ? fieldConfig : fieldConfig.label}
                    {fieldConfig.required && <span className="text-danger">*</span>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {editableData.map((row) => {
                const hasErrors = validationErrors[row.id];
                const rowClassName = [
                  !row.included ? 'selected-row' : '',
                  hasErrors ? 'row-error' : ''
                ].filter(Boolean).join(' ');

                return (
                  <tr key={row.id} className={rowClassName}>
                    <td className="text-center">
                      <Form.Check
                        type="checkbox"
                        checked={row.included}
                        onChange={() => handleRowToggle(row.id)}
                      />
                    </td>
                    {Object.keys(config.requiredFields).map((field) => (
                      <td key={field}>
                        {renderFieldEditor(field, row)}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <span className="text-muted">
            Selected {editableData.filter(row => row.included).length} of {editableData.length} rows
          </span>
          <div className="d-flex gap-2">
            <Button variant="outline-secondary" onClick={onBack}>
              Back
            </Button>
            <Button variant="success" onClick={handleImportClick}>
              Import Selected
            </Button>
          </div>
        </div>

        {importResults && (
          <div className={`alert ${importResults.success ? 'alert-success' : 'alert-danger'}`}>
            {importResults.message}
            {importResults.errors && (
              <ul className="mt-2 mb-0">
                {importResults.errors.map((error, index) => (
                  <li key={index}>Row {error.row}: {error.error}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const GenericImporter = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [columnMappings, setColumnMappings] = useState({});

  const { companyId, importType } = useParams();
  const configKey = importType.toUpperCase();
  const config = ImportTypes[configKey];

  const history = useHistory();

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (file) {
      handleFile(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    maxFiles: 1
  });

  // Validation for config
  if (!config) {
    return (
      <Card>
        <Card.Header>
          <h4>Invalid Import Type</h4>
        </Card.Header>
        <Card.Body>
          <p>The specified import type is not supported.</p>
        </Card.Body>
      </Card>
    );
  }

  const handleFile = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        Papa.parse(e.target.result, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data && results.data.length > 0) {
              setHeaders(results.meta.fields);
              setPreviewData(results.data);
              setCurrentStep(1);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No data found in file'
              });
            }
          },
          error: function (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error parsing file: ' + error.message
            });
          }
        });
      };
      reader.readAsText(file);
      setUploadedFile(file);
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error reading file: ' + err.message
      });
    }
  };

  const handleImport = async (editedData) => {
    try {
      const formData = new FormData();
      formData.append('mappings', JSON.stringify(columnMappings));
      formData.append('data', JSON.stringify(editedData));

      const importUrl = getApiUrl(`${companyId}${config.apiEndpoint}`);
      const response = await axios.post(importUrl, formData, { withCredentials: true });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonText: 'OK'
        }).then(() => {
          // Navigate back to the previous page
          //history.goBack();
          // Or if you want to go to a specific route:
          history.push(`/${companyId}/${config.type}`);
        });
      }

      return response.data;
    } catch (error) {
      console.error('Import error:', error);
      throw new Error(error.response?.data?.message || 'Import failed');
    }
  };

  const renderFileUpload = () => (
    <div className="mb-4">
      <div
        {...getRootProps()}
        className="border border-2 border-dashed rounded p-5 text-center"
        style={{
          backgroundColor: isDragActive ? '#f8f9fa' : 'white',
          borderColor: isDragActive ? '#007bff' : '#dee2e6'
        }}
      >
        <input {...getInputProps()} />
        <FontAwesomeIcon
          icon={faUpload}
          className="mb-3"
          style={{ fontSize: '2em', color: '#6c757d' }}
        />
        {isDragActive ? (
          <p className="mb-0">Drop the file here ...</p>
        ) : (
          <div>
            <p className="mb-2">Dra och släp den fil du vill importera i denna ruta, eller klicka för att välja fil.</p>
            <p className="text-muted mb-0">Endast CSV filer.</p>
          </div>
        )}
      </div>
      {/*<div className="text-center mt-3">
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={handleDownloadTemplate}
        >
          <FontAwesomeIcon icon={faDownload} className="me-2" />
          Download Template
        </Button>
      </div>*/}
    </div>
  );

  const handleDownloadTemplate = () => {
    const headers = Object.values(config.requiredFields).map(field =>
      typeof field === 'string' ? field : field.label
    );
    const csv = Papa.unparse([{}], { fields: headers });

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${config.type}_template.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleColumnMapping = (systemField, selectedOption) => {
    setColumnMappings(prev => ({
      ...prev,
      [systemField]: selectedOption.value
    }));
  };

  const renderColumnMapping = () => (
    <div>
      {Object.entries(config.requiredFields).map(([field, fieldConfig]) => (
        <div key={field} className="d-flex align-items-center mb-3">
          <label className="form-label mb-0 me-3" style={{ minWidth: '200px' }}>
            {typeof fieldConfig === 'string' ? fieldConfig : fieldConfig.label}
            {fieldConfig.required && <span className="text-danger">*</span>}
          </label>
          <div style={{ width: '300px' }}>
            <Select
              options={headers.map(h => ({ value: h, label: h }))}
              onChange={(selected) => handleColumnMapping(field, selected)}
              placeholder="Select column"
              className="basic-single"
              classNamePrefix="select"
            />
          </div>
          {columnMappings[field] && previewData && previewData[0] && (
            <div className="ms-3 text-muted">
              Example: <span className="fw-medium">{previewData[0][columnMappings[field]]}</span>
            </div>
          )}
        </div>
      ))}
      <div className="d-flex justify-content-end gap-2 mt-4">
        <Button variant="outline-secondary" onClick={() => setCurrentStep(0)}>
          Back
        </Button>
        <Button variant="primary" onClick={() => setCurrentStep(2)}>
          Next
        </Button>
      </div>
    </div>
  );

  return (
    <Card>
      <Card.Header>
        <h4 className="mb-0">{config.title}</h4>
      </Card.Header>
      <Card.Body>
        <div className="form-wizard">
          <Stepper className="nav-wizard" activeStep={currentStep}>
            <Step className="nav-link" label="Ladda upp fil" />
            <Step className="nav-link" label="Mappa kolumner" />
            <Step className="nav-link" label="Förhandsgranska & Importera" />
          </Stepper>
        </div>

        <div className="mt-4">
          {currentStep === 0 && renderFileUpload()}
          {currentStep === 1 && renderColumnMapping()}
          {currentStep === 2 && (
            <DataPreviewEditor
              previewData={previewData}
              columnMappings={columnMappings}
              config={config}
              onBack={() => setCurrentStep(1)}
              onImport={handleImport}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default GenericImporter;