import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import axios from '../../../../../services/AxiosInstance';
import { Card, Badge, Table } from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import PageTitle from "../../../../layouts/PageTitle";
import SupplierInvoiceTable from '../../supplier-invoices/SupplierInvoiceTable';

import { getApiUrl } from '../../../../../utils/ApiUtils';

const SupplierDetails = () => {
  const { companyId, supplierId } = useParams();
  const [supplier, setSupplier] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [stats, setStats] = useState({ total_invoices: 0, pending_invoices: 0, paid_invoices: 0, overdue_invoices: 0, total_amount_due: 0, total_amount_paid: 0 });

  useEffect(() => {
    fetchSupplierDetails();
    fetchSupplierInvoices();
    fetchSupplierStats();
  }, [supplierId]);

  const fetchSupplierDetails = async () => {
    const getSupplierDetailsUrl = getApiUrl(`${companyId}/purchasing/suppliers/${supplierId}`);
    const res = await axios.get(getSupplierDetailsUrl, { withCredentials: true });
    setSupplier(res.data);
  };

  const fetchSupplierInvoices = async () => {
    const getSupplierInvoicesUrl = getApiUrl(`${companyId}/purchasing/suppliers/${supplierId}/invoices`);
    const res = await axios.get(getSupplierInvoicesUrl, { withCredentials: true });
    setInvoices(res.data);
  };

  const fetchSupplierStats = async () => {
    const getSupplierStatsUrl = getApiUrl(`${companyId}/purchasing/suppliers/${supplierId}/stats`);
    const res = await axios.get(getSupplierStatsUrl, { withCredentials: true });
    setStats(res.data);
  };

  const invoiceColumns = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'invoice_id',
    },
    {
      Header: 'Fakturanummer',
      accessor: 'invoice_number',
    },
    {
      Header: 'Fakturadatum',
      accessor: 'invoice_date',
    },
    {
      Header: 'Förfallodatum',
      accessor: 'due_date',
    },
    {
      Header: 'Belopp',
      accessor: 'amount',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props => <Badge bg={props.value === 'Paid' ? 'success' : props.value === 'Pending' ? 'warning' : 'danger'}>{props.value}</Badge>,
    },
  ], []);

  const chartData = {
    labels: ['Pending', 'Paid', 'Overdue'],
    datasets: [
      {
        label: 'Invoices',
        data: [stats.pending_invoices, stats.paid_invoices, stats.overdue_invoices],
        backgroundColor: ['#f39c12', '#27ae60', '#e74c3c'],
      },
    ],
  };

  return (
    <>
      <PageTitle activeMenu="Supplier Details" motherMenu="Suppliers" />
      <div className="col-xl-12">

        <Card>
          <Card.Header>
            <Card.Title>{supplier.name}</Card.Title>
          </Card.Header>
          <div className="card-body pb-3 d-flex flex-wrap justify-content-between align-items-top">
            <div className="me-3 mb-3">
              <p className="mb-2">Namn</p>
              <h5 className="mb-0">{supplier.name}</h5>
            </div>

            <div className="me-3 mb-3">
              <p className="mb-2">Adress</p>
              <h5 className="mb-0">{supplier.postadress}</h5>
              <h5 className="mb-0">{supplier.postnumber} {supplier.city}</h5>
              <h5 className="mb-0">{supplier.country}</h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">Organisationsnummer</p>
              <h5 className="mb-0">{supplier.orgnumber} </h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">VAT-nummer</p>
              <h5 className="mb-0">{supplier.vatnumber}</h5>
            </div>
          </div>
        </Card>

        <div className='row'>
          <div className="col-xl-6">
            <Card>
              <Card.Header>
                <Card.Title>Invoice Statistics</Card.Title>
              </Card.Header>
              <Card.Body>
                <Bar data={chartData} />
              </Card.Body>
            </Card>
          </div>

          <div className="col-xl-6">
            <Card>
              <Card.Header>
                <Card.Title>Total Outstanding Amount</Card.Title>
              </Card.Header>
              <Card.Body>
                <h3>{stats.total_amount_due} {supplier.currency}</h3>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fakturor</h4>
          </div>
          <div className="card-body">
            <SupplierInvoiceTable data={invoices} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierDetails;

/*import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SupplierInvoiceTable from '../../supplier-invoices/SupplierInvoiceTable';

const ViewSupplierDetailed = () => {
  const { companyId, supplierId } = useParams();
  const [supplier, setSupplier] = useState(null);
  const [supplierInvoices, setSupplierInvoices] = useState([]);
  const [statistics, setStatistics] = useState({ totalEarned: 0 });

  useEffect(() => {
    // Fetch customer details
    const fetchSupplierDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:3020/api/v1/${companyId}/suppliers/${supplierId}/details`, { withCredentials: true });
        setSupplier(response.data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    // Fetch customer invoices
    const fetchSupplierInvoices = async () => {
      try {
        const response = await axios.get(`http://localhost:3020/api/v1/${companyId}/suppliers/${supplierId}/invoices`, { withCredentials: true });
        setSupplierInvoices(response.data);
      } catch (error) {
        console.error('Error fetching customer invoices:', error);
      }
    };

    fetchSupplierDetails();
    fetchSupplierInvoices();
  }, [companyId, supplierId]);

  if (!supplier) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Om leverantören</h4>
          </div>
          <div className="card-body pb-3 d-flex flex-wrap justify-content-between align-items-top">
            <div className="me-3 mb-3">
              <p className="mb-2">Namn</p>
              <h5 className="mb-0">{supplier.name}</h5>
            </div>

            <div className="me-3 mb-3">
              <p className="mb-2">Adress</p>
              <h5 className="mb-0">{supplier.postaddress}</h5>
              <h5 className="mb-0">{supplier.postnumber} {supplier.city}</h5>
              <h5 className="mb-0">{supplier.country}</h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">Organisationsnummer</p>
              <h5 className="mb-0">{supplier.orgnumber} </h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">VAT-nummer</p>
              <h5 className="mb-0">SE{supplier.vatnumber}01</h5>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fakturor</h4>
          </div>
          <div className="card-body">
            <SupplierInvoiceTable data={supplierInvoices} />
          </div>
        </div>

        {/*
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Statistik</h4>
          </div>
          <div className="card-body">
            <p><strong>Total Earned:</strong> {statistics.totalEarned}</p>
          </div>
        </div>
}
      </div>
    </>
  );
};

export default ViewSupplierDetailed;
*/
