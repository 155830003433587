import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PaymentReceivedModal = ({ show, handleClose, invoiceData, handlePaymentSubmit }) => {
    const isCredit = invoiceData.invoice_type === 'credit_note';
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paidAmount, setPaidAmount] = useState(Math.abs(invoiceData.totals.grand_total)); // Show positive amount in input
    const [selectedAccount, setSelectedAccount] = useState('');

    console.log("Payment handle modal: ", invoiceData);

    const handleAccountChange = (e) => {
        setSelectedAccount(e.target.value);
    };

    const handleSubmit = () => {
        // Convert amount to negative for credit notes before submitting
        const submittedAmount = isCredit ? -Math.abs(paidAmount) : Math.abs(paidAmount);

        handlePaymentSubmit({
            paymentDate,
            paidAmount: submittedAmount,
            selectedAccount,
        });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isCredit ? 'Registrera återbetalning' : 'Registrera betalning av faktura'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Fakturanummer: {invoiceData.invoicenumber}</h5>
                <p>Kund: {invoiceData.customername}</p>

                <hr />

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{isCredit ? 'Återbetalningsdatum' : 'Betaldatum'}</Form.Label>
                        <DatePicker
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{isCredit ? 'Återbetalningssumma' : 'Summa'}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                value={paidAmount}
                                onChange={(e) => setPaidAmount(e.target.value)}
                                min="0"
                                max={Math.abs(invoiceData.totals.grand_total)}
                                step="0.01"
                                placeholder={isCredit ? "Ange återbetalningsbelopp" : "Ange betalningsbelopp"}
                            />
                            <InputGroup.Text>kr</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            {isCredit ? 'Återbetalt från konto' : 'Betalt till konto'}
                        </Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedAccount}
                            onChange={handleAccountChange}
                        >
                            <option value="">Välj konto</option>
                            <option value="1930">1930 - Bankkonto</option>
                            <option value="1940">1940 - Kontant</option>
                            <option value="1510">1510 - Accounts Receivable</option>
                            {/* Add more accounts as needed */}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Avbryt
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!selectedAccount || !paidAmount}
                >
                    {isCredit ? 'Bekräfta återbetalning' : 'Bekräfta'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentReceivedModal;