import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useParams, Link } from 'react-router-dom';
import { GlobalFilter } from './GlobalFilter';
import axios from '../../../../../services/AxiosInstance';
import './filtering.css';
import NewArticleModal from './NewArticleModal';
import { Spinner, SplitButton } from 'react-bootstrap';

import { getApiUrl } from '../../../../../utils/ApiUtils';
import { NoData } from '../../../SharedComponents/NoData';
import PageTitle from '../../../../layouts/PageTitle';

import { Dropdown } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';

export const ArticlesTable = () => {

  const history = useHistory();
  const { companyId } = useParams();

  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);

  // Translation mappings
  const TYPE_TRANSLATIONS = {
    'Goods': 'Varor',
    'Service': 'Tjänster',
    // Add more types if needed
  };

  const UNIT_TRANSLATIONS = {
    'Piece': 'Styck',
    'Day': 'Dag',
    // Add more units if needed
  };

  const ARTICLE_COLUMNS = [
    {
      Header: 'Produktkod',
      Footer: 'Produktkod',
      accessor: 'productcode',
    },
    {
      Header: 'Artikelnamn',
      Footer: 'Artikelnamn',
      accessor: 'description',
    },
    {
      Header: 'Typ',
      Footer: 'Typ',
      accessor: 'type',
      Cell: ({ value }) => TYPE_TRANSLATIONS[value] || value,
    },
    {
      Header: 'Enhet',
      Footer: 'Enhet',
      accessor: 'unit',
      Cell: ({ value }) => UNIT_TRANSLATIONS[value] || value,
    },
    {
      Header: 'Moms',
      Footer: 'Moms',
      accessor: 'tax_rate',
    },
    {
      Header: 'Pris (exkl. moms)',
      Footer: 'Pris',
      accessor: 'unitprice',
    },
    {
      Header: '',
      className: 'column-right',
      Cell: ({ row }) => (
        <Dropdown
          className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                handleRowClick(row.original);
              }}
            >
              Redigera artikel
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteArticle(row.original.id);
              }}
            >
              Ta bort artikel
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ];

  const columns = useMemo(() => ARTICLE_COLUMNS, []);

  const handleClose = () => {
    setSelectedArticle(null);
    setShow(false);
  };

  const handleSave = (newArticleData) => {
    saveNewArticle(newArticleData);
    setShow(false);
  };

  const handleUpdate = (articleId, updatedArticleData) => {
    updateArticle(articleId, updatedArticleData);
    setShow(false);
  };

  const updateArticle = (articleId, updatedData) => {
    const updatedArticle = {
      productcode: updatedData.productcode,
      description: updatedData.description,
      unitprice: updatedData.unitprice,
      type: updatedData.type,
      taxrate: updatedData.taxrate,
      unit: updatedData.unit,
    };

    const updateArticleApiUrl = getApiUrl(`${companyId}/articles/${articleId}`);
    axios
      .put(updateArticleApiUrl, updatedArticle, { withCredentials: true })
      .then(() => {
        fetchArticles();
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedArticle(null);
  };

  const saveNewArticle = (newData) => {
    const newArticle = {
      productcode: newData.productcode,
      description: newData.description,
      unitprice: newData.unitprice,
      type: newData.type,
      taxrate: newData.taxrate,
      unit: newData.unit,
    };

    const createArticleApiUrl = getApiUrl(`${companyId}/articles`);
    axios
      .post(createArticleApiUrl, newArticle, { withCredentials: true })
      .then(() => {
        fetchArticles();
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedArticle(null);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    setLoading(true);
    try {
      const fetchArticlesApiUrl = getApiUrl(`${companyId}/articles`);
      const response = await axios.get(fetchArticlesApiUrl, {
        withCredentials: true,
      });

      setArticles(response.data.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const data = useMemo(() => articles, [articles]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter } = state;

  const handleRowClick = (article) => {
    setSelectedArticle(article);
    setShow(true);
  };

  const handleDeleteArticle = (articleId) => {
    const deleteArticleApiUrl = getApiUrl(`${companyId}/articles/${articleId}`);
    axios
      .delete(deleteArticleApiUrl, { withCredentials: true })
      .then(() => {
        fetchArticles();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImport = () => {
    history.push(`/${companyId}/articles/import`);
  };

  return (
    <>
      <PageTitle activeMenu="Artiklar" motherMenu="Fakturor och Offerter" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        ) : articles.length === 0 ? (
          <>
            <NoData message="Det finns inga artiklar att visa. Skapa en för att kunna se dem här." />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <Link
                style={{ margin: '0 10px', alignItems: 'center' }}
                to="#"
                onClick={() => setShow(true)}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
                Skapa en ny artikel
              </Link>
              <Link
                style={{ margin: '0 10px', alignItems: 'center' }}
                to="#"
                onClick={() => handleImport()}
                className="btn btn-outline-secondary"
              >
                <FontAwesomeIcon icon={faUpload} style={{ marginRight: '5px' }} />
                Importera Artiklar
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Artiklar</h4>
                <SplitButton
                  id="dropdown-split-variants-primary"
                  title="Ny Artikel"
                  variant="primary"
                  onClick={() => setShow(true)}
                >
                  <Dropdown.Item onClick={() => handleImport()}>Importera Artiklar</Dropdown.Item>
                  <Dropdown.Item onClick={() => alert("Export not ready")}>Exportera Artiklar</Dropdown.Item>
                </SplitButton>
                {/*<Link
                  to="#"
                  onClick={() => setShow(true)}
                  className="btn btn-primary"
                >
                  Ny Artikel
                </Link>*/}
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                  <table
                    {...getTableProps()}
                    className="table dataTable table-hover display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="clickable-row"
                            onClick={() => handleRowClick(row.original)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        <NewArticleModal
          show={show}
          handleClose={handleClose}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          articleDetails={selectedArticle}
        />
      </div>
    </>
  );
};

export default ArticlesTable;
