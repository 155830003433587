import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import PDFViewer from './PdfViewer';
import dayjs from 'dayjs';
import {
  Spinner,
  Card,
  Button,
  Badge,
  Alert,
  Modal,
  Row,
} from 'react-bootstrap';

import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,       // Draft
  faPaperPlane,    // Sent
  faCheckCircle,   // Accepted
  faTimesCircle,   // Rejected
  faBan,           // Cancelled
} from '@fortawesome/free-solid-svg-icons';

const QuoteViewer = () => {
  const { companyId, quoteId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // State for quote data
  const [quoteData, setQuoteData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [daysUntilExpiry, setDaysUntilExpiry] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [nextAction, setNextAction] = useState(null);

  // Modal state
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    fetchQuote();
    fetchPdf();
  }, [companyId, quoteId]);

  useEffect(() => {
    if (quoteData) {
      fetchStatusHistory();
      calculateExpiryDays();
    }
  }, [quoteData]);

  const fetchPdf = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/quotes/${quoteId}/pdf`),
        {
          responseType: 'blob',
          withCredentials: true,
        }
      );
      const file = new Blob([response.data], { type: 'application/pdf' });
      setPdfData(URL.createObjectURL(file));
    } catch (error) {
      console.error('Error fetching PDF:', error);
      setError('Could not fetch PDF file. Please try again later.');
    }
  };

  const fetchQuote = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/quotes/${quoteId}`),
        { withCredentials: true }
      );
      setQuoteData(response.data.quote);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching quote:', error);
      setError('Could not fetch quote details. Please try again later.');
      setLoading(false);
    }
  };

  const fetchStatusHistory = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/quotes/${quoteId}/status-history`),
        { withCredentials: true }
      );

      console.log('Status history:', response.data.history);

      setStatusHistory(response.data.history);
      determineNextAction(quoteData.status);
    } catch (error) {
      console.error('Error fetching status history:', error);
      determineNextAction(quoteData.status);
    }
  };

  const calculateExpiryDays = () => {
    const today = dayjs();
    const validUntil = dayjs(quoteData.valid_until);
    setDaysUntilExpiry(validUntil.diff(today, 'day'));
  };

  const determineNextAction = (currentStatus) => {
    switch (currentStatus) {
      case 'Draft':
        setNextAction({ text: 'Send Quote', nextStatus: 'Sent' });
        break;
      case 'Sent':
        setNextAction({
          text: 'Mark as Accepted',
          nextStatus: 'Accepted',
          showReject: true
        });
        break;
      default:
        setNextAction(null);
    }
  };

  const getStatusDisplayName = (status) => ({
    Draft: 'Utkast',
    Sent: 'Skickad',
    Accepted: 'Accepterad',
    Rejected: 'Avböjd',
    Cancelled: 'Makulerad',
  }[status] || status);

  const getStatusIcon = (status) => ({
    Draft: { icon: faFileAlt, color: '#6c757d' },
    Sent: { icon: faPaperPlane, color: '#0d6efd' },
    Accepted: { icon: faCheckCircle, color: '#198754' },
    Rejected: { icon: faTimesCircle, color: '#dc3545' },
    Cancelled: { icon: faBan, color: '#6c757d' },
  }[status] || { icon: faFileAlt, color: '#6c757d' });

  const handleActionClick = (action) => {
    setShowConfirmModal({ action });
  };

  const handleStatusUpdate = async (newStatus) => {
    setActionLoading(true);
    try {
      await axios.put(
        getApiUrl(`${companyId}/quotes/${quoteId}/status`),
        { status: newStatus },
        { withCredentials: true }
      );

      setQuoteData(prev => ({ ...prev, status: newStatus }));
      setStatusHistory(prev => [
        ...prev,
        { status: newStatus, changed_at: new Date().toISOString() }
      ]);
      determineNextAction(newStatus);
      setSuccessMessage(`Quote has been ${newStatus.toLowerCase()}.`);
    } catch (error) {
      setError('Failed to update quote status. Please try again.');
    } finally {
      setActionLoading(false);
      setShowConfirmModal(false);
    }
  };
  // ... (previous code remains the same)

  return (
    <>
      <PageTitle activeMenu="Offerter" motherMenu="Offerter" />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Laddar...</span>
          </Spinner>
        </div>
      ) : (
        <div className="col-xl-12 mt-4">
          {/* Alerts */}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
              {successMessage}
            </Alert>
          )}

          {/* Header with Actions */}
          <div className='col-xl-12 mb-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center-horizontal' }}>
            <h2>Offertdetaljer</h2>
            <div className="d-flex gap-2">
              {nextAction && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => handleActionClick(nextAction.nextStatus)}
                    disabled={actionLoading}
                  >
                    {actionLoading ? 'Uppdaterar...' : nextAction.text}
                  </Button>
                  {nextAction.showReject && (
                    <Button
                      variant="danger"
                      onClick={() => handleActionClick('Rejected')}
                      disabled={actionLoading}
                    >
                      Avböj offert
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Status Timeline */}
          <div className="col-xl-12 col-xxl-12 col-lg-12 mb-4 widget-timeline">
            <ul className="timeline">
              {statusHistory && statusHistory.length > 0 ? (

                statusHistory.map((statusEvent, index) => {
                  const { icon, color } = getStatusIcon(statusEvent.status);
                  return (
                    <li key={index}>
                      <div className="timeline-badge primary" ></div>
                      <div className="timeline-panel text-muted">
                        <span>{dayjs(statusEvent.changed_at).format('YYYY-MM-DD HH:mm')}</span>
                        <h6 className="mb-0 d-flex align-items-center">
                          <FontAwesomeIcon icon={icon} color={color} size="lg" />
                          <span className="ms-2">{getStatusDisplayName(statusEvent.status)}</span>
                        </h6>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li>
                  <div className="timeline-badge"></div>
                  <div className="timeline-panel text-muted">
                    <span>Ingen statushistorik tillgänglig</span>
                  </div>
                </li>
              )
              }


            </ul>
          </div>

          {/* Quote Details Card */}
          <Card className="mt-4 mb-4">
            <Card.Body>
              <Row>
                <div className="accordion_body">
                  <div style={{ display: "flex", flexFlow: "wrap", justifyContent: "space-between" }}>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Kund</p>
                      <span className="font-w500">
                        <strong>{quoteData.customer_name}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Offertnr.</p>
                      <span className="font-w500">
                        <strong>{quoteData.friendly_id}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Offertdatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(quoteData.quote_date).format('DD MMM YYYY')}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Giltig till</p>
                      <span className="font-w500">
                        <strong>
                          {dayjs(quoteData.valid_until).format('DD MMM YYYY')}
                          {daysUntilExpiry > 0 && ` (${daysUntilExpiry} dagar kvar)`}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Status</p>
                      <span className="font-w500">
                        <Badge bg="primary">{getStatusDisplayName(quoteData.status)}</Badge>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Valuta</p>
                      <span className="font-w500">
                        <strong>{quoteData.currency}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Summa</p>
                      <span className="font-w500">
                        <strong>
                          {new Intl.NumberFormat('sv-SE', {
                            style: 'currency',
                            currency: quoteData.currency || 'SEK',
                          }).format(quoteData.totals.grand_total)}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>

          {/* PDF Viewer */}
          {pdfData && <PDFViewer fileUrl={pdfData} />}

          {/* Confirmation Modal */}
          <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bekräfta åtgärd</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {showConfirmModal?.action === 'Rejected' ? (
                'Är du säker på att du vill avböja denna offert?'
              ) : (
                `Är du säker på att du vill ${nextAction?.text.toLowerCase()}?`
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmModal(false)}
              >
                Avbryt
              </Button>
              <Button
                variant={showConfirmModal?.action === 'Rejected' ? 'danger' : 'success'}
                onClick={() => handleStatusUpdate(showConfirmModal.action)}
                disabled={actionLoading}
              >
                {actionLoading ? 'Uppdaterar...' : 'Ja, fortsätt'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default QuoteViewer;