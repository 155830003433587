import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Badge, Spinner } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import {
  Card,
  Table,
  Dropdown,
} from 'react-bootstrap';

import { getApiUrl } from '../../../../utils/ApiUtils';
import { NoData } from '../../../components/SharedComponents/NoData';
import PageTitle from '../../../layouts/PageTitle';

import swal from 'sweetalert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faExchange, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

export const InvoiceList = () => {
  const { companyId } = useParams();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  const COLUMNS = useMemo(() => [
    {
      Header: 'Typ & Id',
      accessor: 'invoicenumber',
      Cell: ({ value, row }) => {
        const { original } = row;
        return (
          <div className="d-flex align-items-center">
            {/*<FontAwesomeIcon
              icon={original.invoice_type === 'credit_note' ? faExchange : faFileInvoice}
              className={`me-2 ${original.invoice_type === 'credit_note' ? 'text-warning' : 'text-secondary'}`}
            />*/}
            <div>
              <div>{value === null ? '-' : value}</div>
              {original.invoice_type === 'credit_note' && (
                <div className="small text-muted">Kreditfaktura</div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Kund',
      accessor: 'name',
    },
    {
      Header: 'Fakturadatum',
      accessor: 'invoicedate',
    },
    {
      Header: 'Förfallodatum',
      accessor: 'deadline',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props => {
        const { original } = props.row;

        // Show credit note badge if it's a credit note
        if (original.invoice_type === 'credit_note') {
          return (
            <div>
              {props.value === 'Draft' ? (
                <Badge bg="light">{props.value}</Badge>
              ) : props.value === 'Published' ? (
                <Badge bg="success">{props.value}</Badge>
              ) : props.value === 'Paid' ? (
                <Badge bg="primary">{props.value}</Badge>
              ) : props.value === 'Overdue' ? (
                <Badge bg="danger">{props.value}</Badge>
              ) : (
                <Badge bg="secondary">{props.value}</Badge>
              )}
            </div>
          );
        }

        // Regular invoice status badges
        if (props.value === 'Draft') {
          return <Badge bg="light">
            Utkast
          </Badge>;
        } else if (props.value === 'Published') {
          return <Badge bg="success">
            Publicerad
          </Badge>;
        } else if (props.value === 'Paid') {
          return <Badge bg="primary">
            Betalad
          </Badge>;
        } else if (props.value === 'Overdue') {
          return <Badge bg="danger">
            Förfallen
          </Badge>;
        } else {
          return <Badge bg="secondary">{props.value}</Badge>;
        }
      },
    },
    {
      Header: 'Bellopp (inkl. moms)',
      accessor: 'total_with_tax',
      Cell: props => {
        const { original } = props.row;
        if (original.state === 'Draft') {
          return (
            <span> - </span>
          );
        }
        return (
          <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(props.value)}</span>);
      }
    },
    {
      Header: "",
      className: "column-right",
      Cell: ({ row }) => (
        <Dropdown className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}>
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item onClick={() => handleRowClick(row)}>
              Visa Faktura
            </Dropdown.Item>
            {row.original.status !== 'Draft' && (
              <Dropdown.Item
                onClick={() => handleCreateCreditNote(row.original.id)}
                className="text-primary"
              >
                Skapa kreditfaktura
              </Dropdown.Item>
            )}
            {row.original.status === 'Published' && (
              <>
                {/*<Dropdown.Item onClick={() => handlePayment(row.original.id)}>
                  Registrera Betalning
                </Dropdown.Item>*/}
                <Dropdown.Item
                  onClick={() => handleInvoiceAction(row.original.id, 'Published')}
                  className="text-danger"
                >
                  Annullera faktura
                </Dropdown.Item>
              </>
            )}
            {row.original.status === 'Draft' && (
              <Dropdown.Item
                onClick={() => handleInvoiceAction(row.original.id, 'Draft')}
                className="text-danger"
              >
                Radera utkast
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ], [companyId]);

  useEffect(() => {
    fetchInvoiceData();
  }, [companyId]);

  const handleRowClick = (row) => {
    if (row.original.is_published) {
      history.push(`/${companyId}/view-invoice/${row.original.id}`);
    } else if (row.original.invoice_type === 'invoice') {
      history.push(`/${companyId}/edit-invoice/${row.original.id}`);
    } else if (row.original.invoice_type === 'credit_note') {
      history.push(`/${companyId}/edit-credit-note/${row.original.id}`);
    }
  };

  const handleDelete = (id) => {
    const deleteInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${id}`);
    axios.delete(deleteInvoiceApiUrl, { withCredentials: true })
      .then(res => {
        fetchInvoiceData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Replace handleDelete with handleCancel
  const handleCancel = async (id) => {
    // Show confirmation dialog
    const willCancel = await swal({
      title: "Är du säker?",
      text: "När du annullerar en faktura kan detta inte ångras.",
      icon: "warning",
      buttons: ["Avbryt", "Ja, annullera faktura"],
      dangerMode: true,
    });

    if (willCancel) {
      try {
        const cancelInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${id}/cancel`);
        await axios.post(cancelInvoiceApiUrl, {}, { withCredentials: true });

        // Show success message
        swal("Klart", "Fakturan har annullerats", "success");

        // Refresh the list
        fetchInvoiceData();
      } catch (err) {
        // Show error message based on the error response
        const errorMessage = err.response?.data?.message || 'Ett fel uppstod vid annullering av fakturan';
        swal("Fel", errorMessage, "error");
      }
    }
  };

  // In your frontend InvoiceList component
  const handleInvoiceAction = async (id, status) => {
    // Different handling based on invoice status
    if (status === 'Draft') {
      // For drafts - complete deletion with confirmation
      const willDelete = await swal({
        title: "Är du säker?",
        text: "När du raderar ett utkast kan detta inte ångras.",
        icon: "warning",
        buttons: ["Avbryt", "Ja, radera utkast"],
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const deleteInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${id}`);
          await axios.delete(deleteInvoiceApiUrl, { withCredentials: true });
          swal("Klart", "Utkastet har raderats", "success");
          fetchInvoiceData();
        } catch (err) {
          swal("Fel", "Kunde inte radera utkastet", "error");
        }
      }
    } else if (status === 'Published') {
      // For published invoices - cancellation with reason
      const willCancel = await swal({
        title: "Är du säker?",
        text: "När du annullerar en faktura kan detta inte ångras.",
        icon: "warning",
        buttons: ["Avbryt", "Ja, annullera faktura"],
        dangerMode: true,
      });

      if (willCancel) {
        try {
          const cancelInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${id}/cancel`);
          await axios.post(cancelInvoiceApiUrl, {}, { withCredentials: true });
          swal("Klart", "Fakturan har annullerats", "success");
          fetchInvoiceData();
        } catch (err) {
          const errorMessage = err.response?.data?.message || 'Ett fel uppstod vid annullering av fakturan';
          swal("Fel", errorMessage, "error");
        }
      }
    }
  };

  // Add new handler for credit note creation
  const handleCreateCreditNote = (invoiceId) => {
    history.push(`/${companyId}/create-credit-note/${invoiceId}`);
  };

  const handlePayment = (invoiceId) => {
    alert('hantera bertalning?');
  };

  /*const fetchInvoiceData = async () => {
    setLoading(true);
    try {
      const getInvoicesApiUrl = getApiUrl(`${companyId}/invoices`);
      const result = await axios.get(getInvoicesApiUrl, { withCredentials: true });
      setInvoices(result.data);
    } catch (err) {
      setError('Could not fetch invoice data.');
    } finally {
      setLoading(false);
    }
  };*/

  const fetchInvoiceData = async () => {
    console.log("Starting to fetch invoice data");
    setLoading(true);
    try {
      // Get all invoices
      const getInvoicesApiUrl = getApiUrl(`${companyId}/invoices`);
      const invoicesResult = await axios.get(getInvoicesApiUrl, { withCredentials: true });

      console.log("Got initial invoice data:", invoicesResult.data);

      // Get all status histories
      const promises = invoicesResult.data.map(invoice =>
        axios.get(getApiUrl(`${companyId}/invoices/${invoice.id}/status-history`), { withCredentials: true })
      );
      const statusHistories = await Promise.all(promises);

      // Process each invoice to include its latest status
      const processedInvoices = invoicesResult.data.map((invoice, index) => {
        const invoiceStatusHistory = statusHistories[index].data;
        let latestStatus = invoice.status; // default to invoice status

        if (invoiceStatusHistory && invoiceStatusHistory.length > 0) {
          // Sort status history by date and get the latest
          const sortedHistory = [...invoiceStatusHistory].sort((a, b) =>
            new Date(b.changed_at) - new Date(a.changed_at)
          );
          latestStatus = sortedHistory[0].status;
        }

        return {
          ...invoice,
          status: latestStatus
        };
      });

      console.log("Setting processed invoices:", processedInvoices);

      setInvoices(processedInvoices);
    } catch (err) {
      setError('Could not fetch invoice data.');
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const invoiceListData = useMemo(() => invoices, [invoices]);

  const tableInstance = useTable({
    columns,
    data: invoiceListData,
    initialState: { hiddenColumns: ['id'] }
  }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <PageTitle activeMenu="Kundfakturor" motherMenu="Fakturor" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : invoices.length === 0 ? (
          <>
            <NoData message="Det finns inga fakturor att visa. Skapa en för att kunna se dem här." />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              padding: '10px'
            }}>
              <Link
                style={{ marginTop: "20px", alignItem: "center" }}
                to={`#`}
                onClick={() => history.push(`/${companyId}/create-invoices`)}
                className="btn btn-primary"
              >
                Skapa en faktura
              </Link>
            </div>
          </>
        ) : (
          <>
            <Card>
              <Card.Header>
                <h4 className="card-title">Kundfakturor</h4>
                <Link
                  to={`#`}
                  onClick={() => history.push(`/${companyId}/create-invoices`)}
                  className="btn btn-primary"
                >
                  Ny Faktura
                </Link>
              </Card.Header>
              <Card.Body>
                <div >
                  <Table {...getTableProps()} className="table table-responsive dataTable table-hover display">
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render('Header')}
                              <span className="ml-1">
                                {column.isSorted ? (
                                  column.isSortedDesc ?
                                    <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                    :
                                    <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                )
                                  :
                                  (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                }
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr className='clickable-row' onClick={() => handleRowClick(row)} {...row.getRowProps()}>
                            {row.cells.map(cell => (
                              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </div >
    </>
  );
};

export default InvoiceList;
