import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import QuoteItemsTable from './QuoteItemsTable';
import { isEqual } from 'lodash';

export function calculateQuoteTotals(items, disableRounding = false) {
    let subtotal = 0;
    let taxBreakdown = {};
    let grandTotal = 0;

    items.forEach((item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const unitprice = parseFloat(item.unitprice) || 0;
        const amount = quantity * unitprice;
        subtotal += amount;

        // Extract numeric value from tax rate string (e.g., "25%" -> 25)
        const taxRateValue = parseFloat(item.tax_rate) || 0;
        const taxAmount = (amount * taxRateValue) / 100;

        if (!taxBreakdown[taxRateValue]) {
            taxBreakdown[taxRateValue] = {
                taxRate: taxRateValue,
                totalAmount: 0,
                totalTax: 0,
            };
        }
        taxBreakdown[taxRateValue].totalAmount += amount;
        taxBreakdown[taxRateValue].totalTax += taxAmount;
    });

    const totalTaxes = Object.values(taxBreakdown).reduce(
        (acc, curr) => acc + curr.totalTax,
        0
    );
    grandTotal = subtotal + totalTaxes;

    let roundedTotal = grandTotal;
    let rounding = 0;

    if (!disableRounding) {
        roundedTotal = Math.round(grandTotal);
        rounding = roundedTotal - grandTotal;
    }

    return {
        subtotal: parseFloat(subtotal.toFixed(2)),
        taxBreakdown,
        totalTaxes: parseFloat(totalTaxes.toFixed(2)),
        grandTotal: parseFloat(grandTotal.toFixed(2)),
        roundedTotal: parseFloat(roundedTotal.toFixed(2)),
        rounding: parseFloat(rounding.toFixed(2)),
    };
}

const ProductsAndArticlesCard = ({
    quoteData,
    items,
    articles,
    handleAddQuoteItem,
    handleDeleteQuoteItem,
    handleUpdateQuoteItem,
    handleCreateArticle,
    handleRoundingChange,
    openCreateNewArticleModal,
    currency = 'SEK'
}) => {
    const [totals, setTotals] = useState({
        subtotal: 0,
        taxBreakdown: {},
        totalTaxes: 0,
        grandTotal: 0,
        roundedTotal: 0,
        rounding: 0,
    });

    useEffect(() => {
        const calculatedTotals = calculateQuoteTotals(
            items,
            quoteData.disable_rounding
        );
        if (!isEqual(calculatedTotals, totals)) {
            setTotals(calculatedTotals);
        }
    }, [items, quoteData.disable_rounding]);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('sv-SE', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="fs-24 font-w800 mb-0">Produkter/Artiklar</h4>
                </div>

                <div className="col-xxl-12">
                    <QuoteItemsTable
                        items={items}
                        articles={articles}
                        handleAddItem={handleAddQuoteItem}
                        handleCreateArticle={handleCreateArticle}
                        handleDeleteItem={handleDeleteQuoteItem}
                        updateRow={handleUpdateQuoteItem}
                        openCreateNewArticleModal={openCreateNewArticleModal}
                    />
                </div>

                <div className="quote-summary mt-4">
                    <div className="row justify-content-end">
                        <div className="col-md-6">
                            <div className="summary-card">
                                <div className="summary-row">
                                    <span className="label">Delsumma (exkl. moms):</span>
                                    <span className="value">{formatCurrency(totals.subtotal)}</span>
                                </div>

                                {Object.entries(totals.taxBreakdown).map(([rate, data]) => (
                                    <div className="summary-row" key={rate}>
                                        <span className="label">Moms ({rate}%):</span>
                                        <span className="value">{formatCurrency(data.totalTax)}</span>
                                    </div>
                                ))}

                                {!quoteData.disable_rounding && Math.abs(totals.rounding) > 0.001 && (
                                    <div className="summary-row">
                                        <span className="label">Avrundning:</span>
                                        <span className="value">{formatCurrency(totals.rounding)}</span>
                                    </div>
                                )}

                                <div className="summary-row total">
                                    <span className="label">Totalt (inkl. moms):</span>
                                    <span className="value">
                                        {formatCurrency(quoteData.disable_rounding ? totals.grandTotal : totals.roundedTotal)}
                                    </span>
                                </div>

                                <div className="summary-row mt-3">
                                    <Form.Check
                                        type="switch"
                                        id="rounding-switch"
                                        label="Avrundning"
                                        checked={!quoteData.disable_rounding}
                                        onChange={(e) => handleRoundingChange(e.target.checked)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>

            <style jsx>{`
        .quote-summary {
          margin-top: 2rem;
          padding-top: 1rem;
          border-top: 1px solid #e5e5e5;
        }
        .summary-card {
          background: #f8f9fa;
          padding: 1.5rem;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .summary-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.75rem;
          padding: 0.25rem 0;
        }
        .summary-row.total {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 2px solid #dee2e6;
          font-weight: bold;
          font-size: 1.1em;
        }
        .label {
          color: #495057;
        }
        .value {
          font-family: monospace;
          font-size: 1.1em;
        }
      `}</style>
        </Card>
    );
};

export default ProductsAndArticlesCard;