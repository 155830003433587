import React, { useState } from 'react';
import { Card, Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartPie,
    faCalendarAlt,
    faArrowUp,
    faArrowDown,
    faExclamationTriangle,
    faChartLine,
    faCheck,
    faFileInvoiceDollar,
    faPlus,
    faSync
} from '@fortawesome/free-solid-svg-icons';
import {
    PieChart,
    Pie,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell
} from 'recharts';

import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const formatAmount = (amount, currency = 'SEK') => {
    return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(amount);
};

const InvoiceTotalsWidget = ({ data, type = 'customer', isLoading, onCreateInvoice }) => {

    const companyId = localStorage.getItem('companyId');
    const history = useHistory();

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    // Handle error state
    if (data?.error) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100 p-4 text-center">
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-danger mb-3"
                    style={{ fontSize: '3rem' }}
                />
                <h4>Unable to Load Data</h4>
                <p className="text-muted mb-3">{data.error}</p>
                <Button
                    variant="outline-primary"
                    onClick={() => window.location.reload()}
                >
                    <FontAwesomeIcon icon={faSync} className="me-2" />
                    Retry
                </Button>
            </div>
        );
    }

    const totals = data?.totals || {};
    const hasData = totals.total_invoices > 0;

    // Handle no data state
    if (!hasData) {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h3 className="h5 mb-0">Financial Overview</h3>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center h-100 p-4 text-center">
                    <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className="text-muted mb-3"
                        style={{ fontSize: '3rem' }}
                    />
                    <h4>No Invoice Data Available</h4>
                    <p className="text-muted mb-4">
                        {type === 'customer'
                            ? "You haven't created any invoices yet. Start by creating your first invoice."
                            : "No supplier invoices found. Add your first supplier invoice to get started."}
                    </p>
                    <Link
                        style={{ marginTop: "20px", alignItem: "center" }}
                        to={`#`}
                        onClick={() => history.push(`/${companyId}/create-invoices`)}
                        className="btn btn-primary"
                    >
                        Skapa en faktura
                    </Link>
                    {/*<Button
                        variant="primary"
                        onClick={onCreateInvoice}
                        className='cancelSelectorName'
                    >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        {type === 'customer' ? 'Create Invoice' : 'Add Supplier Invoice'}
                    </Button>*/}
                </div>
            </>
        );
    }

    // Parse numeric values
    const totalInvoiced = Number(totals.total_invoiced || 0);
    const totalCredited = Number(totals.total_credited || 0);
    const totalPaid = Number(totals.total_paid || 0);
    const netAmount = totalInvoiced + totalCredited; // Since total_credited is already negative
    const remainingAmount = netAmount - totalPaid;

    const pieData = [
        { name: 'Paid', value: totalPaid, color: '#0ea5e9' },
        { name: 'Remaining', value: Math.max(remainingAmount, 0), color: '#f59e0b' }
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h3 className="h5 mb-1">Financial Overview</h3>
                    <div className="text-muted small">{totals.total_invoices} invoices total</div>
                </div>
                <Dropdown>
                    <Dropdown.Toggle variant="white" size="sm" className="border shadow-sm">
                        <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                        This Month
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>This Week</Dropdown.Item>
                        <Dropdown.Item>This Month</Dropdown.Item>
                        <Dropdown.Item>This Quarter</Dropdown.Item>
                        <Dropdown.Item>This Year</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {/* Main financial stats */}
            <div className="row g-4 mb-4">
                <div className="col-6">
                    <div className="d-flex flex-column p-3 h-100 shadow-sm rounded border">
                        <div className="text-muted mb-2">Net Amount</div>
                        <div className="h3 mb-2">{formatAmount(netAmount, totals.currency)}</div>
                        <div className="mt-auto d-flex align-items-center small">
                            <div className="flex-grow-1">
                                <div className="text-muted">Total Invoiced</div>
                                <div>{formatAmount(totalInvoiced, totals.currency)}</div>
                            </div>
                            {totalCredited !== 0 && (
                                <div className="text-danger ms-3">
                                    <div className="text-muted">Credits</div>
                                    <div>{formatAmount(totalCredited, totals.currency)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex flex-column p-3 h-100 shadow-sm rounded border">
                        <div className="text-muted mb-2">Payment Status</div>
                        <div className="h3 mb-2">{formatAmount(totalPaid, totals.currency)}</div>
                        <div className="mt-auto">
                            <div className="d-flex justify-content-between text-muted small mb-1">
                                <span>Payment Progress</span>
                                <span>{Math.round((totalPaid / netAmount) * 100)}%</span>
                            </div>
                            <div className="progress" style={{ height: "6px" }}>
                                <div
                                    className="progress-bar bg-success"
                                    style={{ width: `${(totalPaid / netAmount) * 100}%` }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Invoice stats */}
            <div className="row g-4 mb-4">
                <div className="col-4">
                    <div className="d-flex align-items-center p-3 shadow-sm rounded border">
                        <div className="rounded-circle bg-primary bg-opacity-10 p-2 me-3">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-primary" />
                        </div>
                        <div>
                            <div className="text-muted small">Total</div>
                            <div className="h5 mb-0">{totals.total_invoices}</div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="d-flex align-items-center p-3 shadow-sm rounded border">
                        <div className="rounded-circle bg-success bg-opacity-10 p-2 me-3">
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </div>
                        <div>
                            <div className="text-muted small">Paid</div>
                            <div className="h5 mb-0">{totals.paid_invoices}</div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="d-flex align-items-center p-3 shadow-sm rounded border">
                        <div className="rounded-circle bg-warning bg-opacity-10 p-2 me-3">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
                        </div>
                        <div>
                            <div className="text-muted small">Overdue</div>
                            <div className="h5 mb-0">{totals.overdue_invoices}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Payment distribution chart */}
            <div className="border rounded shadow-sm p-3" style={{ height: '240px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={pieData}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            paddingAngle={2}
                            dataKey="value"
                        >
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value) => formatAmount(value, totals.currency)}
                            contentStyle={{
                                background: 'white',
                                border: 'none',
                                borderRadius: '8px',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                            }}
                        />
                        <Legend
                            verticalAlign="middle"
                            align="right"
                            layout="vertical"
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

const InvoiceAgingWidget = ({ data, type = 'customer', isLoading }) => {
    console.log('InvoiceAgingWidget', data);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="text-center text-muted">Loading...</div>
            </div>
        );
    }

    const aging = data?.aging || [];

    const totalOutstanding = aging.reduce((sum, bucket) => sum + bucket.outstanding_amount, 0);

    // Function to determine progress bar variant based on aging bucket
    const getVariant = (bucket) => {
        switch (bucket) {
            case 'Not Due': return 'success';
            case '1-30 days': return 'info';
            case '31-60 days': return 'warning';
            case '61-90 days': return 'danger';
            case 'Over 90 days': return 'danger';
            default: return 'primary';
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="h5 mb-0">Aging Analysis</h3>
                <div className="text-muted small">
                    Total Outstanding: {formatAmount(totalOutstanding)}
                </div>
            </div>

            {aging.map((bucket) => {
                const percentage = (bucket.outstanding_amount / totalOutstanding) * 100;
                const variant = getVariant(bucket.aging_bucket);

                return (
                    <div key={bucket.aging_bucket} className="mb-3">
                        <div className="d-flex justify-content-between mb-1">
                            <span className="small">{bucket.aging_bucket}</span>
                            <span className="small">{formatAmount(bucket.outstanding_amount)}</span>
                        </div>
                        <div className="progress" style={{ height: '8px' }}>
                            <div
                                className={`progress-bar bg-${variant}`}
                                role="progressbar"
                                style={{ width: `${percentage}%` }}
                                aria-valuenow={percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                            <span className="small text-muted">{bucket.invoice_count} invoices</span>
                            <span className={`small text-${variant}`}>{percentage.toFixed(1)}%</span>
                        </div>
                    </div>
                );
            })}

            {aging.some(bucket => bucket.aging_bucket === 'Over 90 days' && bucket.outstanding_amount > 0) && (
                <div className="alert alert-warning mt-3 d-flex align-items-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    <div>
                        <strong>High Risk Outstanding</strong>
                        <div className="small">
                            You have {aging.find(b => b.aging_bucket === 'Over 90 days')?.invoice_count} invoices
                            overdue by more than 90 days.
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const TrendWidget = ({ data, type = 'customer', isLoading }) => {
    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="text-center text-muted">Loading...</div>
            </div>
        );
    }

    const trends = data?.trends || [];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="h5 mb-0">
                    <FontAwesomeIcon icon={faChartLine} className="text-primary me-2" />
                    {type === 'customer' ? 'Invoice Trends' : 'Payment Trends'}
                </h3>
                <Dropdown>
                    <Dropdown.Toggle variant="light" size="sm">
                        <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                        This Month
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>This Week</Dropdown.Item>
                        <Dropdown.Item>This Month</Dropdown.Item>
                        <Dropdown.Item>This Quarter</Dropdown.Item>
                        <Dropdown.Item>This Year</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div style={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={trends}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(value) => new Date(value).toLocaleDateString('sv-SE', {
                                month: 'short',
                                day: 'numeric'
                            })}
                        />
                        <YAxis tickFormatter={(value) => formatAmount(value)} />
                        <Tooltip
                            formatter={(value) => formatAmount(value)}
                            labelFormatter={(value) => new Date(value).toLocaleDateString('sv-SE', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}
                        />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="total_amount"
                            name="Total Amount"
                            stroke="#0d6efd"
                            strokeWidth={2}
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="paid_amount"
                            name="Paid Amount"
                            stroke="#198754"
                            strokeWidth={2}
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

export { InvoiceTotalsWidget, InvoiceAgingWidget, TrendWidget };