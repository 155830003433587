import swal from "sweetalert";
import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { getApiUrl } from "../../utils/ApiUtils";
import { Spinner } from 'react-bootstrap'; // Import Spinner component

function NewCompany(props) {
  const history = useHistory();
  const uid = props.googleId;

  const [inputValue, setInputValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [manualEntry, setManualEntry] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    orgNumber: '',
    street: '',
    postalCode: '',
    city: '',
    accountingMethod: '',
    vatReportingPeriod: '',
  });
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

  const onCreateCompany = async (e) => {
    e.preventDefault();

    if (!companyDetails.companyName || !companyDetails.orgNumber || !companyDetails.accountingMethod || !companyDetails.vatReportingPeriod) {
      swal('Oops', 'Du måste fylla i alla obligatoriska fält.', "error");
      return;
    }

    setIsSubmitting(true); // Disable buttons and show spinner

    try {
      const createNewCompanyApiUrl = getApiUrl('auth/company');
      const response = await axios.post(createNewCompanyApiUrl, {
        companyName: companyDetails.companyName,
        googleId: uid,
        orgNumber: companyDetails.orgNumber,
        legalEntityType: manualEntry ? 'Aktiebolag' : selectedCompany ? selectedCompany.document.legalEntityType : 'Aktiebolag',
        street: companyDetails.street,
        postalCode: companyDetails.postalCode,
        city: companyDetails.city,
        accountingMethod: companyDetails.accountingMethod,
        vatReportingPeriod: companyDetails.vatReportingPeriod,
      }, { withCredentials: true });

      console.log('Company added successfully:', response.data);

      history.push('/select-company');

    } catch (error) {
      swal('Oops', 'Något gick fel. Försök igen senare.', "error");
      console.error('Error adding company:', error);
    } finally {
      setIsSubmitting(false); // Re-enable buttons after submission
    }
  };

  const searchCompany = async (inputValue) => {
    setSelectedCompany(null);
    setCompanyDetails({
      companyName: '',
      orgNumber: '',
      street: '',
      postalCode: '',
      city: '',
      accountingMethod: '',
      vatReportingPeriod: '',
    });
    setSearchResults([]);
    setLoading(true);

    const cleanedInput = inputValue.replace(/\s+/g, '');
    const isNumber = /^\d+$/.test(cleanedInput);

    const apiUrl = isNumber
      ? `https://api.tic.io/search/companies?q=${encodeURIComponent(cleanedInput)}&query_by=registrationNumber`
      : `https://api.tic.io/search/companies?query_by=names.nameOrIdentifier&q=${encodeURIComponent(inputValue)}`;

    const options = {
      method: 'GET',
      headers: { 'x-api-key': 'k7ITvPSmQvIasmvuNxrRFLZ29xhpbIzB' },
      url: apiUrl,
    };

    try {
      const response = await axios(options);
      setSearchResults(response.data.hits);
    } catch (err) {
      swal('Oops', 'Något gick fel. Försök igen senare.', "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    setSearchResults([]);

    console.log('Selected company:', company);

    setCompanyDetails({
      companyName: company.document.names[0].nameOrIdentifier,
      orgNumber: company.document.registrationNumber,
      street: company.document.mostRecentRegisteredAddress.streetAddress,
      postalCode: company.document.mostRecentRegisteredAddress.postalCode,
      city: company.document.mostRecentRegisteredAddress.city,
      accountingMethod: '',
      vatReportingPeriod: '',
    });
  };

  const toggleManualEntry = () => {
    setManualEntry(!manualEntry);
    setSelectedCompany(null);
    setCompanyDetails({
      companyName: '',
      orgNumber: '',
      street: '',
      postalCode: '',
      city: '',
      accountingMethod: '',
      vatReportingPeriod: '',
    });
  };

  const handleInputChange = (e) => {
    setCompanyDetails({
      ...companyDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-12">
            <div className="card mt-4">
              <div className="card-header">
                <h4 className="card-title">Registrera nytt företag</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  {!manualEntry && !selectedCompany && (
                    <>
                      <div className="row">
                        <div className="form-group mb-3 col-md-10">
                          <label>Företagsnamn eller Organisationsnummer</label>
                          <input
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Ange företagsnamn eller organisationsnummer"
                            disabled={isSubmitting} // Disable input during submission
                          />
                        </div>
                        <div className="form-group mb-3 col-md-2 d-flex align-items-end">
                          <button
                            onClick={() => searchCompany(inputValue)}
                            type="button"
                            className="btn btn-primary"
                            disabled={loading || isSubmitting}
                          >
                            {loading ? 'Söker...' : 'Sök Företag'}
                          </button>
                        </div>
                      </div>

                      {searchResults.length > 0 && (
                        <div className="form-group mb-3">
                          <label>Välj ett företag</label>
                          <ul className="list-group">
                            {searchResults.map((company, idx) => (
                              <li
                                key={idx}
                                className={`list-group-item ${selectedCompany === company ? 'active' : ''}`}
                                onClick={() => handleCompanySelect(company)}
                                style={{ cursor: 'pointer' }}
                              >
                                {company.document.names[0].nameOrIdentifier} ({company.document.registrationNumber})
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  )}

                  {(manualEntry || selectedCompany) && (
                    <>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>Företagsnamn</label>
                          <input
                            type="text"
                            className="form-control"
                            name="companyName"
                            value={companyDetails.companyName}
                            onChange={handleInputChange}
                            placeholder="Ange företagsnamn"
                            disabled={isSubmitting}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Organisationsnummer</label>
                          <input
                            type="text"
                            className="form-control"
                            name="orgNumber"
                            value={companyDetails.orgNumber}
                            onChange={handleInputChange}
                            placeholder="Ange organisationsnummer"
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>Adress</label>
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            value={companyDetails.street}
                            onChange={handleInputChange}
                            placeholder="Ange gatuadress"
                            disabled={isSubmitting}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Postnummer</label>
                          <input
                            type="text"
                            className="form-control"
                            name="postalCode"
                            value={companyDetails.postalCode}
                            onChange={handleInputChange}
                            placeholder="Ange postnummer"
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>Stad</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            value={companyDetails.city}
                            onChange={handleInputChange}
                            placeholder="Ange stad"
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                      {/* New Fields for Accounting Method and VAT Reporting Period */}
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>Bokföringsmetod</label>
                          <select
                            className="form-control"
                            name="accountingMethod"
                            value={companyDetails.accountingMethod}
                            onChange={handleInputChange}
                            disabled={isSubmitting}
                          >
                            <option value="">Välj bokföringsmetod</option>
                            <option value="cash">Kontantmetoden</option>
                            <option value="accrual">Fakturametoden</option>
                          </select>
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Momsredovisningsperiod</label>
                          <select
                            className="form-control"
                            name="vatReportingPeriod"
                            value={companyDetails.vatReportingPeriod}
                            onChange={handleInputChange}
                            disabled={isSubmitting}
                          >
                            <option value="">Välj momsredovisningsperiod</option>
                            <option value="monthly">Månadsvis</option>
                            <option value="quarterly">Kvartalsvis</option>
                            <option value="annually">Årsvis</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}

                  {!manualEntry && (
                    <button
                      type="button"
                      onClick={toggleManualEntry}
                      className="btn btn-link mt-1"
                      disabled={isSubmitting}
                    >
                      {manualEntry ? 'Sök Företag Istället' : 'Ange Uppgifter Manuellt'}
                    </button>
                  )}

                  <div
                    className="form-group mb-3 center"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <button
                      type="button"
                      onClick={onCreateCompany}
                      className="btn btn-primary btn-block mt-4"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner animation="border" size="sm" className="me-2" />
                          Bearbetar...
                        </>
                      ) : (
                        'Fortsätt'
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => history.goBack()}
                      disabled={isSubmitting}
                    >
                      Tillbaka
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCompany;
