// PdfThumbnail.js
import React, { useState, useEffect } from 'react';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from "../../../../utils/ApiUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faSpinner } from '@fortawesome/free-solid-svg-icons';

const PdfThumbnail = ({ companyId, invoiceId, style }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let objectUrl;

    const fetchPdfThumbnail = async () => {
      try {
        const getInvoicePdfApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/pdf`);
        const response = await axios.get(getInvoicePdfApiUrl, {
          responseType: 'blob',
          withCredentials: true,
        });

        objectUrl = URL.createObjectURL(response.data);
        setThumbnailUrl(objectUrl);
      } catch (error) {
        console.error('Error fetching the PDF thumbnail:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfThumbnail();

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [companyId, invoiceId]);

  if (loading) {
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f8f9fa'
        }}
      >
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f8f9fa'
        }}
      >
        <FontAwesomeIcon icon={faFileInvoiceDollar} />
      </div>
    );
  }

  return (
    <object
      data={thumbnailUrl}
      type="application/pdf"
      style={{
        ...style,
        pointerEvents: 'none' // Prevent PDF interactions
      }}
    >
      <FontAwesomeIcon icon={faFileInvoiceDollar} />
    </object>
  );
};

export default PdfThumbnail;