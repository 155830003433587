import React, { useState, useEffect } from 'react';
import { Table, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { NoData } from '../../SharedComponents/NoData';
import { Link, useHistory } from 'react-router-dom';

const MAIN_SECTIONS = [
    'Rörelseintäkter, lagerförändringar m.m.',
    'Rörelseintäkter, lagerförändringar m.m.',
    'Rörelsekostnader',
    'Rörelsekostnader',
    'Finansiella poster',
    'Bokslutsdispositioner',
    'Skatter'
];

const SECTION_TITLES = {
    'Rörelseintäkter, lagerförändringar m.m.': 'Rörelsens intäkter',
    'Rörelseintäkter, lagerförändringar m.m.': 'Rörelsens intäkter',
    'Rörelsekostnader': 'Rörelsens kostnader',
    'Rörelsekostnader': 'Rörelsens kostnader',
    'Finansiella poster': 'Finansiella poster',
    'Bokslutsdispositioner': 'Bokslutsdispositioner',
    'Skatter': 'Skatter'
};

const ResultReport = () => {
    const [fiscalYears, setFiscalYears] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ledgerData, setLedgerData] = useState({});
    const [expandedSections, setExpandedSections] = useState({
        'Rörelseintäkter, lagerförändringar m.m.': true,
        'Nettoomsättning': true,
        'Rörelsekostnader': true,
        'Handelsvaror': true,
        'Övriga externa kostnader': true,
        'Personalkostnader': true,
        'Finansiella poster': true,
        'Övriga ränteintäkter och liknande resultatposter': true,
        'Bokslutsdispositioner': true,
        'Skatter': true
    });


    const { companyId } = useParams();
    const history = useHistory();

    useEffect(() => {
        getFiscalYears();
    }, [companyId]);

    useEffect(() => {
        if (selectedFiscalYear) {
            getResultReport();
        }
    }, [selectedFiscalYear]);

    const getFiscalYears = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
            setFiscalYears(result.data.fiscal_years);
            if (result.data.fiscal_years.length > 0) {
                const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
                setSelectedFiscalYear(latestFiscalYear);
            }
        } catch (err) {
            setError('Failed to fetch fiscal years');
            setFiscalYears([]);
        } finally {
            setLoading(false);
        }
    };

    const getResultReport = async () => {
        if (!selectedFiscalYear) return;

        const queryParams = {
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
        };

        try {
            const response = await axios.get(getApiUrl(`${companyId}/reporting/result`), {
                params: queryParams,
                withCredentials: true
            });

            setLedgerData(response.data);

            console.log('Full ledger data:', response.data);

            setLoading(false);
        } catch (err) {
            setError('Failed to fetch result report');
        }
    };

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption.value);
        setLoading(true);
    };

    const toggleSection = (sectionPath) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionPath]: !prev[sectionPath]
        }));
    };

    const formatAmount = (amount) => {
        if (!amount) return '0,00';
        return new Intl.NumberFormat('sv-SE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(parseFloat(amount));
    };

    const renderAccountRow = (account, depth) => {
        if (!account) return null;
        return (
            <tr key={account.account_id} className="hover-row">
                <td style={{ paddingLeft: `${(depth + 1) * 1.5}rem` }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <span style={{ color: '#666', minWidth: '4rem' }}>{account.account_number}</span>
                        <span>{account.account_name}</span>
                    </div>
                </td>
                <td style={{ textAlign: 'right' }}>{formatAmount(account.change)}</td>
            </tr>
        );
    };

    const renderSection = (sectionData, sectionName, path = '', depth = 0) => {

        console.log('Rendering section:', sectionName);
        console.log('Section data:', sectionData);
        console.log('Current path:', path);
        console.log('Depth:', depth);

        if (!sectionData || typeof sectionData !== 'object') return null;

        const currentPath = path ? `${path}.${sectionName}` : sectionName;
        const hasSubsections = Object.keys(sectionData).some(key =>
            typeof sectionData[key] === 'object' &&
            !['accounts', 'regex', 'total_opening_balance', 'total_change', 'total_closing_balance', 'hasActivity'].includes(key)
        );

        console.log('Has subsections:', hasSubsections);
        console.log('Subsections:', Object.keys(sectionData).filter(key =>
            typeof sectionData[key] === 'object' &&
            !['accounts', 'regex', 'total_opening_balance', 'total_change', 'total_closing_balance', 'hasActivity'].includes(key)
        ));

        // Initialize this section as expanded if not already set
        if (expandedSections[currentPath] === undefined) {
            setExpandedSections(prev => ({
                ...prev,
                [currentPath]: true
            }));
        }

        const isExpanded = expandedSections[currentPath] !== false;

        const showTotal = sectionData.total_change !== undefined;

        return (
            <React.Fragment key={currentPath}>
                {/* Section Header */}
                <tr className={hasSubsections ? 'cursor-pointer hover-row' : ''}>
                    <td
                        style={{
                            paddingLeft: `${depth * 1.5}rem`,
                            backgroundColor: depth === 0 ? '#f8f9fa' : depth === 1 ? '#ffffff' : 'transparent',
                            fontWeight: 'bold'
                        }}
                        onClick={() => hasSubsections && toggleSection(currentPath)}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            {hasSubsections && (
                                <span style={{ color: '#666', width: '20px' }}>
                                    <FontAwesomeIcon
                                        icon={isExpanded ? faChevronDown : faChevronRight}
                                        size="sm"
                                    />
                                </span>
                            )}
                            {depth === 0 ? SECTION_TITLES[sectionName] || sectionName : sectionName}
                        </div>
                    </td>
                    {showTotal && (
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {formatAmount(sectionData.total_change)}
                        </td>
                    )}
                    {!showTotal && <td></td>}
                </tr>

                {isExpanded && (
                    <>
                        {/* Render subsections first */}
                        {Object.entries(sectionData)
                            .filter(([key, value]) =>
                                typeof value === 'object' &&
                                !['accounts', 'regex', 'total_opening_balance', 'total_change', 'total_closing_balance', 'hasActivity'].includes(key)
                            )
                            .map(([key, value]) => renderSection(value, key, currentPath, depth + 1))}

                        {/* Then render accounts if any */}
                        {sectionData.accounts?.map(account => renderAccountRow(account, depth))}
                    </>
                )}
            </React.Fragment>
        );
    };

    const calculateResult = () => {
        return MAIN_SECTIONS.reduce((sum, section) => {
            return sum + parseFloat(ledgerData[section]?.total_change || 0);
        }, 0);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = ["Beskrivning", "Belopp"];

        // Helper function to convert section to rows for PDF
        const getSectionRows = (section, title, depth = 0) => {
            if (!section || typeof section !== 'object') return [];
            let rows = [];

            // Add section title
            rows.push([{
                content: title,
                colSpan: 2,
                styles: {
                    halign: 'left',
                    fontStyle: 'bold',
                    cellPadding: { left: depth * 5 }
                }
            }]);

            // Add accounts if present
            if (section.accounts) {
                section.accounts.forEach((account) => {
                    rows.push([
                        {
                            content: `${account.account_number} - ${account.account_name}`,
                            styles: { cellPadding: { left: (depth + 1) * 5 } }
                        },
                        formatAmount(account.change)
                    ]);
                });
            }

            // Process subsections
            Object.entries(section).forEach(([key, value]) => {
                if (typeof value === 'object' && !['accounts', 'regex', 'total_opening_balance', 'total_change', 'total_closing_balance'].includes(key)) {
                    rows = rows.concat(getSectionRows(value, key, depth + 1));
                }
            });

            // Add section total if present
            if (section.total_change) {
                rows.push([
                    {
                        content: `Summa ${title.toLowerCase()}`,
                        styles: {
                            fontStyle: 'bold',
                            cellPadding: { left: depth * 5 }
                        }
                    },
                    {
                        content: formatAmount(section.total_change),
                        styles: { fontStyle: 'bold', halign: 'right' }
                    }
                ]);
            }

            return rows;
        };

        // Add header information
        doc.setFontSize(14);
        doc.text("Resultaträkning", 14, 10);
        doc.setFontSize(11);
        if (selectedFiscalYear) {
            doc.text(`Period: ${new Date(selectedFiscalYear.start_date).toLocaleDateString('sv-SE')} - ${new Date(selectedFiscalYear.end_date).toLocaleDateString('sv-SE')}`, 14, 20);
        }

        let startY = 30;

        // Add each main section
        MAIN_SECTIONS.forEach(section => {
            if (ledgerData[section]) {
                const rows = getSectionRows(ledgerData[section], SECTION_TITLES[section] || section);

                doc.autoTable({
                    startY: startY,
                    head: [tableColumn],
                    body: rows,
                    styles: { fontSize: 10 },
                    headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
                    columnStyles: {
                        1: { halign: 'right' }
                    },
                });

                startY = doc.lastAutoTable.finalY + 10;
            }
        });

        // Add final result
        const result = calculateResult();
        doc.text(
            `Beräknat resultat: ${formatAmount(result)}`,
            14,
            startY + 10
        );

        doc.save(`Resultatrapport_${selectedFiscalYear?.year}.pdf`);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const result = calculateResult();

    return (
        <>
            <PageTitle motherMenu="Rapporter" activeMenu="Resultatrapport" />

            {fiscalYears.length === 0 ? (
                <>
                    <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                        padding: '10px'
                    }}>
                        <Link
                            style={{ marginTop: "20px" }}
                            to="#"
                            onClick={() => history.push(`/${companyId}/settings`)}
                            className="btn btn-primary"
                        >
                            Gå till Inställningar
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '2rem',
                        width: '100%'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <span>Räkenskapsår:</span>
                            <Select
                                value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                                onChange={handleFiscalYearChange}
                                options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                                styles={{ container: (base) => ({ ...base, minWidth: '200px' }) }}
                            />
                        </div>
                        <Button variant="primary" onClick={exportToPDF}>
                            <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: '0.5rem' }} />
                            Exportera till PDF
                        </Button>
                    </div>

                    <Card>
                        <Card.Header>
                            <h5>Resultaträkning</h5>
                            {selectedFiscalYear && (
                                <div style={{ fontSize: '0.8em', color: '#666' }}>
                                    Period: {new Date(selectedFiscalYear.start_date).toLocaleDateString('sv-SE')} - {new Date(selectedFiscalYear.end_date).toLocaleDateString('sv-SE')}
                                </div>
                            )}
                        </Card.Header>
                        <Card.Body style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '800px' }}>
                                <Table hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                    <colgroup>
                                        <col style={{ width: '70%' }} />
                                        <col style={{ width: '30%' }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Konto</th>
                                            <th style={{ textAlign: 'right' }}>Belopp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {MAIN_SECTIONS.map(section =>
                                            ledgerData[section] && renderSection(ledgerData[section], section)
                                        )}
                                    </tbody>
                                </Table>

                                <div style={{
                                    marginTop: '2rem',
                                    padding: '1rem',
                                    backgroundColor: '#f8f9fa',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Beräknat resultat:</span>
                                    <span style={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2em',
                                        color: result >= 0 ? '#198754' : '#dc3545'
                                    }}>
                                        {formatAmount(result)}
                                    </span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}

            <style jsx>{`
        .hover-row:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
        .cursor-pointer {
          cursor: pointer;
        }
      `}</style>
        </>
    );
};

export default ResultReport;





// import React, { useState, useEffect } from 'react';
// import { Table, Card, Button } from 'react-bootstrap';
// import { useParams } from 'react-router-dom';
// import axios from '../../../../services/AxiosInstance';
// import { getApiUrl } from '../../../../utils/ApiUtils';
// import PageTitle from '../../../layouts/PageTitle';
// import Select from 'react-select';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import { NoData } from '../../SharedComponents/NoData';
// import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

// function ResultReport() {
//     const [fiscalYears, setFiscalYears] = useState([]);
//     const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
//     const { companyId } = useParams();
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [ledgerData, setLedgerData] = useState({});

//     const history = useHistory();

//     useEffect(() => {
//         getFiscalYears();
//     }, [companyId]);

//     useEffect(() => {
//         if (selectedFiscalYear) {
//             getResultReport();
//         }
//     }, [selectedFiscalYear]);

//     const getFiscalYears = async () => {
//         try {
//             const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
//             setFiscalYears(result.data.fiscal_years);
//             if (result.data.fiscal_years.length > 0) {
//                 const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
//                 setSelectedFiscalYear(latestFiscalYear);
//             }
//         } catch (err) {
//             setError('Failed to fetch fiscal years');
//             setFiscalYears([]);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const getResultReport = async () => {
//         if (!selectedFiscalYear) return;

//         const queryParams = {
//             fiscalYearId: selectedFiscalYear.fiscal_year_id,
//         };

//         try {
//             const response = await axios.get(getApiUrl(`${companyId}/reporting/result`), {
//                 params: queryParams,
//                 withCredentials: true
//             });
//             setLedgerData(response.data);
//         } catch (err) {
//             setError('Failed to fetch result report');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleFiscalYearChange = (selectedOption) => {
//         setSelectedFiscalYear(selectedOption.value);
//         setLoading(true);
//     };

//     const renderSection = (sectionData, title, showSum = false) => {
//         if (!sectionData) return null;

//         const rows = Object.keys(sectionData)
//             .filter((key) => key !== 'total_opening_balance' && key !== 'total_change' && key !== 'total_closing_balance')
//             .map((key) => {
//                 const subSection = sectionData[key];
//                 if (typeof subSection === 'object' && subSection.accounts && subSection.accounts.length > 0) {
//                     return (
//                         <React.Fragment key={key}>
//                             <tr>
//                                 <td style={{ fontWeight: 'bold' }}>{key}</td>
//                                 <td></td>
//                             </tr>
//                             {subSection.accounts.map((account, idx) => (
//                                 <tr key={idx}>
//                                     <td>{`${account.account_number} - ${account.account_name}`}</td>
//                                     <td>{parseFloat(account.change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
//                                 </tr>
//                             ))}
//                             {subSection.total_change && (
//                                 <tr style={{ fontWeight: 'bold' }}>
//                                     <td>{`Summa ${key.toLowerCase()}`}</td>
//                                     <td>{parseFloat(subSection.total_change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
//                                 </tr>
//                             )}
//                         </React.Fragment>
//                     );
//                 } else if (typeof subSection === 'object') {
//                     return renderSection(subSection, key, false);
//                 }
//                 return null;
//             });

//         return (
//             <>
//                 <tr>
//                     <td colSpan="2" style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{title}</td>
//                 </tr>
//                 {rows}
//                 {showSum && sectionData.total_change && (
//                     <tr style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
//                         <td>{`Summa ${title.toLowerCase()}`}</td>
//                         <td>{parseFloat(sectionData.total_change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
//                     </tr>
//                 )}
//             </>
//         );
//     };

//     const calculateResult = () => {
//         const income = parseFloat(ledgerData['Rörelseintäkter, lagerförändringar m.m.']?.total_change || 0);
//         const expenses = parseFloat(ledgerData['Rörelsekostnader']?.total_change || 0);
//         return income + expenses; // Expenses are typically negative, so summing gives the result.
//     };

//     const result = calculateResult();

//     const exportToPDF = () => {
//         const doc = new jsPDF();
//         const tableColumn = ["Beskrivning", "Belopp"];

//         const getSectionRows = (section, title) => {
//             if (!section || typeof section !== 'object') return [];
//             let rows = [];

//             Object.keys(section).forEach((key) => {
//                 const subSection = section[key];
//                 if (typeof subSection === 'object' && subSection.accounts && subSection.accounts.length > 0) {
//                     rows.push([{ content: key, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold' } }]);
//                     subSection.accounts.forEach((account) => {
//                         rows.push([
//                             `${account.account_number} - ${account.account_name}`,
//                             parseFloat(account.change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                         ]);
//                     });
//                     if (subSection.total_change) {
//                         rows.push([
//                             `Summa ${key.toLowerCase()}`,
//                             parseFloat(subSection.total_change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                         ]);
//                     }
//                 } else if (typeof subSection === 'object') {
//                     rows = rows.concat(getSectionRows(subSection, key));
//                 }
//             });

//             rows.unshift([{ content: title, colSpan: 2, styles: { halign: 'left', fontSize: 14, fontStyle: 'bold' } }]);

//             return rows;
//         };

//         const incomeRows = getSectionRows(ledgerData['Rörelseintäkter, lagerförändringar m.m.'], 'Rörelsens intäkter');
//         const expenseRows = getSectionRows(ledgerData['Rörelsekostnader'], 'Rörelsens kostnader');

//         doc.text("Resultaträkning", 14, 10);
//         doc.autoTable({
//             head: [tableColumn],
//             body: incomeRows,
//             startY: 20,
//         });

//         doc.autoTable({
//             head: [tableColumn],
//             body: expenseRows,
//             startY: doc.previousAutoTable.finalY + 10,
//         });

//         doc.text(`Resultat: ${result.toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 14, doc.previousAutoTable.finalY + 20);

//         doc.save(`Resultatrapport_${selectedFiscalYear.year}.pdf`);
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>{error}</p>;

//     return (
//         <>
//             <PageTitle motherMenu={"Rapporter"} activeMenu={"Resultatrapport"} />

//             {loading ? (
//                 <p>Loading...</p>
//             ) : fiscalYears.length === 0 ? (
//                 <>
//                     <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
//                     <div style={{
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         height: '100%',
//                         textAlign: 'center',
//                         padding: '10px'
//                     }}>
//                         <Link
//                             style={{ marginTop: "20px", alignItem: "center" }}
//                             to={`#`}
//                             onClick={() => history.push(`/${companyId}/settings`)}
//                             className="btn btn-primary"
//                         >
//                             Gå till Inställningar
//                         </Link>
//                     </div>
//                 </>
//             ) : (
//                 <>

//                     <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%' }}>
//                         <div className="d-flex align-items-center gap-4">
//                             <span>Räkenskapsår:</span>
//                             <Select
//                                 value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
//                                 onChange={handleFiscalYearChange}
//                                 options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
//                             />
//                         </div>
//                         <Button variant="primary" onClick={exportToPDF}>Exportera till PDF</Button>
//                     </div>

//                     <Card className="mb-3">
//                         <Card.Header>
//                             <h5>Resultaträkning</h5>
//                         </Card.Header>
//                         <Card.Body>
//                             <Table responsive>
//                                 <tbody>
//                                     {renderSection(ledgerData['Rörelseintäkter, lagerförändringar m.m.'], 'Rörelsens intäkter', true)}
//                                     {renderSection(ledgerData['Rörelsekostnader'], 'Rörelsens kostnader', true)}
//                                     {renderSection(ledgerData['Bokslutsdispositioner'], 'Bokslutsdispositioner', true)}
//                                     {renderSection(ledgerData['Finansiella poster'], 'Finansiella poster', true)}
//                                     {renderSection(ledgerData['Skatter'], 'Skatter', true)}

//                                 </tbody>
//                             </Table>
//                             <hr />
//                             <div style={{ fontWeight: 'bold', fontSize: '1.2em', textAlign: 'left' }}>
//                                 <span>Beräknat resultat:</span> <span>{result.toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
//                             </div>
//                         </Card.Body>
//                     </Card>
//                 </>
//             )}
//         </>
//     );
// }

// export default ResultReport;
