import React, { useState, useEffect } from 'react';
import { Table, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const BalanceReport = () => {
    const [fiscalYears, setFiscalYears] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ledgerData, setLedgerData] = useState({});
    const [expandedSections, setExpandedSections] = useState({
        'TILLGANGAR': true,
        'EGET KAPITAL OCH SKULDER': true
    });

    const { companyId } = useParams();

    useEffect(() => {
        getFiscalYears();
    }, [companyId]);

    useEffect(() => {
        if (selectedFiscalYear) {
            getBalanceReport();
        }
    }, [selectedFiscalYear]);

    const getFiscalYears = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
            setFiscalYears(result.data.fiscal_years);
            if (result.data.fiscal_years.length > 0) {
                const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
                setSelectedFiscalYear(latestFiscalYear);
            }
        } catch (err) {
            setError('Failed to fetch fiscal years');
        } finally {
            setLoading(false);
        }
    };

    const getBalanceReport = async () => {
        if (!selectedFiscalYear) return;

        const queryParams = {
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
        };

        try {
            const response = await axios.get(getApiUrl(`${companyId}/reporting/balance`), {
                params: queryParams,
                withCredentials: true
            });
            setLedgerData(response.data);
        } catch (err) {
            setError('Failed to fetch balance report');
        } finally {
            setLoading(false);
        }
    };

    const toggleSection = (sectionPath) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionPath]: !prev[sectionPath]
        }));
    };

    const formatAmount = (amount) => {
        if (!amount) return '0,00';
        return new Intl.NumberFormat('sv-SE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: 'decimal'
        }).format(parseFloat(amount));
    };

    const renderAccountRow = (account, depth) => {
        if (!account) return null;
        return (
            <tr key={account.account_id} className="hover-row">
                <td style={{ paddingLeft: `${(depth + 1) * 1.5}rem` }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', overflow: 'hidden' }}>
                        <span style={{ color: '#666', minWidth: '4rem', flexShrink: 0 }}>{account.account_number}</span>
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{account.account_name}</span>
                    </div>
                </td>
                <td style={{ textAlign: 'right' }}>{formatAmount(account.opening_balance)}</td>
                <td style={{ textAlign: 'right' }}>{formatAmount(account.change)}</td>
                <td style={{ textAlign: 'right' }}>{formatAmount(account.closing_balance)}</td>
            </tr>
        );
    };

    const renderSection = (sectionData, sectionName, path = '', depth = 0) => {
        if (!sectionData || typeof sectionData !== 'object') return null;

        const currentPath = path ? `${path}.${sectionName}` : sectionName;
        const hasSubsections = Object.keys(sectionData).some(key =>
            typeof sectionData[key] === 'object' && !['accounts', 'regex'].includes(key)
        );
        const isExpanded = expandedSections[currentPath] !== false;

        return (
            <React.Fragment key={currentPath}>
                <tr className={hasSubsections ? 'cursor-pointer hover-row' : ''}>
                    <td
                        style={{
                            paddingLeft: `${depth * 1.5}rem`,
                            backgroundColor: depth === 0 ? '#f8f9fa' : depth === 1 ? '#ffffff' : 'transparent',
                        }}
                        onClick={() => hasSubsections && toggleSection(currentPath)}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            {hasSubsections && (
                                <span style={{ color: '#666', width: '20px' }}>
                                    <FontAwesomeIcon
                                        icon={isExpanded ? faChevronDown : faChevronRight}
                                        size="sm"
                                    />
                                </span>
                            )}
                            <strong>{sectionName}</strong>
                        </div>
                    </td>
                    {sectionData.total_opening_balance !== undefined && (
                        <>
                            <td style={{ textAlign: 'right' }}><strong>{formatAmount(sectionData.total_opening_balance)}</strong></td>
                            <td style={{ textAlign: 'right' }}><strong>{formatAmount(sectionData.total_change)}</strong></td>
                            <td style={{ textAlign: 'right' }}><strong>{formatAmount(sectionData.total_closing_balance)}</strong></td>
                        </>
                    )}
                    {!sectionData.total_opening_balance && (
                        <>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                        </>
                    )}
                </tr>

                {isExpanded && (
                    <>
                        {sectionData.accounts?.map(account => renderAccountRow(account, depth))}

                        {Object.entries(sectionData)
                            .filter(([key, value]) =>
                                typeof value === 'object' &&
                                !['accounts', 'regex'].includes(key)
                            )
                            .map(([key, value]) => renderSection(value, key, currentPath, depth + 1))}
                    </>
                )}
            </React.Fragment>
        );
    };

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption.value);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        // Add header information
        doc.setFontSize(14);
        doc.text("Balansräkning", 14, 10);
        doc.setFontSize(11);
        doc.text(`Räkenskapsår: ${selectedFiscalYear?.year || ''}`, 14, 20);

        const tableColumn = ["", "Ingående balans", "Förändring", "Utgående saldo"];

        // Helper function to convert section to rows for PDF
        const getSectionRows = (section, depth = 0) => {
            if (!section || typeof section !== 'object') return [];
            let rows = [];

            Object.keys(section).forEach((key) => {
                const subSection = section[key];
                if (!subSection || typeof subSection !== 'object') return;

                const isLeafNode = subSection.accounts && Array.isArray(subSection.accounts) && subSection.accounts.length > 0;

                rows.push([{ content: key, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]);

                if (isLeafNode) {
                    subSection.accounts.forEach((account) => {
                        rows.push([
                            `${' '.repeat(depth * 2)}${account.account_number} - ${account.account_name}`,
                            account.opening_balance,
                            account.change,
                            account.closing_balance,
                        ]);
                    });

                    rows.push([
                        { content: `Summa ${key}`, colSpan: 1, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_opening_balance, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_change, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_closing_balance, styles: { fontStyle: 'bold' } }
                    ]);
                } else {
                    rows = rows.concat(getSectionRows(subSection, depth + 1));
                }
            });

            return rows;
        };

        // Add TILLGANGAR section
        doc.autoTable({
            head: [tableColumn],
            body: [[{ content: "Tillgångar", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]],
            startY: 30,
            styles: { fontSize: 10 },
        });

        doc.autoTable({
            body: getSectionRows(ledgerData.TILLGANGAR),
            startY: doc.lastAutoTable.finalY,
            styles: { fontSize: 10 },
        });

        // Add EGET KAPITAL OCH SKULDER section
        let skulderStartY = doc.lastAutoTable.finalY + 10;

        doc.autoTable({
            head: [tableColumn],
            body: [[{ content: "Eget Kapital och Skulder", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]],
            startY: skulderStartY,
            styles: { fontSize: 10 },
        });

        doc.autoTable({
            body: getSectionRows(ledgerData['EGET KAPITAL OCH SKULDER']),
            startY: doc.lastAutoTable.finalY,
            styles: { fontSize: 10 },
        });

        doc.save(`BalanceReport_${selectedFiscalYear?.year}.pdf`);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '2rem',
                width: '100%'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <span>Räkenskapsår:</span>
                    <Select
                        value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                        onChange={handleFiscalYearChange}
                        options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                        styles={{ container: (base) => ({ ...base, minWidth: '200px' }) }}
                    />
                </div>
                <Button variant="primary" onClick={exportToPDF}>
                    <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: '0.5rem' }} />
                    Exportera till PDF
                </Button>
            </div>

            <Card>
                <Card.Header>
                    <h5>Balansrapport</h5>
                </Card.Header>
                <Card.Body style={{ overflowX: 'auto' }}>
                    <div style={{ overflowX: 'auto' }}>
                        <div style={{ minWidth: '800px' }}>
                            <Table hover style={{ tableLayout: 'fixed', width: '100%', marginBottom: '0' }}>
                                <colgroup>
                                    <col style={{ width: '40%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{ width: '40%' }}>Konto</th>
                                        <th style={{ width: '20%', textAlign: 'right' }}>
                                            <div style={{ fontSize: '0.85em' }}>Ingående saldo</div>
                                            <div style={{ fontSize: '0.75em', color: '#666' }}>
                                                {selectedFiscalYear ? new Date(selectedFiscalYear.start_date).toLocaleDateString('sv-SE') : ''}
                                            </div>
                                        </th>
                                        <th style={{ width: '20%', textAlign: 'right', fontSize: '0.85em' }}>Förändring</th>
                                        <th style={{ width: '20%', textAlign: 'right' }}>
                                            <div style={{ fontSize: '0.85em' }}>Utgående saldo</div>
                                            <div style={{ fontSize: '0.75em', color: '#666' }}>
                                                {selectedFiscalYear ? new Date(selectedFiscalYear.end_date).toLocaleDateString('sv-SE') : ''}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ledgerData?.TILLGANGAR && renderSection(ledgerData.TILLGANGAR, 'TILLGANGAR')}
                                </tbody>
                            </Table>

                            <div style={{ marginBottom: '30px' }} />

                            <Table hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                <colgroup>
                                    <col style={{ width: '40%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{ width: '40%' }}>Konto</th>
                                        <th style={{ width: '20%', textAlign: 'right' }}>
                                            <div style={{ fontSize: '0.85em' }}>Ingående saldo</div>
                                            <div style={{ fontSize: '0.75em', color: '#666' }}>
                                                {selectedFiscalYear ? new Date(selectedFiscalYear.start_date).toLocaleDateString('sv-SE') : ''}
                                            </div>
                                        </th>
                                        <th style={{ width: '20%', textAlign: 'right', fontSize: '0.85em' }}>Förändring</th>
                                        <th style={{ width: '20%', textAlign: 'right' }}>
                                            <div style={{ fontSize: '0.85em' }}>Utgående saldo</div>
                                            <div style={{ fontSize: '0.75em', color: '#666' }}>
                                                {selectedFiscalYear ? new Date(selectedFiscalYear.end_date).toLocaleDateString('sv-SE') : ''}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ledgerData?.['EGET KAPITAL OCH SKULDER'] &&
                                        renderSection(ledgerData['EGET KAPITAL OCH SKULDER'], 'EGET KAPITAL OCH SKULDER')}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <style jsx>{`
        .hover-row:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
        .cursor-pointer {
          cursor: pointer;
        }
      `}</style>
        </>
    );
};

export default BalanceReport;



/*import React, { useState, useEffect } from 'react';
import { Table, Card, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { NoData } from '../../SharedComponents/NoData';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function BalanceReport() {
    const [fiscalYears, setFiscalYears] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const { companyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ledgerData, setLedgerData] = useState({});

    const history = useHistory();

    useEffect(() => {
        getFiscalYears();
    }, [companyId]);

    useEffect(() => {
        if (selectedFiscalYear) {
            getBalanceReport();
        }
    }, [selectedFiscalYear]);

    const getFiscalYears = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
            setFiscalYears(result.data.fiscal_years);
            if (result.data.fiscal_years.length > 0) {
                const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
                setSelectedFiscalYear(latestFiscalYear);
            }
        } catch (err) {
            setError('Failed to fetch fiscal years');
            setFiscalYears([]);
        } finally {
            setLoading(false);
        }
    };

    const getBalanceReport = async () => {
        if (!selectedFiscalYear) return;

        const queryParams = {
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
        };

        try {
            const response = await axios.get(getApiUrl(`${companyId}/reporting/balance`), {
                params: queryParams,
                withCredentials: true
            });
            setLedgerData(response.data);
        } catch (err) {
            setError('Failed to fetch balance report');
        } finally {
            setLoading(false);
        }
    };

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption.value);
        setLoading(true);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        // Add header information
        doc.setFontSize(14);
        doc.text("Balansräkning", 14, 10);
        doc.setFontSize(11);
        doc.text("Företagsnamn: Example Company AB", 14, 20);
        doc.text("Organisationsnummer: 123456-7890", 14, 26);
        doc.text("Räkenskapsår: 2024-01-01 - 2024-12-31", 14, 32);

        const tableColumn = ["", "Ingående balans", "Förändring", "Utgående saldo"];

        // Helper function to convert section to rows for PDF
        const getSectionRows = (section, depth = 0) => {
            if (!section || typeof section !== 'object') return [];
            let rows = [];

            Object.keys(section).forEach((key) => {
                const subSection = section[key];
                if (!subSection || typeof subSection !== 'object') return;

                const isLeafNode = subSection.accounts && Array.isArray(subSection.accounts) && subSection.accounts.length > 0;

                // Add header row for the subsection
                rows.push([{ content: key, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]);

                if (isLeafNode) {
                    // Add account details
                    subSection.accounts.forEach((account) => {
                        rows.push([
                            `${' '.repeat(depth * 2)}${account.account_number} - ${account.account_name}`,
                            account.opening_balance,
                            account.change,
                            account.closing_balance,
                        ]);
                    });

                    // Add subtotal row for the subsection
                    rows.push([
                        { content: `Summa ${key}`, colSpan: 1, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_opening_balance, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_change, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_closing_balance, styles: { fontStyle: 'bold' } }
                    ]);
                } else {
                    // Recursively process nested sections
                    rows = rows.concat(getSectionRows(subSection, depth + 1));

                    // Add a sum row for the non-leaf subsection
                    rows.push([
                        { content: `Summa ${key}`, colSpan: 1, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_opening_balance, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_change, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_closing_balance, styles: { fontStyle: 'bold' } }
                    ]);
                }
            });

            return rows;
        };

        // Render for Tillgångar
        let tillgangarRows = getSectionRows(ledgerData.TILLGANGAR);

        // Add Tillgångar section header
        doc.autoTable({
            head: [tableColumn],
            body: [[{ content: "Tillgångar", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]],
            startY: 40,
        });

        doc.autoTable({
            body: tillgangarRows,
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        // Add Summa Tillgångar at the end of the Tillgångar section
        doc.autoTable({
            body: [
                [
                    { content: "Summa Tillgångar", colSpan: 1, styles: { fontStyle: 'bold' } },
                    { content: ledgerData.TILLGANGAR.total_opening_balance, styles: { fontStyle: 'bold' } },
                    { content: ledgerData.TILLGANGAR.total_change, styles: { fontStyle: 'bold' } },
                    { content: ledgerData.TILLGANGAR.total_closing_balance, styles: { fontStyle: 'bold' } }
                ]
            ],
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        // Add space between major sections
        let egetKapitalStartY = doc.lastAutoTable.finalY + 10;

        // Render for Eget Kapital och Skulder
        let egetKapitalRows = getSectionRows(ledgerData['EGET KAPITAL OCH SKULDER']);

        // Add Eget Kapital och Skulder section header
        doc.autoTable({
            head: [tableColumn],
            body: [[{ content: "Eget Kapital och Skulder", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]],
            startY: egetKapitalStartY,
        });

        doc.autoTable({
            body: egetKapitalRows,
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        // Add Summa Eget Kapital och Skulder at the end
        doc.autoTable({
            body: [
                [
                    { content: "Summa Eget Kapital och Skulder", colSpan: 1, styles: { fontStyle: 'bold' } },
                    { content: ledgerData['EGET KAPITAL OCH SKULDER'].total_opening_balance, styles: { fontStyle: 'bold' } },
                    { content: ledgerData['EGET KAPITAL OCH SKULDER'].total_change, styles: { fontStyle: 'bold' } },
                    { content: ledgerData['EGET KAPITAL OCH SKULDER'].total_closing_balance, styles: { fontStyle: 'bold' } }
                ]
            ],
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        doc.save(`BalanceReport_${selectedFiscalYear.year}.pdf`);
    };

    const renderSection = (section, depth = 0) => {
        if (!section || typeof section !== 'object') return null;

        return Object.keys(section).map((key) => {
            const subSection = section[key];
            if (!subSection || typeof subSection !== 'object') return null;

            const isLeafNode = subSection.accounts && Array.isArray(subSection.accounts) && subSection.accounts.length > 0;

            return (
                <React.Fragment key={`${depth}-${key}`}>
                    <tr>
                        <td style={{ paddingLeft: `${depth * 20}px` }}><strong>{key}</strong></td>
                        <td><strong>{subSection.total_opening_balance || ''}</strong></td>
                        <td><strong>{subSection.total_change || ''}</strong></td>
                        <td><strong>{subSection.total_closing_balance || ''}</strong></td>
                    </tr>
                    {isLeafNode && subSection.accounts.map((account) => (
                        <tr key={account.account_id}>
                            <td style={{ paddingLeft: `${(depth + 1) * 20}px` }}>{account.account_number} - {account.account_name}</td>
                            <td>{account.opening_balance}</td>
                            <td>{account.change}</td>
                            <td>{account.closing_balance}</td>
                        </tr>
                    ))}
                    {!isLeafNode && subSection !== section && renderSection(subSection, depth + 1)}
                </React.Fragment>
            );
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <PageTitle motherMenu={"Rapporter"} activeMenu={"Balansrapport"} />

            {loading ? (
                <p>Loading...</p>
            ) : fiscalYears.length === 0 ? (
                <>
                    <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                        padding: '10px'
                    }}>
                        <Link
                            style={{ marginTop: "20px", alignItem: "center" }}
                            to={`#`}
                            onClick={() => history.push(`/${companyId}/settings`)}
                            className="btn btn-primary"
                        >
                            Gå till Inställningar
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%' }}>
                        <div className="d-flex align-items-center gap-4">
                            <span>Räkenskapsår:</span>
                            <Select
                                value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                                onChange={handleFiscalYearChange}
                                options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                            />
                        </div>
                        <Button variant="primary" onClick={exportToPDF}>Exportera till PDF</Button>
                    </div>

                    {/* Table for Tillgångar }
                    <Card className="mb-3">
                        <Card.Header>
                            <h5>Balansrapport</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Konto</th>
                                        <th>Ingående saldo</th>
                                        <th>Förändring</th>
                                        <th>Utgående saldo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Tillgångar</strong></td>
                                        <td><strong>{ledgerData.TILLGANGAR?.total_opening_balance || ''}</strong></td>
                                        <td><strong>{ledgerData.TILLGANGAR?.total_change || ''}</strong></td>
                                        <td><strong>{ledgerData.TILLGANGAR?.total_closing_balance || ''}</strong></td>
                                    </tr>
                                    {renderSection(ledgerData.TILLGANGAR)}
                                </tbody>
                            </Table>

                            { Adding some space between the two tables }
                            <div style={{ marginBottom: '30px' }} />

                            {/* Table for Eget Kapital och Skulder }
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Eget Kapital och Skulder</strong></td>
                                        <td><strong>{ledgerData['EGET KAPITAL OCH SKULDER']?.total_opening_balance || ''}</strong></td>
                                        <td><strong>{ledgerData['EGET KAPITAL OCH SKULDER']?.total_change || ''}</strong></td>
                                        <td><strong>{ledgerData['EGET KAPITAL OCH SKULDER']?.total_closing_balance || ''}</strong></td>
                                    </tr>
                                    {renderSection(ledgerData['EGET KAPITAL OCH SKULDER'])}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </>
            )
            }
        </>
    );
}

export default BalanceReport;
*/
