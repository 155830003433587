import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form, Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from "../../../../utils/ApiUtils";
import swal from "sweetalert";

// Helper function to format numbers as Swedish currency (SEK)
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amount);
};

const DeleteVerificationModal = ({ show, handleClose, verificationData, companyId }) => {
  const [annulmentTransactions, setAnnulmentTransactions] = useState([]);
  const [allowFullDeletion, setAllowFullDeletion] = useState(false);

  useEffect(() => {
    generateAnnulmentTransactions();
  }, [verificationData]);

  // Generate annulment transactions
  const generateAnnulmentTransactions = () => {
    if (verificationData && verificationData.transactions) {
      const reversedTransactions = verificationData.transactions.map((transaction) => ({
        ...transaction,
        transaction_type: transaction.transaction_type === 'debit' ? 'credit' : 'debit',
      }));
      setAnnulmentTransactions(reversedTransactions);
    }
  };


  const handleDelete = async () => {
    try {
      // Send DELETE request with the `allowFullDeletion` flag in the request body
      await axios.delete(getApiUrl(`${companyId}/verifications/${verificationData.verification_id}`), {
        data: { allowFullDeletion },  // Pass the checkbox value
        withCredentials: true,
      });

      if (allowFullDeletion) {
        swal("Raderat!", "Verifikatet är nu helt raderat", "success");
      } else {
        swal("Annulerat!", "Verifikatet har nu blivit annulerat", "success");
      }

      handleClose();
    } catch (error) {
      swal("Fel", "Det gick inte att radera verifikatet", "error");
    }
  };


  // Tooltip component
  const renderTooltip = (props) => (
    <Tooltip {...props}>
      Annulment will reverse the transactions. Full deletion should only be used during development.
    </Tooltip>
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Radera verifikat: {verificationData.series + verificationData.number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p> För att radera ett verifikat så skapas ett nytt verifikat som tar bort effekten av det verifikat du vill radera.</p>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Original Verification</Accordion.Header>
            <Accordion.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Konto</th>
                    <th>Debet</th>
                    <th>Kredit</th>
                  </tr>
                </thead>
                <tbody>
                  {verificationData.transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.account_number} - {transaction.account_name}</td>
                      <td>{transaction.transaction_type === 'debit' ? formatCurrency(transaction.amount) : 0}</td>
                      <td>{transaction.transaction_type === 'credit' ? formatCurrency(transaction.amount) : 0}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Annulment Verification</Accordion.Header>
            <Accordion.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Konto</th>
                    <th>Debet</th>
                    <th>Kredit</th>
                  </tr>
                </thead>
                <tbody>
                  {annulmentTransactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.account_number} - {transaction.account_name}</td>
                      <td>{transaction.transaction_type === 'debit' ? formatCurrency(transaction.amount) : formatCurrency(0)}</td>
                      <td>{transaction.transaction_type === 'credit' ? formatCurrency(transaction.amount) : formatCurrency(0)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Form.Group className="mt-4">
          <Form.Check
            type="checkbox"
            label="Allow Full Deletion (Development Only)"
            checked={allowFullDeletion}
            onChange={(e) => setAllowFullDeletion(e.target.checked)}
          />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-muted" />
          </OverlayTrigger>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Avbryt
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {allowFullDeletion ? "Radera verifikat" : "Annullera verifikat"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteVerificationModal;
