import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCopy, faTrash, faUpload, faFileInvoiceDollar, faSpinner, faLock } from '@fortawesome/free-solid-svg-icons';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import DeleteVerificationModal from './DeleteVerificationModal';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import swal from 'sweetalert';

const getVerificationPermissions = (verificationData) => {
  const permissions = {
    canDelete: true,
    canEdit: true,
    reason: null
  };

  if (!verificationData || !verificationData.references) {
    return permissions;
  }

  // Check if this is an annulment verification
  if (verificationData.isAnnulmentVerification) {
    permissions.canDelete = false;
    permissions.canEdit = false;
    permissions.reason = 'Detta är ett annuleringsverifikat och kan inte ändras.';
    return permissions;
  }

  // Check if this verification has been annulled
  if (verificationData.hasBeenAnnulled) {
    permissions.canDelete = false;
    permissions.canEdit = false;
    permissions.reason = 'Detta verifikat har redan blivit annulerat.';
    return permissions;
  }

  // Check source references (what this verification refers to)
  for (const ref of verificationData.references.asSource || []) {
    switch (ref.reference_type) {
      case 'customer_invoice_payment':
        permissions.canDelete = false;
        permissions.canEdit = false;
        permissions.reason = 'Detta verifikat är kopplat till en fakturabetalning och kan inte ändras här. Gå till fakturavyn för att hantera betalningen.';
        return permissions;

      case 'payment_reversal':
        permissions.canDelete = false;
        permissions.canEdit = false;
        permissions.reason = 'Detta verifikat är kopplat till en betalningsannulering och kan inte ändras.';
        return permissions;
    }
  }

  // Check target references (what refers to this verification)
  for (const ref of verificationData.references.asTarget || []) {
    if (ref.reference_type === 'verification_reversal') {
      permissions.canDelete = false;
      permissions.canEdit = false;
      permissions.reason = 'Detta verifikat har redan annullerats.';
      return permissions;
    }
  }

  // Check if this is an invoice related verification
  if (verificationData.invoice) {
    permissions.canDelete = false;
    permissions.canEdit = false;
    permissions.reason = 'Detta verifikat är kopplat till en faktura och kan inte ändras här. Gå till fakturavyn för att hantera det.';
    return permissions;
  }

  return permissions;
};
const InvoiceSection = ({ invoice, companyId }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('InvoiceSection invoice:', invoice);
  console.log('InvoiceSection companyId:', companyId);

  useEffect(() => {
    let objectUrl;

    const fetchPdf = async () => {
      try {
        const response = await axios.get(
          getApiUrl(`${companyId}/invoices/${invoice.invoice_id}/pdf`),
          {
            responseType: 'blob',
            withCredentials: true,
          }
        );

        objectUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        setPdfUrl(objectUrl);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      } finally {
        setLoading(false);
      }
    };

    if (invoice?.invoice_id) {
      fetchPdf();
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [invoice?.invoice_id, companyId]);

  if (!invoice?.invoice_id) return null;

  return (
    <div className="border rounded p-3 mb-4">
      <h6 className="text-muted mb-3">Faktura #{invoice.invoice_number}</h6>
      {loading ? (
        <div className="text-center py-3">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <object
          data={pdfUrl}
          type="application/pdf"
          style={{
            width: '100%',
            height: '400px',
            border: 'none'
          }}
        >
          <div className="text-center py-3">
            <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />
            PDF kunde inte visas
          </div>
        </object>
      )}
    </div>
  );
};

const VerificationDetailsModal = ({
  showVerificationDetails,
  setShowVerificationDetails,
  selectedRow,
  onVerificationUpdated,
  onUploadAttachment,
}) => {
  const { companyId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attachments, setAttachments] = useState([]);

  // Calculate permissions based on verification references
  const permissions = useMemo(() => {
    return getVerificationPermissions(selectedRow, selectedRow?.references);
  }, [selectedRow]);

  // Reset attachments when modal opens or closes
  useEffect(() => {
    if (!showVerificationDetails) {
      setAttachments([]);
    }
  }, [showVerificationDetails]);

  // Fetch attachments
  useEffect(() => {
    if (selectedRow?.verification_id) {
      const fetchAttachments = async () => {
        try {
          const response = await axios.get(
            getApiUrl(`${companyId}/accounting/verifications/${selectedRow.verification_id}/getattachments`),
            { withCredentials: true }
          );
          const attachmentsData = response.data.attachments || [];

          const attachmentsWithThumbnails = await Promise.all(
            attachmentsData.map(async (attachment) => {
              try {
                const thumbnailResponse = await axios.get(
                  getApiUrl(`${companyId}/accounting/verifications/thumbnail/${attachment.attachment_id}`),
                  {
                    responseType: 'blob',
                    withCredentials: true,
                  }
                );
                const thumbnailUrl = URL.createObjectURL(thumbnailResponse.data);
                return { ...attachment, thumbnailUrl };
              } catch (error) {
                console.error('Error fetching attachment thumbnail:', error);
                return attachment;
              }
            })
          );

          setAttachments(attachmentsWithThumbnails);
        } catch (error) {
          console.error('Error fetching attachments:', error);
        }
      };
      fetchAttachments();
    }
  }, [selectedRow, companyId]);

  // Cleanup object URLs
  useEffect(() => {
    return () => {
      attachments.forEach((attachment) => {
        if (attachment.thumbnailUrl) {
          URL.revokeObjectURL(attachment.thumbnailUrl);
        }
      });
    };
  }, [attachments]);

  const handleDelete = () => {
    if (!permissions.canDelete) {
      swal("Kan inte radera", permissions.reason, "warning");
      return;
    }
    setShowDeleteModal(true);
    setShowVerificationDetails(false);
  };

  const handleEdit = () => {
    if (!permissions.canEdit) {
      swal("Kan inte redigera", permissions.reason, "warning");
      return;
    }
    setShowVerificationDetails(false);
    openEditVerificationModal(selectedRow);
  };

  const handleCopy = () => {
    setShowVerificationDetails(false);
    openCreateVerificationModal(selectedRow);
  };

  const openCreateVerificationModal = (verificationData) => {
    history.push({
      pathname: `/${companyId}/copy-verification`,
      state: { verificationData, from: location.pathname },
    });
  };

  const openEditVerificationModal = (verificationData) => {
    history.push({
      pathname: `/${companyId}/edit-verification/${verificationData.verification_id}`,
      state: { verificationData, from: location.pathname },
    });
  };

  const renderAttachment = (attachment) => {
    if (!attachment.thumbnailUrl) {
      return <p>Loading attachment...</p>;
    }

    if (attachment.file_type.startsWith('image/')) {
      return (
        <img
          src={attachment.thumbnailUrl}
          alt={attachment.file_name}
          style={{ maxWidth: '100%', maxHeight: '300px' }}
        />
      );
    } else if (attachment.file_type === 'application/pdf') {
      return (
        <embed
          src={attachment.thumbnailUrl}
          type="application/pdf"
          width="100%"
          height="300px"
        />
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };

  const formatCurrency = (amount) => {
    return `${new Intl.NumberFormat('sv-SE').format(amount)} kr`;
  };

  return (
    <>
      <Modal
        show={showVerificationDetails}
        onHide={() => setShowVerificationDetails(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Verifikat - {selectedRow?.series}{selectedRow?.number}
            {(!permissions.canEdit || !permissions.canDelete) && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{permissions.reason}</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faLock}
                  className="ms-2 text-muted"
                />
              </OverlayTrigger>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-4">
            <h6 className="text-muted mb-3">Grundinformation</h6>
            <p className="mb-2"><strong>Beskrivning:</strong> {selectedRow?.description}</p>
            <p className="mb-2"><strong>Datum:</strong> {moment(selectedRow?.date).format('YYYY-MM-DD')}</p>
            <p className="mb-0"><strong>Verifikat skapat:</strong> {moment(selectedRow?.created_at).format('YYYY-MM-DD')}</p>
          </div>

          <div className="mb-4">
            <h6 className="text-muted mb-3">Transaktioner</h6>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th>Konto</th>
                  <th className="text-end">Debet</th>
                  <th className="text-end">Kredit</th>
                </tr>
              </thead>
              <tbody>
                {selectedRow?.transactions?.map((transaction, index) => (
                  <tr key={index}>
                    <td>{transaction.account_number} - {transaction.account_name}</td>
                    <td className="text-end">
                      {transaction.transaction_type === 'debit'
                        ? formatCurrency(transaction.amount)
                        : formatCurrency(0)}
                    </td>
                    <td className="text-end">
                      {transaction.transaction_type === 'credit'
                        ? formatCurrency(transaction.amount)
                        : formatCurrency(0)}
                    </td>
                  </tr>
                ))}
                <tr className="border-top">
                  <td><strong>Summa</strong></td>
                  <td className="text-end"><strong>{formatCurrency(selectedRow?.debitSum)}</strong></td>
                  <td className="text-end"><strong>{formatCurrency(selectedRow?.creditSum)}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>

          {selectedRow?.invoice && (
            <InvoiceSection invoice={selectedRow.invoice} companyId={companyId} />
          )}

          {attachments.length > 0 && (
            <div className="border rounded p-3">
              <h6 className="text-muted mb-3">Bilagor</h6>
              <div className="attachment-previews">
                {attachments.map((attachment, index) => (
                  <div key={index} className="attachment-item mb-3">
                    {renderAttachment(attachment)}
                    <div className="mt-2 small text-muted">
                      {attachment.file_name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleEdit}
            disabled={!permissions.canEdit}
          >
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Redigera
          </Button>
          <Button variant="outline-primary" onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} className="me-2" /> Kopiera
          </Button>
          <Button
            variant="outline-danger"
            onClick={handleDelete}
            disabled={!permissions.canDelete}
          >
            <FontAwesomeIcon icon={faTrash} className="me-2" /> Radera
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => onUploadAttachment(selectedRow.verification_id)}
            disabled={!permissions.canEdit}
          >
            <FontAwesomeIcon icon={faUpload} className="me-2" /> Ladda upp underlag
          </Button>
        </Modal.Footer>
      </Modal>

      {permissions.canDelete && selectedRow && selectedRow.transactions?.length > 0 && (
        <DeleteVerificationModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          verificationData={selectedRow}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default VerificationDetailsModal;