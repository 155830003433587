import React, { useState, useEffect } from 'react';
import { Card, Form, Table, Button, Modal } from 'react-bootstrap';
import { isEqual } from 'lodash';

const styles = {
    textItemsSection: {
        backgroundColor: '#f8f9fa',
        padding: '1rem',
        borderRadius: '8px'
    },
    textItemsTable: {
        border: 'none'
    },
    textItem: {
        fontStyle: 'italic',
        color: '#666'
    },
    textItemTableCell: {
        border: 'none',
        padding: '0.5rem'
    },
    creditNoteSummary: {
        marginTop: '2rem',
        paddingTop: '1rem',
        borderTop: '1px solid #e5e5e5'
    },
    summaryCard: {
        backgroundColor: '#f8f9fa',
        padding: '1.5rem',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    },
    summaryRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '0.75rem',
        padding: '0.25rem 0'
    },
    summaryRowTotal: {
        marginTop: '1rem',
        paddingTop: '1rem',
        borderTop: '2px solid #dee2e6',
        fontWeight: 'bold',
        fontSize: '1.1em'
    },
    label: {
        color: '#495057'
    },
    value: {
        fontFamily: 'monospace',
        fontSize: '1.1em'
    }
};

const TextItemModal = ({ show, handleClose, handleSave }) => {
    const [text, setText] = useState('');

    const onSave = () => {
        if (!text.trim()) return;

        handleSave({
            type: 'Text',
            description: text,
            quantity: 0,
            unitprice: 0,
            tax_rate: '0%',
            productcode: null,
            unit: null
        });
        setText('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Lägg till textrad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Text</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="T.ex. 'Kreditering av faktura på grund av...'"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={onSave} disabled={!text.trim()}>
                    Lägg till
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const CreditNoteItemsCard = ({
    items,
    onUpdateItem,
    onAddTextItem,
    onDeleteItem,
    disableRounding,
    currency = 'SEK'
}) => {
    const [totals, setTotals] = useState({
        subtotal: 0,
        taxBreakdown: {},
        totalTaxes: 0,
        grandTotal: 0,
        roundedTotal: 0,
        rounding: 0,
    });
    const [showTextModal, setShowTextModal] = useState(false);

    const regularItems = items.filter(item => item.type !== 'Text');
    const textItems = items.filter(item => item.type === 'Text');

    useEffect(() => {
        const calculatedTotals = calculateTotals(regularItems, disableRounding);
        if (!isEqual(calculatedTotals, totals)) {
            setTotals(calculatedTotals);
        }
    }, [items, disableRounding]);

    const calculateTotals = (items, disableRounding) => {
        let subtotal = 0;
        let taxBreakdown = {};
        let grandTotal = 0;

        items.forEach((item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitprice = parseFloat(item.unitprice) || 0;
            const amount = quantity * unitprice;
            subtotal += amount;

            const taxRateValue = parseFloat(item.tax_rate) || 0;
            const taxAmount = (amount * taxRateValue) / 100;

            if (!taxBreakdown[taxRateValue]) {
                taxBreakdown[taxRateValue] = {
                    taxRate: taxRateValue,
                    totalAmount: 0,
                    totalTax: 0,
                };
            }
            taxBreakdown[taxRateValue].totalAmount += amount;
            taxBreakdown[taxRateValue].totalTax += taxAmount;
        });

        const totalTaxes = Object.values(taxBreakdown).reduce(
            (acc, curr) => acc + curr.totalTax,
            0
        );
        grandTotal = subtotal + totalTaxes;

        let roundedTotal = grandTotal;
        let rounding = 0;

        if (!disableRounding) {
            roundedTotal = Math.round(grandTotal);
            rounding = roundedTotal - grandTotal;
        }

        return {
            subtotal: parseFloat(subtotal.toFixed(2)),
            taxBreakdown,
            totalTaxes: parseFloat(totalTaxes.toFixed(2)),
            grandTotal: parseFloat(grandTotal.toFixed(2)),
            roundedTotal: parseFloat(roundedTotal.toFixed(2)),
            rounding: parseFloat(rounding.toFixed(2)),
        };
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('sv-SE', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };

    const handleQuantityChange = (index, value) => {
        const actualIndex = items.findIndex(item => item === regularItems[index]);
        if (actualIndex === -1) return;

        const item = items[actualIndex];
        const newQuantity = Math.min(
            Math.max(0, parseInt(value) || 0),
            item.original_quantity || 0
        );
        onUpdateItem(actualIndex, { ...item, quantity: newQuantity });
    };

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="fs-24 font-w800 mb-0">Artiklar att kreditera</h4>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowTextModal(true)}
                    >
                        <i className="fas fa-plus me-2"></i>
                        Lägg till textrad
                    </Button>
                </div>

                {/* Regular items table */}
                <div className="table-responsive mb-4">
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: '100px' }}>Artikelnr</th>
                                <th>Beskrivning</th>
                                <th style={{ width: '120px' }}>Original Antal</th>
                                <th style={{ width: '150px' }}>Antal att Kreditera</th>
                                <th style={{ width: '100px' }}>Enhet</th>
                                <th style={{ width: '120px' }}>Pris</th>
                                <th style={{ width: '100px' }}>Moms</th>
                                <th style={{ width: '120px' }}>Belopp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {regularItems.map((item, index) => (
                                <tr key={`regular-${index}`}>
                                    <td>{item.productcode}</td>
                                    <td>{item.description}</td>
                                    <td>{item.original_quantity}</td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            max={item.original_quantity}
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                        />
                                    </td>
                                    <td>{item.unit}</td>
                                    <td>{formatCurrency(item.unitprice)}</td>
                                    <td>{item.tax_rate}</td>
                                    <td>{formatCurrency(item.quantity * item.unitprice)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {/* Text items section */}
                {textItems.length > 0 && (
                    <div style={styles.textItemsSection} className="mb-4">
                        <h5 className="mb-3">Krediteringsnoteringar</h5>
                        <Table style={styles.textItemsTable}>
                            <tbody>
                                {textItems.map((item, index) => (
                                    <tr key={`text-${index}`} style={styles.textItem}>
                                        <td style={styles.textItemTableCell}>{item.description}</td>
                                        <td style={{ ...styles.textItemTableCell, width: '50px' }} className="text-end">
                                            <Button
                                                variant="link"
                                                className="text-danger p-0"
                                                onClick={() => onDeleteItem(
                                                    items.findIndex(i => i === item)
                                                )}
                                            >
                                                <i className="fas fa-times"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}

                {/* Totals section */}
                <div style={styles.creditNoteSummary}>
                    <div className="row justify-content-end">
                        <div className="col-md-6">
                            <div style={styles.summaryCard}>
                                <div style={styles.summaryRow}>
                                    <span style={styles.label}>Delsumma (exkl. moms):</span>
                                    <span style={styles.value}>{formatCurrency(totals.subtotal)}</span>
                                </div>

                                {Object.entries(totals.taxBreakdown).map(([rate, data]) => (
                                    <div style={styles.summaryRow} key={rate}>
                                        <span style={styles.label}>Moms ({rate}%):</span>
                                        <span style={styles.value}>{formatCurrency(data.totalTax)}</span>
                                    </div>
                                ))}

                                {!disableRounding && Math.abs(totals.rounding) > 0.001 && (
                                    <div style={styles.summaryRow}>
                                        <span style={styles.label}>Avrundning:</span>
                                        <span style={styles.value}>{formatCurrency(totals.rounding)}</span>
                                    </div>
                                )}

                                <div style={{ ...styles.summaryRow, ...styles.summaryRowTotal }}>
                                    <span style={styles.label}>Att kreditera (inkl. moms):</span>
                                    <span style={styles.value}>
                                        {formatCurrency(disableRounding ? totals.grandTotal : totals.roundedTotal)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>

            <TextItemModal
                show={showTextModal}
                handleClose={() => setShowTextModal(false)}
                handleSave={onAddTextItem}
            />
        </Card>
    );
};

export default CreditNoteItemsCard;