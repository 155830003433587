import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../../../services/AxiosInstance';
import swal from 'sweetalert';
import { getApiUrl } from '../../../../../utils/ApiUtils';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Företagsnamn är obligatoriskt'),
  orgnumber: Yup.string().required('Org-nummer är obligatoriskt'),
  vatnumber: Yup.string().nullable(),
  address: Yup.string().required('Adress är obligatoriskt'),
  city: Yup.string().required('Stad är obligatorisk'),
  postalcode: Yup.string().required('Postnummer är obligatoriskt'),
  country: Yup.string().required('Land är obligatoriskt'),
  phonenumber: Yup.string().nullable()
});

const NewSupplierModal = ({ show, handleClose, handleSave, handleUpdate, initialSupplierData }) => {
  const isNewSupplier = initialSupplierData === null;

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const formik = useFormik({
    initialValues: {
      supplier_id: '',
      orgnumber: '',
      vatnumber: '',
      name: '',
      address: '',
      city: '',
      postalcode: '',
      country: '',
      phonenumber: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isNewSupplier) {
        handleSave(values);
      } else {
        handleUpdate(values);
      }
      handleClose();
    }
  });

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = formik;

  useEffect(() => {
    if (show) {
      if (initialSupplierData) {
        resetForm({
          values: {
            supplier_id: initialSupplierData.supplier_id,
            orgnumber: initialSupplierData.orgnumber || '',
            vatnumber: initialSupplierData.vatnumber || '',
            name: initialSupplierData.name || '',
            address: initialSupplierData.postadress || '',
            city: initialSupplierData.city || '',
            postalcode: initialSupplierData.postnumber || '',
            country: initialSupplierData.country || '',
            phonenumber: initialSupplierData.phone || ''
          }
        });
      } else {
        resetForm({
          values: {
            supplier_id: '',
            orgnumber: '',
            vatnumber: '',
            name: '',
            address: '',
            city: '',
            postalcode: '',
            country: '',
            phonenumber: ''
          }
        });
      }
      setCompanies([]);
      setSelectedCompany(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, initialSupplierData]);

  const searchCompany = async (inputValue) => {
    if (!inputValue) return;

    try {
      const searchCompanyApiUrl = getApiUrl('generic/search-company');
      const response = await axios.get(searchCompanyApiUrl, { params: { query: inputValue } });

      setCompanies(
        response.data.hits.map((company) => (
          {
            label: `${company.document.names[0].nameOrIdentifier} (${company.document.registrationNumber})`,
            value: company,
          }))
      );
    } catch (err) {
      console.error('Error fetching company data:', err.message);
      swal(
        'Fel',
        'Kunde inte hämta företagsinformation, försök igen senare eller ange informationen manuellt.',
        'error'
      );
    }
  };

  const handleCompanySelect = (selectedOption) => {
    const company = selectedOption.value;

    console.log('Selected company:', company);

    setSelectedCompany(company);


    const selectedAddress = company.document.mostRecentMailingAddress ||
      company.document.mostRecentVisitingAddress ||
      company.document.mostRecentRegisteredAddress;

    //const visitingAddress = company.document.addresses?.find(addr => addr.addressType === 'visitingAddress');
    //const mailAddress = company.document.addresses?.find(addr => addr.addressType === 'mailAddress');
    //const selectedAddress = visitingAddress || mailAddress || {};

    const phoneNumber = company.document.phoneNumbers?.length > 0 ? company.document.phoneNumbers[0].e164PhoneNumber : '-';

    // loop through the names array to find the name with companyNameingType  legalName
    const nameObject = company.document.names.find(name => name.companyNamingType === 'legalName');
    const name = nameObject ? nameObject.nameOrIdentifier : '-';

    setFieldValue('orgnumber', company.document.registrationNumber || '');
    setFieldValue('vatnumber', company.document.isRegisteredForVAT ? `SE${company.document.registrationNumber}01` : '-');
    setFieldValue('name', name);
    setFieldValue('address', selectedAddress.streetAddress || '-');
    setFieldValue('city', selectedAddress.city || '-');
    setFieldValue('postalcode', selectedAddress.postalCode || '-');
    setFieldValue('country', company.document.iso3166CountryCode || '-');
    setFieldValue('phonenumber', phoneNumber);
  };

  const handleCloseClick = () => {
    handleClose();
  };

  return (
    <Modal className='fade bd-example-modal-lg' size='lg' show={show} onHide={handleClose}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Ny leverantör</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="basic-form">
              <div className='col-xl-12'>
                <div className="col-xl-4 mb-5">
                  Fyll i information om leverantören.
                </div>
                {companies.length > 0 && (
                  <>
                    <label>{companies.length} resultat hittade</label>
                    <Select
                      options={companies}
                      onChange={handleCompanySelect}
                      placeholder="Välj ett företag"
                      value={selectedCompany ? { label: selectedCompany.document.names[0].nameOrIdentifier, value: selectedCompany } : null}
                    />
                  </>
                )}

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Företagsnamn *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && <div className="text-danger">{errors.name}</div>}
                  </div>
                  <div className="form-group mb-3 col-md-6 d-flex align-items-end">
                    <Button variant="link" onClick={() => searchCompany(values.name)}>Hämta företagsinformation</Button>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Org-nummer *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="orgnumber"
                      value={values.orgnumber}
                      onChange={handleChange}
                    />
                    {touched.orgnumber && errors.orgnumber && <div className="text-danger">{errors.orgnumber}</div>}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Vat-nummer</label>
                    <input
                      type="text"
                      className="form-control"
                      name="vatnumber"
                      value={values.vatnumber}
                      onChange={handleChange}
                    />
                    {touched.vatnumber && errors.vatnumber && <div className="text-danger">{errors.vatnumber}</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Adress *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                    />
                    {touched.address && errors.address && <div className="text-danger">{errors.address}</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-3">
                    <label>Stad *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                    />
                    {touched.city && errors.city && <div className="text-danger">{errors.city}</div>}
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label>Postnummer *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalcode"
                      value={values.postalcode}
                      onChange={handleChange}
                    />
                    {touched.postalcode && errors.postalcode && <div className="text-danger">{errors.postalcode}</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Land *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                    />
                    {touched.country && errors.country && <div className="text-danger">{errors.country}</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Telefonnummer</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phonenumber"
                      value={values.phonenumber}
                      onChange={handleChange}
                    />
                    {touched.phonenumber && errors.phonenumber && <div className="text-danger">{errors.phonenumber}</div>}
                  </div>
                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseClick}>
              Avbryt
            </Button>
            <Button variant="primary" type="submit">
              Spara
            </Button>
          </Modal.Footer>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default NewSupplierModal;
