import React from 'react';
import { Table, Button } from 'react-bootstrap';
import QuoteTableRow from './QuotesTableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const QuoteItemsTable = ({
  items,
  articles,
  handleAddItem,
  handleCreateArticle,
  handleDeleteItem,
  updateRow,
  openCreateNewArticleModal,
}) => {
  const handleAddNewRow = () => {
    const newItem = {
      quantity: 1,
      unitprice: 0,
      tax_rate: '25%',
      unit: 'Piece',
      type: 'Goods',
      total_amount: 0,
      isNew: true
    };
    handleAddItem(newItem);
  };

  return (
    <div className="quote-items-table">
      {items.length > 0 ? (
        <Table responsive className="table dataTable table-hover display">
          <thead>
            <tr>
              <th></th>
              <th>Beskrivning</th>
              <th>Typ</th>
              <th>Antal</th>
              <th>Enhet</th>
              <th>Á-Pris</th>
              <th>Moms</th>
              <th>Summa</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <QuoteTableRow
                key={item.item_id || `new-${index}`}
                index={index}
                item={item}
                articles={articles}
                updateRow={updateRow}
                deleteRow={handleDeleteItem}
                handleCreateArticle={handleCreateArticle}
                openCreateNewArticleModal={openCreateNewArticleModal}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="empty-state">
          <p>Lägg till rader i din offert</p>
        </div>
      )}

      <div className="table-actions">
        <Button
          variant="outline-primary"
          onClick={handleAddNewRow}
          className="add-row-button"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Lägg till ny rad
        </Button>
      </div>

      <style jsx>{`
        .quote-items-table {
          margin: 20px 0;
        }
        .empty-state {
          text-align: center;
          padding: 20px;
          background: #f8f9fa;
          border-radius: 4px;
          margin: 20px 0;
        }
        .table-actions {
          display: flex;
          justify-content: center;
          margin: 20px 0;
        }
        .add-row-button {
          min-width: 150px;
        }
      `}</style>
    </div>
  );
};

export default QuoteItemsTable;