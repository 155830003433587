import React, { useState, useCallback, useEffect } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import { Card, Button, Dropdown, Form } from 'react-bootstrap';
import axios from '../../../services/AxiosInstance';

import { InvoiceTotalsWidget, InvoiceAgingWidget, TrendWidget } from './Widgets/InvoiceAnalytics';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTimes,
  faChartLine,
  faFileInvoiceDollar,
  faSpinner,
  faCog,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend
} from 'recharts';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { getApiUrl } from "../../../utils/ApiUtils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const defaultProps = {
  className: "layout",
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  rowHeight: 20
};

// Import your widget components from the provided code
const UnpaidInvoicesWidget = ({ companyId }) => {
  // Using dummy data since we don't have the API here
  const dummyData = {
    current: { count: 42, totalAmount: 150000 },
    historical: Array.from({ length: 7 }, (_, i) => ({
      date: new Date(Date.now() - i * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      amount: Math.floor(Math.random() * 200000),
      count: Math.floor(Math.random() * 50)
    }))
  };

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-primary" />
          <h3 className="h5 mb-0">Unpaid Invoices</h3>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="light" size="sm">
            <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
            Month
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Week</Dropdown.Item>
            <Dropdown.Item>Month</Dropdown.Item>
            <Dropdown.Item>Quarter</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-6">
          <div className="bg-light p-3 rounded">
            <div className="text-muted small">Count</div>
            <div className="h3 mb-0">{dummyData.current.count}</div>
          </div>
        </div>
        <div className="col-6">
          <div className="bg-light p-3 rounded">
            <div className="text-muted small">Total Amount</div>
            <div className="h3 mb-0">
              {new Intl.NumberFormat('sv-SE', {
                style: 'currency',
                currency: 'SEK'
              }).format(dummyData.current.totalAmount)}
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: '200px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={dummyData.historical}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="amount"
              stroke="#8884d8"
              name="Amount"
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="count"
              stroke="#82ca9d"
              name="Count"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const TopSellingArticlesWidget = ({ companyId }) => {
  // Dummy data for demonstration
  const dummyData = {
    articles: [
      { id: 1, name: 'Product A', totalSold: 150, totalRevenue: 45000, articleNumber: 'A001' },
      { id: 2, name: 'Product B', totalSold: 120, totalRevenue: 36000, articleNumber: 'B002' },
      { id: 3, name: 'Product C', totalSold: 90, totalRevenue: 27000, articleNumber: 'C003' }
    ]
  };

  const [showConfig, setShowConfig] = useState(false);
  const [config, setConfig] = useState({
    showRevenue: true,
    sortBy: 'quantity',
    timeRange: 'month'
  });

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faChartLine} className="text-success" />
          <h3 className="h5 mb-0">Top Selling Articles</h3>
        </div>
        <Button
          variant="light"
          size="sm"
          onClick={() => setShowConfig(!showConfig)}
        >
          <FontAwesomeIcon icon={faCog} />
        </Button>
      </div>

      <div style={{ height: '200px' }} className="mb-3">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={dummyData.articles}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalSold" fill="#8884d8" name="Quantity Sold" />
            {config.showRevenue && (
              <Bar dataKey="totalRevenue" fill="#82ca9d" name="Revenue" />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="d-flex flex-column gap-3">
        {dummyData.articles.map((article, index) => (
          <div
            key={article.id}
            className="d-flex align-items-center justify-content-between bg-light p-3 rounded"
          >
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center justify-content-center rounded-circle bg-primary text-white"
                style={{ width: '24px', height: '24px', fontSize: '14px' }}>
                {index + 1}
              </div>
              <div>
                <div className="fw-medium">{article.name}</div>
                <div className="small text-muted">
                  {article.articleNumber}
                </div>
              </div>
            </div>
            <div className="text-end">
              <div className="fw-medium">
                {article.totalSold} sold
              </div>
              {config.showRevenue && (
                <div className="small text-muted">
                  {new Intl.NumberFormat('sv-SE', {
                    style: 'currency',
                    currency: 'SEK'
                  }).format(article.totalRevenue)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Dashboard = () => {

  const { companyId } = useParams();

  console.log("Insde widget: ", companyId);

  // State for widget layout management
  const [items, setItems] = useState([
    {
      i: 'invoice-totals',
      x: 0,
      y: 0,
      w: 4,  // Adjusted width to fit better in 12-column grid
      h: 8,  // Increased height for better visualization
      type: 'invoiceTotals'
    },
    {
      i: 'invoice-aging',
      x: 4,
      y: 0,
      w: 4,
      h: 8,
      type: 'invoiceAging'
    },
    {
      i: 'invoice-trends',
      x: 8,
      y: 0,
      w: 4,
      h: 8,
      type: 'invoiceTrends'
    }
  ]);

  // State for analytics data from API
  const [analyticsData, setAnalyticsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCounter, setNewCounter] = useState(3); // Start at 3 since we have 3 initial widgets

  // Fetch data from our API
  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const invoiceAnalyticsApi = getApiUrl(`${companyId}/dashboard/widgets/invoice-analytics?type=customer`);
        const response = await axios.get(invoiceAnalyticsApi, { withCredentials: true });

        console.log("Response: ", response);

        if (response.status !== 200) {
          console.log("Error: ", response);
          throw new Error('Failed to fetch analytics data');
        }

        const data = await response.data;

        setAnalyticsData(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching analytics data:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchAnalytics();
    // Set up polling every 5 minutes to keep data fresh
    const interval = setInterval(fetchAnalytics, 300000);
    return () => clearInterval(interval);
  }, []);

  // const createElement = useCallback((el) => {
  //   const Component = el.type === 'unpaidInvoices' ? UnpaidInvoicesWidget : TopSellingArticlesWidget;

  //   return (
  //     <div key={el.i} data-grid={el} className="widget-container">
  //       <div className="widget-content">
  //         <Button
  //           variant="link"
  //           className="remove-button"
  //           onClick={() => onRemoveItem(el.i)}
  //         >
  //           <FontAwesomeIcon icon={faTimes} />
  //         </Button>
  //         {/* We wrap the component in a div with padding to create space for the close button */}
  //         <div className="widget-inner-content">
  //           <Component companyId="123" />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }, []);

  // const createElement = useCallback((el) => {
  //   // This function determines which component to render based on the type
  //   const getComponent = (type) => {
  //     switch (type) {
  //       case 'unpaidInvoices':
  //         return UnpaidInvoicesWidget;
  //       case 'topArticles':
  //         return TopSellingArticlesWidget;
  //       case 'invoiceTotals':
  //         return InvoiceTotalsWidget;
  //       case 'invoiceAging':
  //         return InvoiceAgingWidget;
  //       case 'invoiceTrends':
  //         return TrendWidget;
  //       default:
  //         return UnpaidInvoicesWidget;
  //     }
  //   };

  //   const Component = getComponent(el.type);

  //   return (
  //     <div key={el.i} data-grid={el} className="widget-container">
  //       <div className="widget-content">
  //         <Button
  //           variant="link"
  //           className="remove-button"
  //           onClick={() => onRemoveItem(el.i)}
  //         >
  //           <FontAwesomeIcon icon={faTimes} />
  //         </Button>
  //         <div className="widget-inner-content">
  //           {['invoiceTotals', 'invoiceAging', 'invoiceTrends'].includes(el.type) ? (
  //             <Component data={analyticsData} type="customer" isLoading={false} />
  //           ) : (
  //             <Component companyId="123" />
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }, [analyticsData]);

  // const onAddItem = useCallback(() => {
  //   const widgetType = newCounter % 2 === 0 ? 'unpaidInvoices' : 'topArticles';
  //   setItems(items => [...items, {
  //     i: `n${newCounter}`,
  //     x: (items.length * 6) % 12,
  //     y: Infinity,
  //     w: 6,
  //     h: 4,
  //     type: widgetType
  //   }]);
  //   setNewCounter(c => c + 1);
  // }, [newCounter]);

  // const onRemoveItem = useCallback((itemId) => {
  //   setItems(items => items.filter(item => item.i !== itemId));
  // }, []);

  // const onAddItem = useCallback(() => {
  //   // Define all available widget types
  //   const widgetTypes = [
  //     { type: 'unpaidInvoices', w: 3, h: 5 },
  //     { type: 'topArticles', w: 3, h: 5 },
  //     { type: 'invoiceTotals', w: 3, h: 5 },
  //     { type: 'invoiceAging', w: 3, h: 5 },
  //     { type: 'invoiceTrends', w: 6, h: 5 }  // Wider for the trends widget
  //   ];

  //   // Select widget type based on counter
  //   const widgetConfig = widgetTypes[newCounter % widgetTypes.length];

  //   setItems(items => [...items, {
  //     i: `n${newCounter}`,
  //     x: (items.length * 3) % 12,  // Position horizontally
  //     y: Infinity,  // Add to bottom
  //     w: widgetConfig.w,
  //     h: widgetConfig.h,
  //     type: widgetConfig.type
  //   }]);
  //   setNewCounter(c => c + 1);
  // }, [newCounter]);

  const createElement = useCallback((el) => {
    // Get the appropriate component based on widget type
    const getComponent = (type) => {
      switch (type) {
        case 'invoiceTotals':
          return InvoiceTotalsWidget;
        case 'invoiceAging':
          return InvoiceAgingWidget;
        case 'invoiceTrends':
          return TrendWidget;
        default:
          return null;
      }
    };

    const Component = getComponent(el.type);

    return (
      <div key={el.i} data-grid={el} className="widget-container">
        <div className="widget-content">
          <Button
            variant="link"
            className="remove-button, cancelSelectorName"
            onClick={() => onRemoveItem(el.i)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div className="widget-inner-content">
            <Component
              data={analyticsData}
              type="customer"
              isLoading={isLoading}
              onCreateInvoice={() => alert('Create invoice clicked')}
            />
          </div>
        </div>
      </div>
    );
  }, [analyticsData, isLoading]);

  const onAddItem = useCallback(() => {
    // Define available widget types and their configurations
    const widgetTypes = [
      { type: 'invoiceTotals', w: 4, h: 8 },
      { type: 'invoiceAging', w: 4, h: 8 },
      { type: 'invoiceTrends', w: 4, h: 8 }
    ];

    // Select which widget type to add next
    const widgetConfig = widgetTypes[newCounter % widgetTypes.length];

    setItems(items => [...items, {
      i: `widget-${newCounter}`,
      x: (items.length * widgetConfig.w) % 12, // Position horizontally
      y: Infinity, // Add to bottom
      w: widgetConfig.w,
      h: widgetConfig.h,
      type: widgetConfig.type
    }]);

    setNewCounter(c => c + 1);
  }, [newCounter]);

  const onRemoveItem = useCallback((itemId) => {
    setItems(items => items.filter(item => item.i !== itemId));
  }, []);

  // If there's an error fetching data, display it
  if (error) {
    return (
      <div className="p-4 text-danger">
        Error loading dashboard data: {error}
      </div>
    );
  }

  return (
    <div className="p-4">
      <Button
        onClick={onAddItem}
        variant="primary"
        className="mb-4"
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Add Widget
      </Button>

      <ResponsiveReactGridLayout
        draggableCancel=".cancelSelectorName"
        onLayoutChange={(layout) => console.log('layout changed', layout)}
        {...defaultProps}
      >
        {items.map(createElement)}
      </ResponsiveReactGridLayout>

      <style jsx global>{`
        /* Base widget container styling */
        .widget-container {
          transition: all 200ms ease;
          transition-property: left, top, width, height;
        }
        
        /* The main widget content area with dashed border */
        .widget-content {
          height: 100%;
          border: 2px dashed #dee2e6;
          border-radius: 8px;
          background: white;
          position: relative;
          transition: border-color 0.2s ease;
        }

        /* Style changes when dragging to provide visual feedback */
        .react-grid-item.react-grid-placeholder > .widget-content {
          border-color: #4A90E2;
          background: rgba(74, 144, 226, 0.1);
        }

        /* Inner content wrapper to provide padding */
        .widget-inner-content {
          padding: 40px 16px 16px 16px; /* Extra top padding for the close button */
          height: 100%;
          overflow: auto;
        }
        
        .react-grid-item.cssTransforms {
          transition-property: transform, width, height;
        }

        /* Close button positioning and styling */
        .remove-button {
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 10;
          padding: 4px 8px;
          color: #666;
          background: white;
          border-radius: 4px;
          opacity: 0.7;
          transition: opacity 0.2s ease, color 0.2s ease;
        }

        .remove-button:hover {
          color: #dc3545;
          opacity: 1;
        }

        /* Resize handle styling */
        .react-resizable-handle {
          position: absolute;
          width: 20px;
          height: 20px;
          bottom: 0;
          right: 0;
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
          background-position: bottom right;
          padding: 0 3px 3px 0;
          background-repeat: no-repeat;
          background-origin: content-box;
          box-sizing: border-box;
          cursor: se-resize;
          z-index: 10;
        }

        /* Additional widget styling to ensure proper spacing */
        .widget-content .p-3 {
          padding-top: 0 !important; /* Override the widget's internal padding */
        }

        /* Ensure charts and content are properly contained */
        .recharts-wrapper {
          max-width: 100%;
        }
      `}</style>
    </div>
  );
};

export default Dashboard;