/*import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from '../../../../services/AxiosInstance';
import FileDropZone from '../../../components/SharedComponents/FileDropZone.js';
import ProductsAndArticlesCard from './QuoteProductAndArticleCard.js';
import QuoteDeliveryOptions from './QuoteDeliveryOptions';
import DatePicker from 'react-datepicker';
import { getApiUrl } from '../../../../utils/ApiUtils.js';
import { formatISO, parseISO, differenceInDays, addDays } from 'date-fns';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../layouts/PageTitle';
import NewArticleModal from '../Factoring/Articles/NewArticleModal.js';
import Select from 'react-select';
import { isEqual } from 'lodash';

const CustomerDetails = React.memo(({ customer }) => {
  if (!customer) return null;

  const sectionStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '5px',
  };

  // Determine address (prefer billing if exists)
  let billingAddress = null;
  if (customer.addresses && customer.addresses.length > 0) {
    billingAddress = customer.addresses.find(a => a.address_type === 'billing') || customer.addresses[0];
  }

  const mainContact = customer.contacts?.[0];

  return (
    <div className="row mt-4">
      {customer.type === 'company' ? (
        <>
          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faBuilding} /> Företagsdetaljer</h5>
              <p>
                {customer.name}<br />
                Org. Nr.: {customer.organization_number}<br />
                {customer.vat_number && `VAT Nr.: ${customer.vat_number}`}
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faMapMarkerAlt} /> Adress</h5>
              {billingAddress ? (
                <p>
                  {billingAddress.street1}<br />
                  {billingAddress.postal_code} {billingAddress.city}<br />
                  {billingAddress.country}
                </p>
              ) : (
                <p>Ingen adress angiven</p>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faUser} /> Kontaktperson</h5>
              {mainContact ? (
                <p>
                  {mainContact.name}<br />
                  {mainContact.email}<br />
                  {mainContact.phone}
                </p>
              ) : (
                <p>Ingen kontaktperson angiven</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-md-6">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faUser} /> Kunddetaljer</h5>
              <p>
                {customer.first_name} {customer.last_name}<br />
                {customer.email}<br />
                {customer.phone}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faMapMarkerAlt} /> Adress</h5>
              {billingAddress ? (
                <p>
                  {billingAddress.street1}<br />
                  {billingAddress.postal_code} {billingAddress.city}<br />
                  {billingAddress.country}
                </p>
              ) : (
                <p>Ingen adress angiven</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});


const QuoteForm = () => {
  const { companyId, quoteId } = useParams();
  const history = useHistory();
  const isEditMode = Boolean(quoteId);

  // Quote data state
  const [quoteData, setQuoteData] = useState({
    customerid: '',
    quote_date: new Date(),
    valid_until: addDays(new Date(), 30),
    currency: 'SEK',
    status: 'Draft',
    disable_rounding: false,
    items: [],
    attachments: []
  });

  // Other state management
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNewArticleModal, setShowNewArticleModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [companySettings, setCompanySettings] = useState(null);

  // Change tracking
  const [initialQuoteData, setInitialQuoteData] = useState(null);
  const [initialItems, setInitialItems] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [deliveryOptionValid, setDeliveryOptionValid] = useState(false);

  useEffect(() => {
    fetchArticles();
    fetchCompanySettings();
    if (isEditMode) {
      fetchQuoteDetails();
    } else {
      setInitialQuoteData(quoteData);
      setInitialItems(items);
    }
  }, []);

  const fetchCompanySettings = async () => {
    try {
      const { data } = await axios.post(
        getApiUrl(`${companyId}/settings/bulk`),
        { keys: ['default_quote_validity', 'default_currency'] },
        { withCredentials: true }
      );

      console.log('Company settings:', data);

      setCompanySettings(data.settings);

      if (!isEditMode) {
        const validityDays = parseInt(data.settings.default_quote_validity || '30', 10);
        setQuoteData(prev => ({
          ...prev,
          valid_until: addDays(prev.quote_date, validityDays),
          currency: data.settings.default_currency || 'SEK'
        }));
      }
    } catch (error) {
      swal('Error', 'Error fetching company settings', 'error');
    }
  };

  const fetchQuoteDetails = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/quotes/${quoteId}`),
        { withCredentials: true }
      );

      if (response.data.success) {
        const { quote } = response.data;

        console.log('Quote details:', quote);

        setQuoteData({
          ...quote,
          quote_date: parseISO(quote.quote_date),
          valid_until: parseISO(quote.valid_until)
        });
        setItems(quote.items || []);
        setAttachments(quote.attachments || []);
        setInitialQuoteData(quote);
        setInitialItems(quote.items || []);

        if (quote.customer_id) {
          const customerDetails = await fetchCustomerDetails(quote.customer_id);
          setSelectedCustomer(customerDetails);
        }
      }
    } catch (error) {
      swal('Error', 'Failed to fetch quote details', 'error');
    }
  };

  const fetchCustomerDetails = async (customerId) => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/sales/customers/${customerId}`),
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      swal('Error', 'Failed to fetch customer details', 'error');
      return null;
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/articles`),
        { withCredentials: true }
      );
      setArticles(response.data.data);
    } catch (error) {
      swal('Error', 'Failed to fetch articles', 'error');
    }
  };

  const handleCustomerSelect = async (selectedOption) => {
    if (!selectedOption) {
      setSelectedCustomer(null);
      setQuoteData(prev => ({ ...prev, customerid: '' }));
      return;
    }

    const customerDetails = await fetchCustomerDetails(selectedOption.value);
    if (customerDetails) {
      setSelectedCustomer(customerDetails);
      setQuoteData(prev => ({
        ...prev,
        customerid: customerDetails.id,
        currency: customerDetails.preferred_currency || prev.currency
      }));
    }
  };

  const handleDateChange = (date, field) => {
    setQuoteData(prev => ({ ...prev, [field]: date }));
  };

  const handleAddQuoteItem = (item) => {
    setItems(prev => [...prev, item]);
  };

  const handleUpdateQuoteItem = (index, updatedItem) => {
    setItems(prev => {
      const newItems = [...prev];
      newItems[index] = updatedItem;
      return newItems;
    });
  };

  const handleDeleteQuoteItem = (index) => {
    setItems(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleRoundingChange = (checked) => {
    setQuoteData(prev => ({ ...prev, disable_rounding: !checked }));
  };

  const handleNewArticle = (articleData) => {
    setSelectedArticle(articleData);
    setShowNewArticleModal(true);
  };

  const handleSaveArticle = async (newArticle) => {
    try {
      const response = await axios.post(
        getApiUrl(`${companyId}/articles`),
        newArticle,
        { withCredentials: true }
      );
      if (response.data.success) {
        await fetchArticles();
        swal('Success', 'Article saved successfully', 'success');
      }
    } catch (error) {
      swal('Error', 'Failed to save article', 'error');
    }
    setShowNewArticleModal(false);
  };

  const handleFileUpload = async (file) => {
    if (!isEditMode || !quoteId) {
      swal('Warning', 'Please save the quote before uploading attachments.', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('quote_id', quoteId);

    try {
      await axios.post(
        getApiUrl(`${companyId}/quotes/${quoteId}/attachments`),
        formData,
        {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      fetchQuoteDetails();
    } catch (error) {
      swal('Error', 'Failed to upload file', 'error');
    }
  };

  const handleSaveDraft = async () => {
    if (!quoteData.customerid) {
      swal('Error', 'Please select a customer', 'error');
      return null;
    }

    setLoading(true);
    try {
      const dataToSend = {
        customerid: quoteData.customerid,
        status: 'Draft',
        currency: quoteData.currency,
        quote_date: formatISO(quoteData.quote_date, { representation: 'date' }),
        valid_until: formatISO(quoteData.valid_until, { representation: 'date' }),
        disable_rounding: quoteData.disable_rounding,
        items: items.map(item => ({
          ...item,
          item_id: item.item_id && !item.isNew ? item.item_id : undefined
        }))
      };

      let response;
      if (isEditMode) {
        response = await axios.put(
          getApiUrl(`${companyId}/quotes/${quoteId}`),
          dataToSend,
          { withCredentials: true }
        );
      } else {
        response = await axios.post(
          getApiUrl(`${companyId}/quotes`),
          dataToSend,
          { withCredentials: true }
        );
      }

      if (response.data.success) {
        setInitialQuoteData(quoteData);
        setInitialItems(items);
        setHasUnsavedChanges(false);
        swal('Success', 'Quote saved successfully', 'success');
        return response.data.quoteId || quoteId;
      }
    } catch (error) {
      swal('Error', 'Failed to save quote', 'error');
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handlePublish = async () => {
    if (!deliveryOption.method) {
      swal('Warning', 'Please select a delivery option', 'warning');
      return;
    }

    if (!deliveryOptionValid) {
      swal('Warning', 'Please correct the delivery option errors', 'warning');
      return;
    }

    if (hasUnsavedChanges) {
      const confirmSave = await swal({
        title: 'Unsaved Changes',
        text: 'Do you want to save before publishing?',
        icon: 'warning',
        buttons: ['Cancel', 'Save and Publish'],
      });

      if (confirmSave) {
        const savedId = await handleSaveDraft();
        if (savedId) {
          await publishQuote(savedId);
        }
      }
    } else {
      await publishQuote(quoteId);
    }
  };

  const publishQuote = async (id) => {
    try {
      const response = await axios.post(
        getApiUrl(`${companyId}/quotes/${id}/publish`),
        {
          method: deliveryOption.method,
          email: deliveryOption.email
        },
        {
          withCredentials: true,
          responseType: deliveryOption.method === 'download' ? 'blob' : 'json'
        }
      );

      if (deliveryOption.method === 'download' && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `quote-${id}.pdf`;
        link.click();
        window.URL.revokeObjectURL(url);
      }

      swal('Success', 'Quote published successfully', 'success');
      history.push(`/${companyId}/quotes`);
    } catch (error) {
      swal('Error', 'Failed to publish quote', 'error');
    }
  };

  // Navigation warning for unsaved changes
  useEffect(() => {
    if (initialQuoteData) {
      const quoteDataChanged = !isEqual(quoteData, initialQuoteData);
      const itemsChanged = !isEqual(items, initialItems);
      setHasUnsavedChanges(quoteDataChanged || itemsChanged);
    }
  }, [quoteData, items, initialQuoteData, initialItems]);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (hasUnsavedChanges) {
        // Show confirmation dialog
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    });

    return () => {
      unblock();
    };
  }, [hasUnsavedChanges, history]);

  // Add this to your QuoteForm component, before the return statement

  const searchCustomers = async (inputValue) => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/sales/customers`),
        { withCredentials: true }
      );

      let customers = response.data;

      // Filter customers based on search input
      if (inputValue) {
        const searchLower = inputValue.toLowerCase();
        customers = customers.filter(customer => {
          const name = customer.name?.toLowerCase() || '';
          const number = customer.number?.toLowerCase() || '';
          const orgNumber = customer.organization_number?.toLowerCase() || '';

          return name.includes(searchLower) ||
            number.includes(searchLower) ||
            orgNumber.includes(searchLower);
        });
      }

      // Format customers for select options
      return customers.map(customer => ({
        ...customer,
        label: `${customer.number || ''} - ${customer.name} ${customer.organization_number ? `(${customer.organization_number})` : ''}`,
        value: customer.id
      }));
    } catch (error) {
      console.error('Error searching customers:', error);
      swal('Error', 'Failed to fetch customers', 'error');
      return [];
    }
  };

  // ... (previous code remains the same until the return statement)

  return (
    <>
      <PageTitle activeMenu="Skapa Offert" motherMenu="Offerter" />
      <div className="row">
    
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Kund</h4>
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={selectedCustomer}
                getOptionLabel={option =>
                  `${option.number || ''} - ${option.name} ${option.organization_number ? `(${option.organization_number})` : ''}`
                }
                getOptionValue={option => option.id}
                loadOptions={searchCustomers}
                onChange={handleCustomerSelect}
                placeholder="Välj kund..."
                isDisabled={loading}
              />
              <CustomerDetails customer={selectedCustomer} />
            </div>
          </div>
        </div>

      
        <div className="col-xl-12">
          <ProductsAndArticlesCard
            quoteData={quoteData}
            items={items}
            articles={articles}
            handleAddQuoteItem={handleAddQuoteItem}
            handleDeleteQuoteItem={handleDeleteQuoteItem}
            handleUpdateQuoteItem={handleUpdateQuoteItem}
            handleCreateArticle={handleNewArticle}
            handleRoundingChange={handleRoundingChange}
            openCreateNewArticleModal={handleNewArticle}
            currency={quoteData.currency}
          />
        </div>

       
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Offertinformation</h4>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Offertdatum</label>
                  <DatePicker
                    className="form-control"
                    selected={quoteData.quote_date}
                    onChange={date => handleDateChange(date, 'quote_date')}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Giltigt till</label>
                  <DatePicker
                    className="form-control"
                    selected={quoteData.valid_until}
                    onChange={date => handleDateChange(date, 'valid_until')}
                    dateFormat="yyyy-MM-dd"
                    minDate={quoteData.quote_date}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Valuta</label>
                  <Select
                    value={{ value: quoteData.currency, label: quoteData.currency }}
                    options={[
                      { value: 'SEK', label: 'SEK' },
                      { value: 'EUR', label: 'EUR' },
                      { value: 'USD', label: 'USD' }
                    ]}
                    onChange={option => setQuoteData(prev => ({ ...prev, currency: option.value }))}
                    isDisabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

       
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Bilagor</h4>
              <FileDropZone
                setFiles={setAttachments}
                mainText="Dra och släpp filer här för att lägga till bilagor"
                minHeight="150px"
                autoUpload={true}
                handleUpload={handleFileUpload}
                predefinedFiles={attachments.map(attachment => ({
                  id: attachment.id,
                  name: attachment.filename,
                  size: attachment.size
                }))}
              />
            </div>
          </div>
        </div>

       
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Leveransalternativ</h4>
              <QuoteDeliveryOptions
                onDeliveryOptionChange={setDeliveryOption}
                onValidationChange={setDeliveryOptionValid}
              />
            </div>
          </div>
        </div>

       
        <div className="col-xl-12">
          <div className="d-flex justify-content-start gap-3 mt-4 mb-4">
            <button
              className="btn btn-primary"
              onClick={handlePublish}
              disabled={loading || !deliveryOptionValid}
            >
              {loading ? 'Sparar...' : 'Publicera offert'}
            </button>
            <button
              className="btn btn-light"
              onClick={handleSaveDraft}
              disabled={loading}
            >
              {loading ? 'Sparar...' : 'Spara utkast'}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => history.push(`/${companyId}/quotes`)}
              disabled={loading}
            >
              Avbryt
            </button>
          </div>
          {hasUnsavedChanges && (
            <div className="text-warning mb-4">
              Du har osparade ändringar
            </div>
          )}
        </div>
      </div>

  
      <NewArticleModal
        show={showNewArticleModal}
        handleClose={() => setShowNewArticleModal(false)}
        handleSave={handleSaveArticle}
        articleDetails={selectedArticle}
      />
    </>
  );
};

export default QuoteForm;
*/

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from '../../../../services/AxiosInstance';
import FileDropZone from '../../../components/SharedComponents/FileDropZone.js';
import ProductsAndArticlesCard from './QuoteProductAndArticleCard.js';
import QuoteDeliveryOptions from './QuoteDeliveryOptions';
import DatePicker from 'react-datepicker';
import { getApiUrl } from '../../../../utils/ApiUtils.js';
import { formatISO, parseISO, differenceInDays, addDays } from 'date-fns';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../layouts/PageTitle';
import NewArticleModal from '../Factoring/Articles/NewArticleModal.js';
import Select from 'react-select';
import { isEqual } from 'lodash';

// Keep the CustomerDetails component as is
const CustomerDetails = React.memo(({ customer }) => {
  if (!customer) return null;

  const sectionStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '5px',
  };

  // Determine address (prefer billing if exists)
  let billingAddress = null;
  if (customer.addresses && customer.addresses.length > 0) {
    billingAddress = customer.addresses.find(a => a.address_type === 'billing') || customer.addresses[0];
  }

  const mainContact = customer.contacts?.[0];

  return (
    <div className="row mt-4">
      {customer.type === 'company' ? (
        <>
          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faBuilding} /> Företagsdetaljer</h5>
              <p>
                {customer.name}<br />
                Org. Nr.: {customer.organization_number}<br />
                {customer.vat_number && `VAT Nr.: ${customer.vat_number}`}
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faMapMarkerAlt} /> Adress</h5>
              {billingAddress ? (
                <p>
                  {billingAddress.street1}<br />
                  {billingAddress.postal_code} {billingAddress.city}<br />
                  {billingAddress.country}
                </p>
              ) : (
                <p>Ingen adress angiven</p>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faUser} /> Kontaktperson</h5>
              {mainContact ? (
                <p>
                  {mainContact.name}<br />
                  {mainContact.email}<br />
                  {mainContact.phone}
                </p>
              ) : (
                <p>Ingen kontaktperson angiven</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-md-6">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faUser} /> Kunddetaljer</h5>
              <p>
                {customer.first_name} {customer.last_name}<br />
                {customer.email}<br />
                {customer.phone}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faMapMarkerAlt} /> Adress</h5>
              {billingAddress ? (
                <p>
                  {billingAddress.street1}<br />
                  {billingAddress.postal_code} {billingAddress.city}<br />
                  {billingAddress.country}
                </p>
              ) : (
                <p>Ingen adress angiven</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const QuoteForm = () => {
  const { companyId, quoteId } = useParams();
  const history = useHistory();
  const isEditMode = Boolean(quoteId);

  // Quote data state with improved initialization
  const [quoteData, setQuoteData] = useState({
    customerid: '',
    quote_date: new Date(),
    valid_until: addDays(new Date(), 30),
    currency: 'SEK',
    status: 'Draft',
    disable_rounding: false,
    items: [],
    attachments: []
  });

  // State management
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNewArticleModal, setShowNewArticleModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [companySettings, setCompanySettings] = useState(null);

  // Change tracking with improved initialization
  const [initialQuoteData, setInitialQuoteData] = useState(null);
  const [initialItems, setInitialItems] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [deliveryOptionValid, setDeliveryOptionValid] = useState(false);

  // Initial data loading
  useEffect(() => {
    fetchArticles();
    fetchCompanySettings();
    if (isEditMode) {
      fetchQuoteDetails();
    } else {
      // For new quotes, set initial state after setting default values
      const newQuoteInitialData = {
        ...quoteData,
        quote_date: new Date(),
        valid_until: addDays(new Date(), 30)
      };
      setInitialQuoteData(newQuoteInitialData);
      setInitialItems([]);
    }
  }, []);

  // Fetch company settings with improved error handling
  const fetchCompanySettings = async () => {
    try {
      const { data } = await axios.post(
        getApiUrl(`${companyId}/settings/bulk`),
        { keys: ['default_quote_validity', 'default_currency'] },
        { withCredentials: true }
      );

      setCompanySettings(data.settings);

      if (!isEditMode) {
        const validityDays = parseInt(data.settings.default_quote_validity || '30', 10);
        setQuoteData(prev => ({
          ...prev,
          valid_until: addDays(prev.quote_date, validityDays),
          currency: data.settings.default_currency || 'SEK'
        }));
      }
    } catch (error) {
      swal('Error', 'Error fetching company settings', 'error');
    }
  };

  // Improved quote details fetching
  const fetchQuoteDetails = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/quotes/${quoteId}`),
        { withCredentials: true }
      );

      if (response.data.success) {
        const { quote } = response.data;
        const parsedQuote = {
          ...quote,
          quote_date: parseISO(quote.quote_date),
          valid_until: parseISO(quote.valid_until)
        };

        setQuoteData(parsedQuote);
        setItems(quote.items || []);
        setAttachments(quote.attachments || []);

        // Set initial state with exact same data
        setInitialQuoteData(parsedQuote);
        setInitialItems(quote.items || []);

        if (quote.customer_id) {
          const customerDetails = await fetchCustomerDetails(quote.customer_id);
          setSelectedCustomer(customerDetails);
        }
      }
    } catch (error) {
      swal('Error', 'Failed to fetch quote details', 'error');
    }
  };

  // Improved customer details fetching
  const fetchCustomerDetails = async (customerId) => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/sales/customers/${customerId}`),
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      swal('Error', 'Failed to fetch customer details', 'error');
      return null;
    }
  };

  // Articles fetching
  const fetchArticles = async () => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/articles`),
        { withCredentials: true }
      );
      setArticles(response.data.data);
    } catch (error) {
      swal('Error', 'Failed to fetch articles', 'error');
    }
  };

  // Customer handling
  const handleCustomerSelect = async (selectedOption) => {
    if (!selectedOption) {
      setSelectedCustomer(null);
      setQuoteData(prev => ({ ...prev, customerid: '' }));
      return;
    }

    const customerDetails = await fetchCustomerDetails(selectedOption.value);
    if (customerDetails) {
      setSelectedCustomer(customerDetails);
      setQuoteData(prev => ({
        ...prev,
        customerid: customerDetails.id,
        currency: customerDetails.preferred_currency || prev.currency
      }));
    }
  };

  // Date handling
  const handleDateChange = (date, field) => {
    setQuoteData(prev => ({ ...prev, [field]: date }));
  };

  // Item management
  const handleAddQuoteItem = (item) => {
    setItems(prev => [...prev, item]);
  };

  const handleUpdateQuoteItem = (index, updatedItem) => {
    setItems(prev => {
      const newItems = [...prev];
      newItems[index] = updatedItem;
      return newItems;
    });
  };

  const handleDeleteQuoteItem = (index) => {
    setItems(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleRoundingChange = (checked) => {
    setQuoteData(prev => ({ ...prev, disable_rounding: !checked }));
  };

  // Article management
  const handleNewArticle = (articleData) => {
    setSelectedArticle(articleData);
    setShowNewArticleModal(true);
  };

  const handleSaveArticle = async (newArticle) => {
    try {
      const response = await axios.post(
        getApiUrl(`${companyId}/articles`),
        newArticle,
        { withCredentials: true }
      );
      if (response.data.success) {
        await fetchArticles();
        swal('Success', 'Article saved successfully', 'success');
      }
    } catch (error) {
      swal('Error', 'Failed to save article', 'error');
    }
    setShowNewArticleModal(false);
  };

  // File handling
  const handleFileUpload = async (file) => {
    if (!isEditMode || !quoteId) {
      swal('Warning', 'Please save the quote before uploading attachments.', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('quote_id', quoteId);

    try {
      await axios.post(
        getApiUrl(`${companyId}/quotes/${quoteId}/attachments`),
        formData,
        {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      fetchQuoteDetails();
    } catch (error) {
      swal('Error', 'Failed to upload file', 'error');
    }
  };

  // Draft saving
  const handleSaveDraft = async () => {
    console.log('Current quoteData:', quoteData);
    console.log('Selected customer:', selectedCustomer);

    if (!quoteData.customer_id) {
      swal('Error', 'Please select a customer', 'error');
      return null;
    }

    setLoading(true);
    try {
      const dataToSend = {
        customerid: selectedCustomer?.id || quoteData.customerid, // Use either ID
        status: 'Draft',
        currency: quoteData.currency,
        quote_date: formatISO(quoteData.quote_date, { representation: 'date' }),
        valid_until: formatISO(quoteData.valid_until, { representation: 'date' }),
        disable_rounding: quoteData.disable_rounding,
        items: items.map(item => ({
          ...item,
          item_id: item.item_id && !item.isNew ? item.item_id : undefined
        }))
      };

      let response;
      if (isEditMode) {
        response = await axios.put(
          getApiUrl(`${companyId}/quotes/${quoteId}`),
          dataToSend,
          { withCredentials: true }
        );
      } else {
        response = await axios.post(
          getApiUrl(`${companyId}/quotes`),
          dataToSend,
          { withCredentials: true }
        );
      }

      if (response.data.success) {
        setInitialQuoteData({
          ...quoteData,
          quote_date: quoteData.quote_date,
          valid_until: quoteData.valid_until
        });
        setInitialItems([...items]);
        setHasUnsavedChanges(false);
        swal('Success', 'Quote saved successfully', 'success');
        return response.data.quoteId || quoteId;
      }
    } catch (error) {
      swal('Error', 'Failed to save quote', 'error');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Publishing functionality
  const handlePublish = async () => {
    if (!deliveryOption.method) {
      swal('Warning', 'Please select a delivery option', 'warning');
      return;
    }

    if (!deliveryOptionValid) {
      swal('Warning', 'Please correct the delivery option errors', 'warning');
      return;
    }

    if (hasUnsavedChanges) {
      const confirmSave = await swal({
        title: 'Osparade ändringar',
        text: 'Vill du spara utkastet innan du publicerar offerten?',
        icon: 'warning',
        buttons: ['Avbryt', 'Spara och publicera'],
      });

      if (confirmSave) {
        const savedId = await handleSaveDraft();
        if (savedId) {
          await publishQuote(savedId);
        }
      }
    } else {
      await publishQuote(quoteId);
    }
  };

  const publishQuote = async (id) => {
    try {
      const response = await axios.post(
        getApiUrl(`${companyId}/quotes/${id}/publish`),
        {
          method: deliveryOption.method,
          email: deliveryOption.email
        },
        {
          withCredentials: true,
          responseType: deliveryOption.method === 'download' ? 'blob' : 'json'
        }
      );

      if (deliveryOption.method === 'download' && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `quote-${id}.pdf`;
        link.click();
        window.URL.revokeObjectURL(url);
      }

      swal('Success', 'Quote published successfully', 'success');
      history.push(`/${companyId}/quotes`);
    } catch (error) {
      swal('Error', 'Failed to publish quote', 'error');
    }
  };

  // Change detection and navigation warnings
  useEffect(() => {
    if (initialQuoteData && initialItems) {
      const quoteDataChanged = !isEqual(
        {
          ...quoteData,
          quote_date: quoteData.quote_date ? formatISO(quoteData.quote_date) : null,
          valid_until: quoteData.valid_until ? formatISO(quoteData.valid_until) : null
        },
        {
          ...initialQuoteData,
          quote_date: initialQuoteData.quote_date ? formatISO(initialQuoteData.quote_date) : null,
          valid_until: initialQuoteData.valid_until ? formatISO(initialQuoteData.valid_until) : null
        }
      );
      const itemsChanged = !isEqual(items, initialItems);
      setHasUnsavedChanges(quoteDataChanged || itemsChanged);
    }
  }, [quoteData, items, initialQuoteData, initialItems]);

  useEffect(() => {
    const unblock = history.block((promptLocation) => {
      if (hasUnsavedChanges) {
        swal({
          title: 'Du har osparade ändringar!',
          text: 'Vill du spara utkastet innan du lämnar sidan?',
          icon: 'warning',
          buttons: {
            cancel: 'Avbryt',
            discard: {
              text: 'Fortsätt utan att spara',
              value: 'discard',
            },
            save: {
              text: 'Spara utkast',
              value: 'save',
            },
          },
        }).then((value) => {
          switch (value) {
            case 'save':
              handleSaveDraft()
                .then(() => {
                  unblock();
                  history.push(promptLocation.pathname);
                })
                .catch(() => {
                  swal('Error', 'Failed to save draft. Navigation cancelled.', 'error');
                });
              break;
            case 'discard':
              setHasUnsavedChanges(false);
              unblock();
              history.push(promptLocation.pathname);
              break;
            default:
              break;
          }
        });
        return false;
      }
      return true;
    });

    return () => {
      if (unblock) {
        unblock();
      }
    };
  }, [hasUnsavedChanges, history, handleSaveDraft]);


  // Customer search functionality
  const searchCustomers = async (inputValue) => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/sales/customers`),
        { withCredentials: true }
      );

      let customers = response.data;

      if (inputValue) {
        const searchLower = inputValue.toLowerCase();
        customers = customers.filter(customer => {
          const name = customer.name?.toLowerCase() || '';
          const number = customer.number?.toString().toLowerCase() || '';
          const orgNumber = customer.organization_number?.toLowerCase() || '';

          return name.includes(searchLower) ||
            number.includes(searchLower) ||
            orgNumber.includes(searchLower);
        });
      }

      return customers.map(customer => ({
        ...customer,
        label: `${customer.number || ''} - ${customer.name} ${customer.organization_number ? `(${customer.organization_number})` : ''}`,
        value: customer.id
      }));
    } catch (error) {
      console.error('Error searching customers:', error);
      swal('Error', 'Failed to fetch customers', 'error');
      return [];
    }
  };

  return (
    <>
      <PageTitle activeMenu="Skapa Offert" motherMenu="Offerter" />
      <div className="row">
        {/* Customer Selection */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Kund</h4>
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={selectedCustomer}
                getOptionLabel={option =>
                  `${option.number || ''} - ${option.name} ${option.organization_number ? `(${option.organization_number})` : ''}`
                }
                getOptionValue={option => option.id}
                loadOptions={searchCustomers}
                onChange={handleCustomerSelect}
                placeholder="Välj kund..."
                isDisabled={loading}
              />
              <CustomerDetails customer={selectedCustomer} />
            </div>
          </div>
        </div>

        {/* Products and Articles */}
        <div className="col-xl-12">
          <ProductsAndArticlesCard
            quoteData={quoteData}
            items={items}
            articles={articles}
            handleAddQuoteItem={handleAddQuoteItem}
            handleDeleteQuoteItem={handleDeleteQuoteItem}
            handleUpdateQuoteItem={handleUpdateQuoteItem}
            handleCreateArticle={handleNewArticle}
            handleRoundingChange={handleRoundingChange}
            openCreateNewArticleModal={handleNewArticle}
            currency={quoteData.currency}
          />
        </div>

        {/* Quote Details */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Offertinformation</h4>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Offertdatum</label>
                  <DatePicker
                    className="form-control"
                    selected={quoteData.quote_date}
                    onChange={date => handleDateChange(date, 'quote_date')}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Giltigt till</label>
                  <DatePicker
                    className="form-control"
                    selected={quoteData.valid_until}
                    onChange={date => handleDateChange(date, 'valid_until')}
                    dateFormat="yyyy-MM-dd"
                    minDate={quoteData.quote_date}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Valuta</label>
                  <Select
                    value={{ value: quoteData.currency, label: quoteData.currency }}
                    options={[
                      { value: 'SEK', label: 'SEK' },
                      { value: 'EUR', label: 'EUR' },
                      { value: 'USD', label: 'USD' }
                    ]}
                    onChange={option => setQuoteData(prev => ({ ...prev, currency: option.value }))}
                    isDisabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Attachments */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Bilagor</h4>
              <FileDropZone
                setFiles={setAttachments}
                mainText="Dra och släpp filer här för att lägga till bilagor"
                minHeight="150px"
                autoUpload={true}
                handleUpload={handleFileUpload}
                predefinedFiles={attachments.map(attachment => ({
                  id: attachment.id,
                  name: attachment.filename,
                  size: attachment.size
                }))}
              />
            </div>
          </div>
        </div>

        {/* Delivery Options */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Leveransalternativ</h4>
              <QuoteDeliveryOptions
                onDeliveryOptionChange={setDeliveryOption}
                onValidationChange={setDeliveryOptionValid}
              />
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-xl-12">
          <div className="d-flex justify-content-start gap-3 mt-4 mb-4">
            <button
              className="btn btn-primary"
              onClick={handlePublish}
              disabled={loading || !deliveryOptionValid}
            >
              {loading ? 'Sparar...' : 'Publicera offert'}
            </button>
            <button
              className="btn btn-light"
              onClick={handleSaveDraft}
              disabled={loading}
            >
              {loading ? 'Sparar...' : 'Spara utkast'}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => history.push(`/${companyId}/quotes`)}
              disabled={loading}
            >
              Avbryt
            </button>
          </div>
          {hasUnsavedChanges && (
            <div className="text-warning mb-4">
              Du har osparade ändringar
            </div>
          )}
        </div>
      </div>

      {/* New Article Modal */}
      <NewArticleModal
        show={showNewArticleModal}
        handleClose={() => setShowNewArticleModal(false)}
        handleSave={handleSaveArticle}
        articleDetails={selectedArticle}
      />
    </>
  );
};

export default QuoteForm;
