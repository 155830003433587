import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Badge, Spinner } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import {
  Card,
  Table,
  Dropdown,
} from 'react-bootstrap';

import { getApiUrl } from '../../../../utils/ApiUtils';
import { NoData } from '../../../components/SharedComponents/NoData';
import PageTitle from '../../../layouts/PageTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

export const QuoteList = () => {
  const { companyId } = useParams();
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  const COLUMNS = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'friendly_id',
      Cell: ({ value }) => (value === null ? '-' : value),
    },
    {
      Header: 'Kund',
      accessor: 'customer_name',
    },
    {
      Header: 'Offererat datum',
      accessor: 'quote_date',
    },
    {
      Header: 'Giltigt till',
      accessor: 'valid_until',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props => {
        const { value } = props;
        switch (value) {
          case 'Draft':
            return <Badge bg="light">Utkast</Badge>;
          case 'Sent':
            return <Badge bg="info">Skickad</Badge>;
          case 'Accepted':
            return <Badge bg="success">Accepterad</Badge>;
          case 'Rejected':
            return <Badge bg="danger">Avböjd</Badge>;
          case 'Cancelled':
            return <Badge bg="secondary">Avbruten</Badge>;
          default:
            return <Badge bg="light">{value}</Badge>;
        }
      },
    },
    {
      Header: 'Belopp (inkl. moms)',
      accessor: 'total_with_tax',
      Cell: props => {
        const { original } = props.row;
        if (original.status === 'Draft') {
          return <span>-</span>;
        }
        return (
          <span>
            {new Intl.NumberFormat('sv-SE', {
              style: 'currency',
              currency: original.currency || 'SEK'
            }).format(props.value)}
          </span>
        );
      }
    },
    {
      Header: "",
      className: "column-right",
      Cell: ({ row }) => (
        <Dropdown
          className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            {row.original.status === 'Sent' && (
              <>
                <Dropdown.Item onClick={() => handleAcceptQuote(row.original.id)}>
                  Acceptera offert
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleRejectQuote(row.original.id)}>
                  Avböj offert
                </Dropdown.Item>
              </>
            )}
            {row.original.status === 'Draft' && (
              <Dropdown.Item onClick={() => handleDelete(row.original.id)}>
                Radera offert
              </Dropdown.Item>
            )}
            {row.original.status !== 'Cancelled' && row.original.status !== 'Rejected' && (
              <Dropdown.Item onClick={() => handleCancelQuote(row.original.id)}>
                Avbryt offert
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ], []);

  useEffect(() => {
    fetchQuoteData();
  }, [companyId]);

  const handleRowClick = (quoteId) => {
    const quote = quotes.find(quote => quote.id === quoteId);

    if (quote) {
      if (quote.status === 'Draft') {
        history.push(`/${companyId}/edit-quote/${quoteId}`);
      } else {
        history.push(`/${companyId}/view-quote/${quoteId}`);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const deleteQuoteApiUrl = getApiUrl(`${companyId}/quotes/${id}`);
      await axios.delete(deleteQuoteApiUrl, { withCredentials: true });
      fetchQuoteData();
    } catch (err) {
      console.error('Error deleting quote:', err);
    }
  };

  const handleAcceptQuote = async (id) => {
    try {
      const acceptQuoteApiUrl = getApiUrl(`${companyId}/quotes/${id}/accept`);
      await axios.put(acceptQuoteApiUrl, {}, { withCredentials: true });
      fetchQuoteData();
    } catch (err) {
      console.error('Error accepting quote:', err);
    }
  };

  const handleRejectQuote = async (id) => {
    try {
      const rejectQuoteApiUrl = getApiUrl(`${companyId}/quotes/${id}/reject`);
      await axios.put(rejectQuoteApiUrl, {}, { withCredentials: true });
      fetchQuoteData();
    } catch (err) {
      console.error('Error rejecting quote:', err);
    }
  };

  const handleCancelQuote = async (id) => {
    try {
      const cancelQuoteApiUrl = getApiUrl(`${companyId}/quotes/${id}/cancel`);
      await axios.put(cancelQuoteApiUrl, {}, { withCredentials: true });
      fetchQuoteData();
    } catch (err) {
      console.error('Error cancelling quote:', err);
    }
  };

  const fetchQuoteData = async () => {
    setLoading(true);
    try {
      const getQuotesApiUrl = getApiUrl(`${companyId}/quotes`);
      const result = await axios.get(getQuotesApiUrl, { withCredentials: true });
      setQuotes(result.data.quotes);
    } catch (err) {
      setError('Det gick inte att hämta offerterna.');
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const quoteListData = useMemo(() => quotes, [quotes]);

  const tableInstance = useTable({
    columns,
    data: quoteListData,
    initialState: { hiddenColumns: ['id'] }
  }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <PageTitle activeMenu="Offerter" motherMenu="Offerter" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Laddar...</span>
            </Spinner>
            <div>Laddar...</div>
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : quotes.length === 0 ? (
          <>
            <NoData message="Det finns inga offerter att visa. Skapa en för att kunna se dem här." />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              padding: '10px'
            }}>
              <Link
                style={{ marginTop: "20px", alignItem: "center" }}
                to={`#`}
                onClick={() => history.push(`/${companyId}/create-quote`)}
                className="btn btn-primary"
              >
                Skapa en offert
              </Link>
            </div>
          </>
        ) : (
          <>
            <Card>
              <Card.Header>
                <h4 className="card-title">Offerter</h4>
                <Link
                  to={`#`}
                  onClick={() => history.push(`/${companyId}/create-quote`)}
                  className="btn btn-primary"
                >
                  Ny Offert
                </Link>
              </Card.Header>
              <Card.Body>
                <div>
                  <Table {...getTableProps()} className="table table-responsive dataTable table-hover display">
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render('Header')}
                              <span className="ml-1">
                                {column.isSorted ? (
                                  column.isSortedDesc ?
                                    <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                    :
                                    <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                ) : (
                                  <i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr
                            className='clickable-row'
                            onClick={() => handleRowClick(row.original.id)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map(cell => (
                              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default QuoteList;