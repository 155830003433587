import { useParams, useLocation, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Form, Button, Row, Col, Table, Card, Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatePicker from "react-datepicker";
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from "../../../../utils/ApiUtils";
import PageTitle from "../../../layouts/PageTitle";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

// Helper function to format numbers as Swedish currency (SEK)
const formatCurrency = (amount) => {
    return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amount);
};

const EditVerification = () => {
    const { companyId, verificationId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const { verificationData, from } = location.state || {};

    const [accounts, setAccounts] = useState([]);
    const [formData, setFormData] = useState({
        date: verificationData ? new Date(verificationData.date) : new Date(),
        description: verificationData ? verificationData.description : "",
        attachments: verificationData ? verificationData.attachments : [],
        transactions: verificationData ? verificationData.transactions.map(tx => ({
            ...tx,
            debit: tx.transaction_type === 'debit' ? parseFloat(tx.amount) : "",
            credit: tx.transaction_type === 'credit' ? parseFloat(tx.amount) : ""
        })) : [],
    });

    const [annulmentTransactions, setAnnulmentTransactions] = useState([]);

    useEffect(() => {
        console.log("Verification data: ", verificationData);
        fetchAccounts();
        generateAnnulmentTransactions();
    }, []);

    const fetchAccounts = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/accounts`), { withCredentials: true });
            const options = result.data.map((account) => ({
                value: account.account_id,
                label: `${account.account_number} - ${account.account_name}`,
                account_number: account.account_number,
                account_name: account.account_name,
            }));
            setAccounts(options);
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    const generateAnnulmentTransactions = () => {
        if (verificationData && verificationData.transactions) {
            const reversedTransactions = verificationData.transactions.map((transaction) => ({
                ...transaction,
                transaction_type: transaction.transaction_type === 'debit' ? 'credit' : 'debit',
            }));
            setAnnulmentTransactions(reversedTransactions);
        }
    };

    const handleRowChange = (index, field, value) => {
        const newTransactions = [...formData.transactions];
        if (field === "account_id") {
            const selectedAccount = accounts.find((option) => option.value === value);
            newTransactions[index] = {
                ...newTransactions[index],
                account_id: selectedAccount.value,
                account_number: selectedAccount.account_number,
                account_name: selectedAccount.account_name,
            };
        } else {
            newTransactions[index][field] = value;
        }
        setFormData((prev) => ({ ...prev, transactions: newTransactions }));
    };

    const handleAddRow = () => {
        setFormData((prev) => ({
            ...prev,
            transactions: [...prev.transactions, { account_id: "", account_number: "", account_name: "", debit: "", credit: "" }],
        }));
    };

    const handleRemoveRow = (index) => {
        const newTransactions = formData.transactions.filter((_, rowIndex) => rowIndex !== index);
        setFormData((prev) => ({ ...prev, transactions: newTransactions }));
    };

    const handleSave = async () => {
        try {
            // Prepare both verifications in one request
            const verificationRequests = {
                annulment: {
                    date: verificationData.date.split("T")[0],
                    description: `Annulering av verifikat ${verificationData.series}${verificationData.number}`,
                    series: "V",
                    transactions: annulmentTransactions,
                    attachments: formData.attachments
                },
                replacement: {
                    date: formData.date.toISOString().split("T")[0],
                    description: formData.description,
                    series: "V",
                    transactions: formData.transactions.map(tx => ({
                        ...tx,
                        amount: tx.debit || tx.credit,
                        transaction_type: tx.debit ? 'debit' : 'credit'
                    })),
                    attachments: formData.attachments
                }
            };

            // Updated URL to include verification ID
            const response = await axios.post(
                getApiUrl(`${companyId}/verifications/${verificationId}/replace`),
                verificationRequests,
                { withCredentials: true }
            );

            if (!response.data.success) {
                throw new Error(response.data.message || "Failed to update verification");
            }

            history.push(from || `/${companyId}/verifications`);
        } catch (error) {
            console.error("Error saving verification:", error);
            swal("Error", "Failed to update verification. Please try again.", "error");
        }
    };
    const handleAbort = async () => {
        history.push(from || `/${companyId}/verifications`);
    };

    // Tooltip components
    const renderTooltip = (props, what) => {
        switch (what) {
            case "annulment":
                return (
                    <Tooltip {...props}>
                        Detta är verifikatet som kommer att skapas för att nollställa det verifikat du vill ändra.
                    </Tooltip>
                );

            case "original":
                return (
                    <Tooltip {...props}>
                        This is the original verification that was created on {verificationData.date.split("T")[0]}.
                    </Tooltip>
                );

            case "change":
                return (
                    <Tooltip {...props}>
                        Detta är det nya modifierade verifikatet som kommer att skapas.
                    </Tooltip>
                );

            default:
                return null;
        }
    };

    return (
        <div className="col-12">
            <PageTitle motherMenu="Bokföring" activeMenu={"Verifikationer"} />
            <h4 className="mb-5 mt-3"> Redigera verifikat</h4>

            <Row>
                {/* Left column: Edited verification */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Ditt nya verifikat
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => renderTooltip(props, "change")}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-muted" />
                                </OverlayTrigger>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="date">
                                    <Form.Label>Datum</Form.Label>
                                    <DatePicker
                                        selected={formData.date}
                                        onChange={(date) => setFormData((prev) => ({ ...prev, date }))}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                    />
                                </Form.Group>

                                <Form.Group controlId="description" className="mt-3">
                                    <Form.Label>Titel</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={formData.description}
                                        onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
                                        placeholder="Fyll i titel"
                                    />
                                </Form.Group>

                                <h5 className="mt-4">Transaktioner</h5>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Konto</th>
                                            <th>Debet</th>
                                            <th>Kredit</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.transactions.map((row, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Select
                                                        options={accounts}
                                                        value={accounts.find((option) => option.value === row.account_id)}
                                                        onChange={(selectedOption) =>
                                                            handleRowChange(index, "account_id", selectedOption ? selectedOption.value : "")
                                                        }
                                                        styles={{ control: (base) => ({ ...base, minWidth: "250px" }) }}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        value={row.debit}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^\d.]/g, '');  // Only allow numbers and decimal
                                                            handleRowChange(index, "debit", value);
                                                        }}
                                                        placeholder="Debet"
                                                        style={{
                                                            width: '100%',
                                                            padding: '0.375rem 0.75rem'
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        value={row.credit}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^\d.]/g, '');  // Only allow numbers and decimal
                                                            handleRowChange(index, "credit", value);
                                                        }}
                                                        placeholder="Kredit"
                                                        style={{
                                                            width: '100%',
                                                            padding: '0.375rem 0.75rem'
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {index >= 0 && (
                                                        <Button variant="link" onClick={() => handleRemoveRow(index)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Button className="mt-3" variant="link" onClick={handleAddRow}>
                                    Lägg till rad
                                </Button>
                            </Form>
                            <div className="mt-4">
                                <Button className="me-2" onClick={handleSave}>
                                    Spara Ändring
                                </Button>
                                <Button variant="link" onClick={handleAbort}>
                                    Ångra ändring
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Right column: Accordion for Original and Annulment verification with tooltips */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Verifikationshistorik</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                                <Accordion.Item eventKey="1" className="mt-3">
                                    <Accordion.Header>
                                        Annulering av ursprungligt verifikat
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => renderTooltip(props, "annulment")}
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-muted" />
                                        </OverlayTrigger>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Verifikatnr: {verificationData.series}?
                                        <br />
                                        Titel: Annulering av verifikat {verificationData.series + verificationData.number}
                                        <br />
                                        Datum: {verificationData.date.split("T")[0]}
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Konto</th>
                                                    <th>Debet</th>
                                                    <th>Kredit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {annulmentTransactions.map((transaction, index) => (
                                                    <tr key={index}>
                                                        <td>{transaction.account_number} - {transaction.account_name}</td>
                                                        <td>{transaction.transaction_type === 'debit' ? formatCurrency(transaction.amount) : formatCurrency(0)}</td>
                                                        <td>{transaction.transaction_type === 'credit' ? formatCurrency(transaction.amount) : formatCurrency(0)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Ursprungligt verifikat
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Verifikatnr: {verificationData.series + verificationData.number}
                                        <br />
                                        Titel: {verificationData.description}
                                        <br />
                                        Datum: {verificationData.date.split("T")[0]}
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Konto</th>
                                                    <th>Debet</th>
                                                    <th>Kredit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {verificationData.transactions.map((transaction, index) => (
                                                    <tr key={index}>
                                                        <td>{transaction.account_number} - {transaction.account_name}</td>
                                                        <td>{transaction.transaction_type === 'debit' ? formatCurrency(transaction.amount) : formatCurrency(0)}</td>
                                                        <td>{transaction.transaction_type === 'credit' ? formatCurrency(transaction.amount) : formatCurrency(0)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EditVerification;
