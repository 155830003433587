import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

const taxRateOptions = [
  { value: '0%', label: '0%' },
  { value: '6%', label: '6%' },
  { value: '12%', label: '12%' },
  { value: '25%', label: '25%' }
];

const unitOptions = [
  { value: 'Piece', label: 'Styck' },
  { value: 'Hour', label: 'Timmar' },
  { value: 'Liter', label: 'Liter' },
  { value: 'Kilogram', label: 'Kilogram' },
  { value: 'Square Meter', label: 'Kvadratmeter' },
  { value: 'Cubic Meter', label: 'Kubikmeter' },
  { value: 'Meter', label: 'Meter' },
  { value: 'Package', label: 'Förpackning' },
  { value: 'Set', label: 'Set' },
  { value: 'Day', label: 'Dag' },
  { value: 'Month', label: 'Månad' }
];

const typeOptions = [
  { value: 'Goods', label: 'Varor' },
  { value: 'Service', label: 'Tjänst' }
];

// Yup Validation Schema
const validationSchema = Yup.object().shape({
  productcode: Yup.string().required('Produktkod är obligatorisk'),
  description: Yup.string().required('Beskrivning är obligatorisk'),
  unitprice: Yup.number()
    .typeError('Pris måste vara ett nummer')
    .required('Pris är obligatoriskt'),
  taxrate: Yup.string().required('Välj moms'),
  unit: Yup.string().required('Välj enhet'),
  type: Yup.string().required('Välj typ')
});

const NewArticleModal = ({ show, handleClose, handleSave, handleUpdate, articleDetails }) => {
  const isNewArticle = articleDetails === null;

  const formik = useFormik({
    initialValues: {
      productcode: '',
      description: '',
      unitprice: '',
      type: '',
      unit: '',
      taxrate: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        productcode: values.productcode,
        description: values.description,
        unitprice: values.unitprice,
        type: values.type,
        unit: values.unit,
        taxrate: values.taxrate
      };

      if (isNewArticle || !handleUpdate) {
        handleSave(payload);
      } else {
        handleUpdate(articleDetails.id, payload);
      }

      handleClose();
    }
  });

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = formik;

  useEffect(() => {
    if (articleDetails) {
      resetForm({
        values: {
          productcode: articleDetails.productcode || '',
          description: articleDetails.description || '',
          unitprice: articleDetails.unitprice || '',
          type: articleDetails.type || '',
          unit: articleDetails.unit || '',
          taxrate: articleDetails.tax_rate || ''
        }
      });
    } else {
      resetForm({
        values: {
          productcode: '',
          description: '',
          unitprice: '',
          type: '',
          unit: '',
          taxrate: ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleDetails, show]);

  const handleCloseClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <Modal className='fade bd-example-modal-lg' size='lg' show={show} onHide={handleClose}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{isNewArticle ? 'Ny artikel' : 'Redigera artikel'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="basic-form">
              <div className='col-xl-12'>
                <div className="col-xl-4 mb-5">
                  Fyll i information om artikeln.
                </div>
                <Form.Group className="mb-3" controlId="productcode">
                  <Form.Label>Produktkod *</Form.Label>
                  <Form.Control
                    type="text"
                    name="productcode"
                    value={values.productcode}
                    onChange={handleChange}
                  />
                  {touched.productcode && errors.productcode && (
                    <div className="text-danger">{errors.productcode}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Beskrivning *</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                  {touched.description && errors.description && (
                    <div className="text-danger">{errors.description}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="unitprice">
                  <Form.Label>Pris (exkl. Moms) *</Form.Label>
                  <Form.Control
                    type="text"
                    name="unitprice"
                    value={values.unitprice}
                    onChange={handleChange}
                  />
                  {touched.unitprice && errors.unitprice && (
                    <div className="text-danger">{errors.unitprice}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="tax_rate">
                  <Form.Label>Moms *</Form.Label>
                  <Select
                    options={taxRateOptions}
                    value={taxRateOptions.find(option => option.value === values.taxrate)}
                    onChange={(selectedOption) => setFieldValue('taxrate', selectedOption.value)}
                  />
                  {touched.taxrate && errors.taxrate && (
                    <div className="text-danger">{errors.taxrate}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="enhet">
                  <Form.Label>Enhet *</Form.Label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(option => option.value === values.unit)}
                    onChange={(selectedOption) => setFieldValue('unit', selectedOption.value)}
                  />
                  {touched.unit && errors.unit && (
                    <div className="text-danger">{errors.unit}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="type">
                  <Form.Label>Typ *</Form.Label>
                  <Select
                    options={typeOptions}
                    value={typeOptions.find(option => option.value === values.type)}
                    onChange={(selectedOption) => setFieldValue('type', selectedOption.value)}
                  />
                  {touched.type && errors.type && (
                    <div className="text-danger">{errors.type}</div>
                  )}
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseClick}>
              Avbryt
            </Button>
            <Button variant="primary" type="submit">
              Spara
            </Button>
          </Modal.Footer>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default NewArticleModal;
