import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

const QuoteDeliveryOptions = ({ onDeliveryOptionChange, onValidationChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const deliveryOptions = [
    {
      value: 'email',
      label: 'E-post till kund',
      description: 'Vi kommer att maila en länk till kunden där kunden kommer kunna att öppna och se offerten. Du kommer se när offerten har öppnats.',
    },
    {
      value: 'download',
      label: 'Ladda ner PDF',
      description: 'Ladda ned offerten som en pdf. Denna kan sedan skickas till kunden.',
    },
    {
      value: 'preview',
      label: 'Förhandsgranska',
      description: 'Förhandsgranska offerten innan du skickar den till kunden.',
    }
  ];

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Ogiltigt e-postformat')
      .required('E-postadress krävs')
  });

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    onDeliveryOptionChange({ method: value, email: '' });
    onValidationChange(value !== 'email');
  };

  return (
    <div className="delivery-options-container">
      {deliveryOptions.map((option) => (
        <div key={option.value} className="delivery-option">
          <div className="option-header">
            <input
              type="radio"
              id={option.value}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleOptionChange(option.value)}
              className="form-check-input"
            />
            <div className="option-content">
              <label htmlFor={option.value} className="option-label">
                {option.label}
              </label>
              <p className="option-description">{option.description}</p>
            </div>
          </div>

          {selectedOption === 'email' && option.value === 'email' && (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={emailSchema}
              onSubmit={() => { }}
              validate={(values) => {
                try {
                  emailSchema.validateSync(values);
                  onValidationChange(true);
                } catch (error) {
                  onValidationChange(false);
                }
              }}
            >
              {({ values, errors, handleChange, handleBlur, touched }) => (
                <div className="email-input-container">
                  <label htmlFor="email">Ange mottagarens epost:</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                    onChange={(e) => {
                      handleChange(e);
                      onDeliveryOptionChange({ method: selectedOption, email: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
              )}
            </Formik>
          )}
        </div>
      ))}

      <style jsx>{`
        .delivery-options-container {
          padding: 15px;
          margin: 10px;
        }
        .delivery-option {
          border: 1px solid #e1e2e3;
          padding: 15px;
          margin-bottom: 10px;
          border-radius: 5px;
        }
        .option-header {
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }
        .option-content {
          flex-grow: 1;
        }
        .option-label {
          font-weight: bold;
          margin-bottom: 5px;
          display: block;
        }
        .option-description {
          color: #666;
          margin: 0;
          font-size: 0.9em;
        }
        .email-input-container {
          margin-top: 15px;
          padding: 10px;
          background: #f8f9fa;
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
};

export default QuoteDeliveryOptions;