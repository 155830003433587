import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../../../services/AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { getApiUrl } from '../../../../../utils/ApiUtils';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';

const typeOptions = [
  { value: 'company', label: 'Företag' },
  { value: 'individual', label: 'Privatperson' },
];

const addressTypeOptions = [
  { value: 'billing', label: 'Fakturaadress' },
  { value: 'shipping', label: 'Leveransadress' },
  { value: 'other', label: 'Annan adress' },
];

// Validation Schema with conditions
const validationSchema = Yup.object().shape({
  type: Yup.string().required('Kundtyp måste väljas'),
  // For company: name and orgnumber required
  // For individual: first_name and last_name required
  name: Yup.string().when('type', {
    is: 'company',
    then: Yup.string().required('Företagsnamn krävs för företag'),
    otherwise: Yup.string().notRequired(),
  }),
  orgnumber: Yup.string().when('type', {
    is: 'company',
    then: Yup.string().required('Organisationsnummer krävs för företag'),
    otherwise: Yup.string().notRequired(),
  }),
  first_name: Yup.string().when('type', {
    is: 'individual',
    then: Yup.string().required('Förnamn krävs för privatperson'),
    otherwise: Yup.string().notRequired(),
  }),
  last_name: Yup.string().when('type', {
    is: 'individual',
    then: Yup.string().required('Efternamn krävs för privatperson'),
    otherwise: Yup.string().notRequired(),
  }),
  phone: Yup.string().required('Telefonnummer är obligatoriskt'),
  payment_terms: Yup.number().transform(value => (isNaN(value) ? undefined : value))
    .nullable(true)
    .notRequired(),
  addresses: Yup.array().of(
    Yup.object().shape({
      address_type: Yup.string().oneOf(['billing', 'shipping', 'other']).required(),
      street1: Yup.string().required('Gatuadress krävs'),
      city: Yup.string().required('Ort krävs'),
      postalcode: Yup.string().required('Postnummer krävs'),
      country: Yup.string().nullable(),
    })
  ).min(1, 'Minst en adress måste anges'),
  contacts: Yup.array().when('type', {
    is: 'company',
    then: Yup.array().of(
      Yup.object().shape({
        contact_name: Yup.string().required('Kontaktpersonens namn krävs för företag'),
        contact_email: Yup.string().email('Ogiltig e-post').nullable(),
        contact_phone: Yup.string().nullable(),
        role: Yup.string().nullable(),
      })
    ).min(1, 'Minst en kontaktperson måste anges för ett företag'),
    otherwise: Yup.array().of(
      Yup.object().shape({
        // For individuals, contacts are optional, but if present, can validate lightly
        contact_name: Yup.string().nullable(),
        contact_email: Yup.string().email('Ogiltig e-post').nullable(),
        contact_phone: Yup.string().nullable(),
        role: Yup.string().nullable(),
      })
    )
  })
});

const NewCustomerModal = ({ show, handleClose, handleSave, handleUpdate, customerDetails }) => {
  const isEditMode = !!customerDetails;

  const defaultValues = {
    id: '',
    type: 'company',
    orgnumber: '',
    vatnumber: '',
    name: '',
    first_name: '',
    last_name: '',
    phone: '',
    payment_terms: '',
    addresses: [
      {
        address_type: 'billing',
        street1: '',
        street2: '',
        city: '',
        postalcode: '',
        country: '',
      },
    ],
    contacts: [
      {
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        role: '',
      },
    ],
  };

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        type: values.type,
        orgnumber: values.type === 'company' ? values.orgnumber || '' : '',
        vatnumber: values.type === 'company' ? values.vatnumber || '' : '',
        name: values.type === 'company' ? values.name || '' : '',
        first_name: values.type === 'individual' ? values.first_name || '' : '',
        last_name: values.type === 'individual' ? values.last_name || '' : '',
        phone: values.phone || '',
        payment_terms: values.payment_terms || '',
        addresses: values.addresses.map((addr) => ({
          address_type: addr.address_type,
          street1: addr.street1 || '',
          street2: addr.street2 || '',
          city: addr.city || '',
          postalcode: addr.postalcode || '',
          country: addr.country || '',
        })),
        contacts: values.contacts.map((ct) => ({
          contact_name: ct.contact_name || '',
          contact_email: ct.contact_email || '',
          contact_phone: ct.contact_phone || '',
          role: ct.role || '',
        })),
      };

      if (isEditMode) {
        payload.id = values.id;
        handleUpdate(payload);
      } else {
        handleSave(payload);
      }
      handleClose();
    }
  });

  const { values, errors, touched, setFieldValue } = formik;

  useEffect(() => {
    if (show) {
      if (isEditMode && customerDetails) {
        const {
          id,
          type,
          orgnumber,
          vatnumber,
          name,
          first_name,
          last_name,
          phone,
          payment_terms,
          addresses = [],
          contacts = [],
        } = customerDetails;

        formik.resetForm({
          values: {
            id: id || '',
            type: type || 'company',
            orgnumber: orgnumber || '',
            vatnumber: vatnumber || '',
            name: name || '',
            first_name: first_name || '',
            last_name: last_name || '',
            phone: phone || '',
            payment_terms: payment_terms || '',
            addresses: addresses.length > 0 ? addresses : [
              {
                address_type: 'billing',
                street1: '',
                street2: '',
                city: '',
                postalcode: '',
                country: '',
              },
            ],
            contacts: contacts.length > 0 ? contacts : [
              {
                contact_name: '',
                contact_email: '',
                contact_phone: '',
                role: '',
              },
            ],
          }
        });
      } else {
        formik.resetForm({ values: defaultValues });
      }
      setCompanies([]);
      setSelectedCompany(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, isEditMode, customerDetails]);

  const searchCompany = async (inputValue) => {
    if (!inputValue) return;

    try {
      const searchCompanyApiUrl = getApiUrl('generic/search-company');
      const response = await axios.get(searchCompanyApiUrl, {
        params: { query: inputValue },
      });

      setCompanies(
        response.data.hits.map((company) => ({
          label: `${company.document.names[0].nameOrIdentifier} (${company.document.registrationNumber})`,
          value: company,
        }))
      );
    } catch (err) {
      console.error('Error fetching company data:', err.message);
      swal(
        'Fel',
        'Kunde inte hämta företagsinformation, försök igen senare eller ange informationen manuellt.',
        'error'
      );
    }
  };

  const handleCompanySelect = (selectedOption) => {
    const company = selectedOption.value;
    setSelectedCompany(company);

    const visitingAddress = company.document.addresses?.find(addr => addr.addressType === 'registeredAddress');
    const mailAddress = company.document.addresses?.find(addr => addr.addressType === 'mailAddress');
    const selectedAddress = visitingAddress || mailAddress || {};

    const phoneNumber = company.document.phoneNumbers?.[0]?.e164PhoneNumber || '';
    const email = company.document.emailAddresses?.[0].emailAddress || '';
    const compName = company.document.names?.[0]?.nameOrIdentifier || '';

    const updatedAddresses = [
      {
        address_type: 'billing',
        street1: ((selectedAddress.street || '') + ' ' + (selectedAddress.houseNumber || '')).trim(),
        street2: '',
        city: selectedAddress.city || '',
        postalcode: selectedAddress.postalCode || '',
        country: company.document.iso3166CountryCode || '',
      },
    ];

    const updatedContacts = [
      {
        contact_name: '',
        contact_email: email,
        contact_phone: phoneNumber,
        role: '',
      },
    ];

    // Set all these values in formik
    setFieldValue('type', 'company');
    setFieldValue('orgnumber', company.document.registrationNumber || '');
    setFieldValue('vatnumber', company.document.isRegisteredForVAT ? `SE${company.document.registrationNumber}01` : '');
    setFieldValue('name', compName);
    setFieldValue('first_name', '');
    setFieldValue('last_name', '');
    setFieldValue('phone', phoneNumber);
    setFieldValue('payment_terms', '');
    setFieldValue('addresses', updatedAddresses);
    setFieldValue('contacts', updatedContacts);
  };

  return (
    <Modal
      className='fade bd-example-modal-lg'
      size='lg'
      show={show}
      onHide={handleClose}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? 'Redigera kund' : 'Ny kund'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="basic-form">
              <div className="col-xl-12">
                {!isEditMode && (
                  <>
                    <label>Typ av kund</label>
                    <Select
                      options={typeOptions}
                      value={typeOptions.find((opt) => opt.value === values.type)}
                      onChange={(selected) => setFieldValue('type', selected.value)}
                      className="mb-3"
                    />
                    {touched.type && errors.type && <div className="text-danger">{errors.type}</div>}
                  </>
                )}

                {!isEditMode && values.type === 'company' && (
                  <>
                    <label>Sök företag</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ange företagsnamn eller org-nummer"
                        value={values.name || ''}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <Button variant="primary" onClick={() => searchCompany(values.name)}>
                        Hämta företagsinformation
                      </Button>
                    </div>
                    {companies.length > 0 && (
                      <>
                        <label>Hittade {companies.length} företag</label>
                        <Select
                          options={companies}
                          onChange={handleCompanySelect}
                          placeholder="Välj ett företag"
                          className='mb-5'
                          value={
                            selectedCompany
                              ? {
                                label: selectedCompany.document.names[0].nameOrIdentifier,
                                value: selectedCompany
                              }
                              : null
                          }
                        />
                      </>
                    )}
                  </>
                )}

                {/* Company fields */}
                {values.type === 'company' && (
                  <>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Företagsnamn *</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.name || ''}
                          onChange={(e) => setFieldValue('name', e.target.value)}
                        />
                        {touched.name && errors.name && <div className="text-danger">{errors.name}</div>}
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Org-nummer *</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.orgnumber || ''}
                          onChange={(e) => setFieldValue('orgnumber', e.target.value)}
                        />
                        {touched.orgnumber && errors.orgnumber && <div className="text-danger">{errors.orgnumber}</div>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Vat-nummer</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.vatnumber || ''}
                          onChange={(e) => setFieldValue('vatnumber', e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Telefonnummer *</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.phone || ''}
                          onChange={(e) => setFieldValue('phone', e.target.value)}
                        />
                        {touched.phone && errors.phone && <div className="text-danger">{errors.phone}</div>}
                      </div>
                    </div>
                  </>
                )}

                {/* Individual fields */}
                {values.type === 'individual' && (
                  <>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Förnamn *</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.first_name || ''}
                          onChange={(e) => setFieldValue('first_name', e.target.value)}
                        />
                        {touched.first_name && errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Efternamn *</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.last_name || ''}
                          onChange={(e) => setFieldValue('last_name', e.target.value)}
                        />
                        {touched.last_name && errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Telefonnummer *</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.phone || ''}
                          onChange={(e) => setFieldValue('phone', e.target.value)}
                        />
                        {touched.phone && errors.phone && <div className="text-danger">{errors.phone}</div>}
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Betalningsvillkor (dagar)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={values.payment_terms || ''}
                      onChange={(e) => setFieldValue('payment_terms', e.target.value)}
                    />
                    {touched.payment_terms && errors.payment_terms && <div className="text-danger">{errors.payment_terms}</div>}
                  </div>
                </div>

                <hr />
                <h5>Adresser</h5>
                <FieldArray name="addresses">
                  {({ push, remove }) => (
                    <>
                      {values.addresses.map((addr, idx) => (
                        <div key={idx} className="mb-4 border p-3">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h6>Adress {idx + 1}</h6>
                            {values.addresses.length > 1 && (
                              <Button variant="link" onClick={() => remove(idx)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            )}
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-4">
                              <label>Typ *</label>
                              <Select
                                options={addressTypeOptions}
                                value={addressTypeOptions.find(o => o.value === addr.address_type)}
                                onChange={(opt) => setFieldValue(`addresses.${idx}.address_type`, opt.value)}
                              />
                              {touched.addresses?.[idx]?.address_type && errors.addresses?.[idx]?.address_type && (
                                <div className="text-danger">{errors.addresses[idx].address_type}</div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-8">
                              <label>Gata *</label>
                              <input
                                type="text"
                                className="form-control"
                                value={addr.street1 || ''}
                                onChange={(e) => setFieldValue(`addresses.${idx}.street1`, e.target.value)}
                              />
                              {touched.addresses?.[idx]?.street1 && errors.addresses?.[idx]?.street1 && (
                                <div className="text-danger">{errors.addresses[idx].street1}</div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-4">
                              <label>Ort *</label>
                              <input
                                type="text"
                                className="form-control"
                                value={addr.city || ''}
                                onChange={(e) => setFieldValue(`addresses.${idx}.city`, e.target.value)}
                              />
                              {touched.addresses?.[idx]?.city && errors.addresses?.[idx]?.city && (
                                <div className="text-danger">{errors.addresses[idx].city}</div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-4">
                              <label>Postnummer *</label>
                              <input
                                type="text"
                                className="form-control"
                                value={addr.postalcode || ''}
                                onChange={(e) => setFieldValue(`addresses.${idx}.postalcode`, e.target.value)}
                              />
                              {touched.addresses?.[idx]?.postalcode && errors.addresses?.[idx]?.postalcode && (
                                <div className="text-danger">{errors.addresses[idx].postalcode}</div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-4">
                              <label>Land</label>
                              <input
                                type="text"
                                className="form-control"
                                value={addr.country || ''}
                                onChange={(e) => setFieldValue(`addresses.${idx}.country`, e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <Button variant="link" onClick={() => push({
                        address_type: 'billing',
                        street1: '',
                        street2: '',
                        city: '',
                        postalcode: '',
                        country: '',
                      })}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" />Lägg till ny adress
                      </Button>
                      {touched.addresses && typeof errors.addresses === 'string' && (
                        <div className="text-danger">{errors.addresses}</div>
                      )}
                    </>
                  )}
                </FieldArray>

                {values.type === 'company' && (
                  <>
                    <hr />
                    <h5>Kontaktpersoner</h5>
                    <FieldArray name="contacts">
                      {({ push, remove }) => (
                        <>
                          {values.contacts.map((contact, index) => (
                            <div key={index} className="mb-4 border p-3">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <h6>Kontakt {index + 1}</h6>
                                {values.contacts.length > 1 && (
                                  <Button variant="link" onClick={() => remove(index)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                )}
                              </div>
                              <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                  <label>Namn *</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={contact.contact_name || ''}
                                    onChange={(e) => setFieldValue(`contacts.${index}.contact_name`, e.target.value)}
                                  />
                                  {touched.contacts?.[index]?.contact_name && errors.contacts?.[index]?.contact_name && (
                                    <div className="text-danger">{errors.contacts[index].contact_name}</div>
                                  )}
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                  <label>E-post</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    value={contact.contact_email || ''}
                                    onChange={(e) => setFieldValue(`contacts.${index}.contact_email`, e.target.value)}
                                  />
                                  {touched.contacts?.[index]?.contact_email && errors.contacts?.[index]?.contact_email && (
                                    <div className="text-danger">{errors.contacts[index].contact_email}</div>
                                  )}
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                  <label>Telefon</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={contact.contact_phone || ''}
                                    onChange={(e) => setFieldValue(`contacts.${index}.contact_phone`, e.target.value)}
                                  />
                                  {touched.contacts?.[index]?.contact_phone && errors.contacts?.[index]?.contact_phone && (
                                    <div className="text-danger">{errors.contacts[index].contact_phone}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <Button variant="link" onClick={() => push({ contact_name: '', contact_email: '', contact_phone: '', role: '' })}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />Lägg till ny kontaktperson
                          </Button>
                          {touched.contacts && typeof errors.contacts === 'string' && (
                            <div className="text-danger">{errors.contacts}</div>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </>
                )}

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Avbryt
            </Button>
            <Button variant="primary" type="submit">
              {isEditMode ? 'Uppdatera' : 'Spara'}
            </Button>
          </Modal.Footer>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default NewCustomerModal;
