import React, { useState, useEffect } from 'react';
import { Table, Button, Card } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import Select from 'react-select';
import { NoData } from '../../SharedComponents/NoData';

function VatReportingPeriods() {
  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
  const { companyId } = useParams();
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  const fetchVatPeriods = async (fiscalYearId) => {
    try {
      const response = await axios.get(
        getApiUrl(`${companyId}/reporting/vat-reporting-periods`),
        {
          params: { fiscalYearId },
          withCredentials: true
        }
      );
      if (response.data.success) {
        setPeriods(response.data.periods);
      } else {
        setError('Failed to load VAT reporting periods');
      }
    } catch (err) {
      setError('Error fetching VAT reporting periods');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getFiscalYears = async () => {
      try {
        const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
        setFiscalYears(result.data.fiscal_years);
        if (result.data.fiscal_years.length > 0) {
          const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
          setSelectedFiscalYear(latestFiscalYear);
          // Fetch VAT periods for the latest fiscal year
          await fetchVatPeriods(latestFiscalYear.fiscal_year_id);
        }
      } catch (err) {
        console.error('Error fetching fiscal years:', err);
        setFiscalYears([]);
      } finally {
        setLoading(false);
      }
    };

    getFiscalYears();
  }, [companyId]);

  const handleFiscalYearChange = async (selectedOption) => {
    setSelectedFiscalYear(selectedOption.value);
    setLoading(true);
    await fetchVatPeriods(selectedOption.value.fiscal_year_id);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <PageTitle motherMenu={"Rapporter"} activeMenu={"Momsrapport"} />

      {loading ? (
        <p>Loading...</p>
      ) : fiscalYears.length === 0 ? (
        <>
          <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center',
            padding: '10px'
          }}>
            <Link
              style={{ marginTop: "20px", alignItem: "center" }}
              to={`#`}
              onClick={() => history.push(`/${companyId}/settings`)}
              className="btn btn-primary"
            >
              Gå till Inställningar
            </Link>
          </div>
        </>
      ) : (
        <>

          <div className="d-flex align-items-center gap-4 mb-5">
            <span>Räkenskapsår:</span>
            <Select
              value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
              onChange={handleFiscalYearChange}
              options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
            />
          </div >
          <Card className="mb-3">
            <Card.Body>
              <div>

                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>Period</th>
                      <th>Moms att betala/få tillbaka</th>
                      <th>Deklarations inlämnat senast</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {periods.length === 0 ? (
                      <tr>
                        <td colSpan="6">No VAT reporting periods available</td>
                      </tr>
                    ) : (
                      periods.map(period => (
                        <tr onClick={() => history.push(`/${companyId}/vat-period/${period.vat_reporting_period_id}`)} key={period.vat_reporting_period_id}>
                          <td>{new Date(period.start_date).toLocaleDateString()} - {new Date(period.end_date).toLocaleDateString()}</td>
                          <td>{period.vat_total} SEK</td>
                          <td>{new Date(period.due_date).toLocaleDateString()}</td>
                          <td>{period.state}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default VatReportingPeriods;
