import React, { useState } from 'react';
import axios from '../../../../services/AxiosInstance';
import EventTypeSelection from './EventTypeSelection';
import TemplateSelection from './TemplateSelection';
import ParameterInput from './VerificationDataInput';
import ReviewVerification from './ReviewVerification';
import Submission from './VerificationSubmission';
import { useParams } from 'react-router-dom/';
import PageTitle from '../../../layouts/PageTitle';
import { Card } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';

import FileDropZone from '../../SharedComponents/FileDropZoneWithPreview';
import { getApiUrl } from '../../../../utils/ApiUtils';
import swal from 'sweetalert';

function CreateVerification() {
  const [step, setStep] = useState(0);
  const [eventType, setEventType] = useState(null);
  const [template, setTemplate] = useState(null);
  const [parameters, setParameters] = useState(null);
  const [verification, setVerification] = useState(null);
  const [attachments, setAttachments] = useState([]);  // New state to store uploaded file paths

  const { companyId } = useParams();

  console.log("Company id: ", companyId);

  const handleNextEventType = (type) => {
    setEventType(type);
    setStep(1);
  };

  const handleNextTemplate = (template) => {
    console.log("Template selected: ", template);
    setTemplate(template);
    setStep(2);
  };

  const handleNextParameters = (params) => {
    console.log("Params: ", params);
    setParameters(params);
    setStep(3);
  };

  const handleConfirmVerification = async (verificationData) => {

    console.log("Verification data: ", verificationData);
    console.log("Attachments: ", attachments);

    const { date, description, series, transactions } = verificationData;

    try {
      const createVerificationApiUrl = getApiUrl(`${companyId}/verifications`);

      const response = await axios.post(createVerificationApiUrl, { date, description, series, transactions, attachments }, { withCredentials: true });

      console.log("Response: ", response.data);

      if (response.data.success) {
        console.log("Verification created: ", response.data);
        setVerification(verificationData);
        setStep(4);
      } else {
        swal("Error", "Error creating verificaztion", "error");
      }
    } catch (error) {
      console.error('Error creating verification:', error.response);

      if (error.response && error.response.status === 500) {
        swal("Server Error", `${error.response.data.message}`, "error");
      } else {
        swal("Error", "Error creating verification", "error");
      }
    }

  };

  const handleSubmitVerification = async () => {
    /*try {
      const verificationData = {
        ...verification,
        attachments, // Include file attachments in the data to be submitted
      };
      const response = await axios.post(`/api/companies/${companyId}/verifications`, verificationData, { withCredentials: true });
      // Handle success, e.g., navigate to another page or show a success message
    } catch (error) {
      console.error('Error submitting verification:', error);
    }*/
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const uploadFileApiUrl = getApiUrl(`${companyId}/accounting/verifications/addattachment`);
      const response = await axios.post(uploadFileApiUrl, formData, { withCredentials: true });

      if (response.data.success) {
        setAttachments((prevAttachments) => [...prevAttachments, {
          attachmentId: response.data.attachmentId,
          file_url: response.data.fileUrl,
          file_name: response.data.fileName,
          file_type: response.data.fileType,
          file_size: response.data.fileSize,
          blob_name: response.data.blobName
        }]);
      } else {
        console.error('Error uploading file:', response.data.message);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <>
      <PageTitle activeMenu="Nytt verifikat" motherMenu="Bokföring" />

      <div className="row">
        <div className="col-7">
          <Card>
            <Card.Header style={{ border: '0px', display: 'block' }}>
              <div className="form-wizard ">
                <Stepper className="nav-wizard" style={{ padding: '0px' }} activeStep={step} label={"false"}>
                  <Step label="Välj typ av händelse" className="nav-link" onClick={() => console.log("Next step 0")} />
                  <Step label="Välj en mall" className="nav-link" onClick={() => console.log("next step 1")} />
                  <Step label="Fyll i detaljer" className="nav-link" onClick={() => console.log("Next step 2")} />
                  <Step label="Granska" className="nav-link" onClick={() => console.log("Next step 3")} />
                </Stepper>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='row'>
                <div>
                  {step === 0 && <EventTypeSelection onNext={handleNextEventType} />}
                  {step === 1 && <TemplateSelection eventType={eventType} onNext={handleNextTemplate} companyId={companyId} />}
                  {step === 2 && <ParameterInput template={template} onNext={handleNextParameters} companyId={companyId} />}
                  {step === 3 && <ReviewVerification template={template} parameters={parameters} companyId={companyId} onVerificationAdded={handleConfirmVerification}
                    attachments={attachments} />}
                  {step === 4 && <Submission verification={verification} onSubmit={handleSubmitVerification} companyId={companyId} />}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-5">
          <Card>
            <Card.Header style={{ border: '0px', display: 'block' }}>
              <h4 className="card-title">Dokument</h4>
            </Card.Header>
            <Card.Body>
              <FileDropZone
                width="140px"
                height="170px"
                maxFiles={2}
                onFileUpload={(file) => {
                  // Handle file upload
                  console.log('File uploaded:', file);
                  handleFileUpload(file);
                }}
              />
            </Card.Body>
          </Card>
        </div>
      </div >
    </>
  );
}

export default CreateVerification;
